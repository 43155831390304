import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { getSmoothStepPath, getMarkerEnd, getEdgeCenter, EdgeText } from 'react-flow-renderer';
import AddRoundedIcon from '@material-ui/icons/AddCircleRounded';
import TuneRoundedIcon from '@material-ui/icons/TuneRounded';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { v4 as uuid } from 'uuid';

import {
  elementDeleted,
  elementSelected,
  nodeInserted,
  nodeMoved,
  selectDraggedNode,
  notification,
  filterMode
} from '../../features/workflow/workflowSlice';
import { Tooltip, ClickAwayListener, Popper, MenuItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  // Styles for our custom popper dropdown menu.
  popper: {
    border: '1px solid #d3d4d5',
    borderRadius: '4px',
    padding: theme.spacing(1),
    backgroundColor: 'white', // force white background
    boxShadow: '0 5px 5px 5px lightgray',
    width: '200px',
    zIndex: 10,
  },
  // This style is for our drop indicator.
  dropIndicator: {
    position: 'fixed',
    width: 450,
    height: 80,
    border: '3px dashed #0069ea',
    backgroundColor: 'white',
    opacity: 0.8,
    pointerEvents: 'none',
    boxSizing: 'border-box',
    zIndex: 9999,
  },
}));

// We create a styled popper that always uses the defined popper style.
const StyledMenu = withStyles((theme) => ({
  paper: {
    border: '1px solid #d3d4d5',
    backgroundColor: 'white',
    boxShadow: '0 5px 5px 5px lightgray',
    width: '200px',
    zIndex: 9999,
  },
}))( (props) => <Popper {...props} placement="right-start" /> );

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: 'white',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: 'white',
      },
    },
  },
}))(MenuItem);

export default function CustomEdge({
  id,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  borderRadius = 5,
  style = {},
  data,
  arrowHeadType,
  markerEndId
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();

  // State for our dropdown popper.
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [nodrag, setNoDrag] = useState(false);
  // State for tracking drag-over.
  const [isDragOver, setIsDragOver] = useState(false);
  // State to hold the bounding rectangle of the foreignObject.
  const [dropRect, setDropRect] = useState(null);
  const dragLeaveTimerRef = useRef(null);
  // Create a ref for the foreignObject.
  const foreignObjectRef = useRef();
  const draggedNode = useSelector(selectDraggedNode)
  const [tooltipOpen, setTooltipOpen] = useState(false);

  // Whenever isDragOver changes and the ref is available, update dropRect.
  useEffect(() => {
    if (isDragOver && foreignObjectRef.current) {
      const rect = foreignObjectRef.current.getBoundingClientRect();
      setDropRect(rect);
    }
  }, [isDragOver]);

  const closeDropdown = () => {
    setAnchorEl(null);
    setOpen(false);
    setTooltipOpen(false); // also close tooltip
    setNoDrag(false);
  };

  // Compute the edge path and marker.
  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    borderRadius,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  // Get the center of the edge.
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  // When the user clicks the plus icon.
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setNoDrag(true);
  };

 
   // --- DRAG/DROP HANDLERS ---
   const handleDragStart = (event) => {
    // Here we store the dragged node's id.
    // (Assume that when starting a drag on this edge drop zone, the node to be moved
    // has been stored elsewhere—for example, in the dataTransfer. For this example,
    // we assume that the node's id is provided as a prop or via some global state.)
    // For demonstration, let's say we have it in data.draggedNodeId.
    event.dataTransfer.setData('application/json', JSON.stringify({ nodeId: data.draggedNodeId }));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    // Clear any existing timer to hide.
    if (dragLeaveTimerRef.current) {
      clearTimeout(dragLeaveTimerRef.current);
    }
    setIsDragOver(true);
  };

  const handleDragLeave = (event) => {
    // Delay hiding the drop indicator.
    dragLeaveTimerRef.current = setTimeout(() => {
      setIsDragOver(false);
    }, 800); // adjust delay (in ms) as needed
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragOver(false);
    
    const newX = edgeCenterX;
    const newY = edgeCenterY;
    // For the new target, you might want to compute this based on drop coordinates.
    // Here we simply keep the original target.
    // Dispatch our nodeMoved action.
    //dispatch(nodeMoved(draggedNode, newX, newY, source, target, true, id));
    dispatch(nodeMoved({
      nodeId: draggedNode.id,
      newParent: source,
      newChild: target,
      newX: newX,
      newY: newY,
    }));

    closeDropdown();
    dispatch(notification({ "status": "success", "message": "Step \""+draggedNode.data.name+"\" was moved" }))

  };
  // --- END DRAG/DROP HANDLERS ---

  // Insert step option from the dropdown.
  const insertStep = () => {
    closeDropdown();
    const newX = (sourceX + targetX) / 2;
    const newY = (sourceY + targetY) / 2;
    dispatch(nodeInserted('action', newX, newY, source, target, true, id));
    dispatch(notification({ "status": "success", "message": "Step inserted" }))

  };

  return (
    <>
      {/* Render the edge path */}
      <path
        id={id}
        className="react-flow__edge-path nodrag"
        d={edgePath}
        markerEnd={markerEnd}
        style={{ ...style, pointerEvents: 'none', zIndex: 0 }}
      />

      {/* Render the edge label */}
      <EdgeText
        x={edgeCenterX}
        y={edgeCenterY - 28}
        label={data.fields?.[0]?.value === 'empty' ? '' : data.fields?.[0]?.value ?? data.name}
        labelStyle={{ fill: 'white' }}
        labelShowBg
        labelBgStyle={{ fill: 'black' }}
        labelBgPadding={[4, 4]}
        labelBgBorderRadius={2}
      />

      <ClickAwayListener onClickAway={closeDropdown}>
        <foreignObject
          id="toolbox"
          ref={foreignObjectRef}
          style={{
            cursor: 'pointer',
            backgroundColor: 'whitesmoke',
            padding: 0,
            overflow: 'visible',
            zIndex: 1000,
          }}
          width={25}
          height={25}
          x={edgeCenterX - 12.5}
          y={edgeCenterY - 12.5}
          requiredExtensions="http://www.w3.org/1999/xhtml"
          draggable="true"
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <Tooltip title={<Typography style={{ fontSize: '16px' }}>Add Step, Filter, or Delay</Typography>} 
          placement="top"
          open={tooltipOpen}
          onOpen={() => setTooltipOpen(true)}
          onClose={() => setTooltipOpen(false)}
          leaveDelay={200}
          >
            <div style={{ position: 'relative' }}>
              {/* When dragging, render the drop indicator using a portal so it’s not bound by the foreignObject */}
              {isDragOver &&
                dropRect &&
                ReactDOM.createPortal(
                  <div
                    className={classes.dropIndicator}
                    style={{
                      left: dropRect.left + dropRect.width / 2 - 450 / 2,
                      top: dropRect.top + dropRect.height / 2 - 80 / 2,
                    }}
                  />,
                  document.body
                )}
              <AddRoundedIcon
                size="large"
                className="nodrag"
                color="primary"
                onClick={handleClick}
                style={{ pointerEvents: 'auto' }}
              />
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closeDropdown}
                style={{ 
                marginLeft: '8px',
                padding: '8px',
                borderRadius: '5px',  
                backgroundColor: 'white',
                boxShadow: '0 3px 5px 1px lightgray',
                width: '200px',
                zIndex: 9999}}
              >
                <StyledMenuItem className="nodrag" onClick={insertStep}>
                  <ListItemIcon>
                    <AddCircleOutlineRoundedIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Add Step" />
                </StyledMenuItem>
                <StyledMenuItem
                  className="nodrag"
                  onClick={() => {
                    closeDropdown();
                    dispatch(filterMode('filter'));
                    dispatch(elementSelected(id));
                  }}
                >
                  <ListItemIcon>
                    <TuneRoundedIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Filter" />
                </StyledMenuItem>
                <StyledMenuItem
                  className="nodrag"
                  onClick={() => {
                    closeDropdown();
                    dispatch(filterMode('delay'));
                    dispatch(elementSelected(id));
                  }}
                >
                  <ListItemIcon>
                    <HourglassEmptyRoundedIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Delay" />
                </StyledMenuItem>
              </StyledMenu>
            </div>
          </Tooltip>
        </foreignObject>
      </ClickAwayListener>
    </>
  );
}
