// undoable.js

export const UNDO = 'UNDO';
export const REDO = 'REDO';

const initialUndoState = {
  past: [],
  present: null,
  future: []
};

export function undoable(reducer) {
  const initialState = {
    past: [],
    present: reducer(undefined, {}), // get initial present state
    future: []
  };

  return function (state = initialState, action) {
    const { past, present, future } = state;

    switch (action.type) {
      case 'UNDO': {
        if (past.length === 0) return state; // nothing to undo
        const previousEntry = past[past.length - 1];
        const newPast = past.slice(0, past.length - 1);
        return {
          past: newPast,
          present: previousEntry.state,
          future: [present, ...future]
        };
      }
      case 'REDO': {
        if (future.length === 0) return state; // nothing to redo
        const nextState = future[0];
        const newFuture = future.slice(1);
        return {
          past: [...past, { state: present, description: undefined }],
          present: nextState,
          future: newFuture
        };
      }
      default: {
        // Delegate action handling to the wrapped reducer.
        const newPresent = reducer(present, action);
        // If no state change, don't update history.
        if (newPresent === present) {
          return state;
        }
        // Extract the description from action.meta, if any.
        const description = action.meta?.description;
        // If description is missing or indicates a system update, don't record it.
        if (!description || description.includes('step updated')) {
          return {
            past,
            present: newPresent,
            future: [] // clear future on new action
          };
        }
        // Otherwise, record the change.
        return {
          past: [...past, { state: present, description }],
          present: newPresent,
          future: [] // clear future on new action
        };
      }
    }
  };
}
