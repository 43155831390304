import React, { useState, Fragment, useEffect } from "react";
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useParams
} from "react-router-dom";
import ExecViewFlow from '../components/execViewFlow';


const Executions = () => {
  const {execID} = useParams()

  return (<>
  
    <ExecViewFlow executionID={execID} />
    </>
  )
}

export default Executions;