import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import { Grid } from "@material-ui/core";
import dashboard from "./pages/dashboard";
import notfound from "./pages/404";
import connections from "./pages/connections";
import workflows from "./pages/workflows";
import editor from "./pages/editor";
import support from "./pages/support";
import settings from "./pages/settings";
import userProfile from "./pages/profile";
import executions from "./pages/executions"
import { makeStyles } from '@material-ui/core/styles';
import addAuthForm from "./pages/addAuthForm";
import editAppPage from "./pages/appDatabaseEdit";
import form from "./pages/form";
import page from "./pages/page";
import appDeveloper from "./pages/appDeveloper";
import appDeveloperAuthentication from "./pages/appDeveloperAuthentication";
import appDeveloperOverview from "./pages/appDeveloperOverview";
import appDeveloperAuthenticationEdit from "./pages/appDeveloperAuthenticationEdit";
import appDeveloperActions from "./pages/appDeveloperActions";
import appDeveloperActionsEdit from "./pages/appDeveloperActionsEdit";
import appDeveloperTriggers from "./pages/appDeveloperTriggers";
import appDeveloperTriggersEdit from "./pages/appDeveloperTriggersEdit";
import appDeveloperNew from "./pages/appDeveloperNew";
import appDeveloperActionsEditInput from "./pages/appDeveloperActionsEditInput";
import appDeveloperActionsEditApi from "./pages/appDeveloperActionsEditApi";



const useStyles = makeStyles({
  container: {
    display: "flex"
  }
});

function App() {
  const classes = useStyles();
  
  
  useEffect(() => {
    var visits = Number(localStorage.getItem('visitCount'));
    var current = Boolean(sessionStorage.getItem('session'));
    
  
    if (!current) {
      visits++;
    }
    
    localStorage.setItem('visitCount', visits);
    sessionStorage.setItem('session', true);
    
    
  
  }, [])

  

  return (

    <Grid container direction="row">
      <Router>
        <div className={classes.container}>
          <Switch>
            <Route exact path="/" component={editor} />
            <Route exact path="/dashboard" component={dashboard} />
            <Route exact path="/executions/:execID" component={executions} />
            <Route exact path="/workflows" component={workflows} />
            <Route exact path="/connections" component={connections} />
            <Route exact path="/settings" component={settings} />
            <Route exact path="/support" component={support} />
            <Route exact path="/profile" component={userProfile} />
            <Route exact path="/workflows" component={workflows} />
            <Route exact path="/addAuthForm" component={addAuthForm} />
            <Route exact path="/editApps" component={appDeveloper}/>
            <Route exact path="/form/:formId" component={form}/>
            <Route exact path="/page/:pageId" component={page}/>
            <Route exact path="/develop/" component={appDeveloper}/>
            <Route exact path="/develop/new" component={appDeveloperNew}/>
            <Route exact path="/develop/:appSlug" component={appDeveloperOverview}/>
            <Route exact path="/develop/:appSlug/authentication" component={appDeveloperAuthentication}/>
            <Route exact path="/develop/:appSlug/authentication/edit" component={appDeveloperAuthenticationEdit}/>
            <Route exact path="/develop/:appSlug/actions" component={appDeveloperActions}/>
            <Route exact path="/develop/:appSlug/actions/:actionKey/settings" component={appDeveloperActionsEdit}/>
            <Route exact path="/develop/:appSlug/actions/:actionKey/input" component={appDeveloperActionsEditInput}/>
            <Route exact path="/develop/:appSlug/actions/:actionKey/input/:id" component={appDeveloperActionsEditInput}/>
            <Route exact path="/develop/:appSlug/actions/:actionKey/api" component={appDeveloperActionsEditApi}/>
            <Route exact path="/develop/:appSlug/triggers" component={appDeveloperTriggers}/>
            <Route exact path="/develop/:appSlug/triggers/:triggerKey/settings" component={appDeveloperTriggersEdit}/>
            <Route exact path="/develop/:appSlug/actions/:triggerKey/input" component={appDeveloperTriggersEdit}/>
            <Route path="/404" component={notfound} />
            <Redirect to="/404" />
          </Switch>
        </div>
      </Router>
      <Grid item xs={12} />
    </Grid>

  );
};

export default App;
