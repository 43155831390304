import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Avatar,
  ListItemIcon,
  ListItemText,
  Divider,
  Menu,
  IconButton,
  CircularProgress,
  Modal,
  TextField,
  Button,
  Badge,
  FormHelperText,
  FormControl,
  MenuItem
} from '@material-ui/core';
import SecurityRoundedIcon from '@material-ui/icons/SecurityRounded';
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import { selectToken } from '../features/externalState/externalSlice';

function getModalStyle() {
  const top = 30;
  const left = 30;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  button2: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    justifyContent: 'center',
    width: '20%',
    backgroundColor: '#0069ea',
    borderRadius: '4px',
    border: 'none',
    height: '50px',
    cursor: 'pointer',
    textTransform: 'none'
  },
  button3: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    justifyContent: 'center',
    width: '100%',
    //backgroundColor: '#0069ea',
    borderRadius: '4px',
    border: '1px solid #0069ea',
    height: '50px',
    cursor: 'pointer',
    textTransform: 'none'
  },
  paper: {
    position: 'absolute',
    width: '30%',
    borderRadius: 5,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 8, 6),
  },
  badgeSuccess: {
    backgroundColor: '#4caf50',
    color: '#4caf50',
  },
  badgeFailure: {
    backgroundColor: '#f44336',
    color: '#f44336',
  },
}));

export default function AuthSelect({
  app,
  applabel,
  icon,
  custombutton,
  node,
  user,
  tenant,
  value,
  sendData,
  saveNode,
  sendAuth,
  sendLoggedOutToAuthorizer,
  breakpoint,
}) {
  const classes = useStyles();
  const [auths, setAuths] = useState([]);
  const [authname, setAuthName] = useState(node.data.authname); // "Zoho Books - MyName#1" etc.
  const [credentialStatus, setCredentialStatus] = useState(''); // 'success', 'failure', or ''
  const [modalStyle] = useState(getModalStyle);
  const [openModalState, setOpenModalState] = useState(false);
  const [namechange, setNameChange] = useState('');
  const [reallyDelete, setReallyDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [newAuthId, setNewAuthId] = useState(null); // track newly created credential ID
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [testLoading, setTestLoading] = useState(false)

  const spanRef = useRef();

  // 1) Load credentials
  const handleLoad = () => {
    if (!tenant || !applabel) return;
    axios
      .get(
        `https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/credentials?tenant=${tenant}&app=${applabel}`
      )
      .then((response) => {
        setAuths(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // 2) On mount or when tenant/app changes, load
  useEffect(() => {
    handleLoad();
  }, [tenant, applabel]);

  // 3) If no credential is stored, pick the lastUsed or last one
  useEffect(() => {
    if (auths.length > 0 && !node.data.authname) {
      console.log('0')
      const withTimestamp = auths.filter((c) => c.lastUsed);
      let selectedCredential;
      if (withTimestamp.length > 0) {
        console.log('1')
        // pick the one with the highest lastUsed
        selectedCredential = withTimestamp.reduce((prev, current) =>
          prev.lastUsed > current.lastUsed ? prev : current
        );
      } else {
        selectedCredential = auths[auths.length - 1];
        console.log('2')
      }
      if (selectedCredential) {
        console.log('3')
        const i = auths.indexOf(selectedCredential);
        const displayValue = `${selectedCredential.app} - ${selectedCredential.name ?? 'unnamed'}#${i + 1}`;
        setAuthName(displayValue);
        sendAuth(displayValue);
        sendData(selectedCredential._id.$oid);
        updateLastUsed(selectedCredential._id.$oid);
        testCredential(selectedCredential._id.$oid);
      }
    } else {
      if (node.data.authname && node.data.credential && !credentialStatus) {
        testCredential(node.data.credential);
      }
      
    }
  }, [auths, node.data.authname]);

  // 4) Update lastUsed
  const updateLastUsed = async (credentialId) => {
    const currentTimestamp = Date.now();
    try {
      await axios.patch(
        `https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/credentials?id=${credentialId}&key=lastUsed&value=${currentTimestamp}`
      );
    } catch (error) {
      console.error('Error updating lastUsed:', error);
    }
  };

  // 5) Test credential placeholder
  const testCredential = async (credentialId) => {
    let lambdaURL = null
    let testAction = null
    try {
      // placeholder test; assume success
      // If real test fails, set to 'failure'
      setTestLoading(true)
      axios({
        headers: {
            'content-type': 'application/json'
        },
        method: 'get',
        url: 'https://editor.workload.co:5000/api/apps/' + app  
    }).then(resp => {
      if (resp.data.lambdaURL !== "") {
        lambdaURL = resp.data.lambdaURL
    }
    else {
        lambdaURL = "https://odkusrnpx8.execute-api.us-west-2.amazonaws.com/production/apps/"+app
    }
      const authInfo = resp.data.authInfo
      if (resp.data.disableAuth === true) {
        setCredentialStatus('success');
        setTestLoading(false)
        return
      }
      else if (authInfo.apiType === 'oauth2') {
        if (Object.entries(resp.data.actions).filter(([key,value]) =>
            value.method === 'get' &&
            Array.isArray(value.fields) && value.fields.length === 0 &&
            value.url)[0]) {
                testAction = Object.entries(resp.data.actions).filter(([key,value]) =>
                value.method === 'get' &&
                Array.isArray(value.fields) && value.fields.length === 0 &&
                value.url)[0][0]
            }
                else {
                  setCredentialStatus('success');
                  setTestLoading(false)
                  return
                }
      } else {
        testAction = authInfo.testAuth
      }
      var testAuth = {
        method: 'post',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            "operation": "testAuth",
            "data": {
                "url": lambdaURL,
                "app": app,
                "credential": credentialId,
                "action": testAction,
                "fields": []
            }
        }
    };
    console.log('testAuth config is: ', testAuth)
    axios(testAuth)
          .then(function (response) {
            setCredentialStatus('success')
            setTestLoading(false)
        }).catch(function (error) {
          setCredentialStatus('failure');
        setTestLoading(false)
        })
      ;})
    } catch (error) {
      setCredentialStatus('failure');
      setTestLoading(false)
    }
  };

  // 6) Handling new credentials from the popup
  const handleNewAuth = (credentialId, displayValue) => {
    // Force a reload of all credentials
    axios
      .get(
        `https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/credentials?user=${user}&app=${applabel}`
      )
      .then((res) => {
        setAuths(res.data);
        // If the newly created credential has no name, prompt user to set it
        setNewAuthId(credentialId); // store the newly created ID
        const found = res.data.find((c) => c._id.$oid === credentialId);

        if (!found?.name) {
          // open the rename modal automatically
          setNameChange(''); // or default to applabel
          setOpenModalState(true);
        } else {
          // otherwise, update UI as normal
          const i = res.data.indexOf(found);
          const showName = `${found.app} - ${found.name ?? 'unnamed'}#${i + 1}`;
          setAuthName(showName);
          sendAuth(showName);
          sendData(credentialId);
          updateLastUsed(credentialId);
          testCredential(credentialId);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // 7) The popup logic
  let windowObjectReference = null;
  let previousUrl = null;

  const addAuth = (url, name) => {
    if (!user) {
      sendLoggedOutToAuthorizer(true);
      return;
    }
    window.removeEventListener('message', receiveMessage);
    const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open(url, name, strWindowFeatures);
    } else if (previousUrl !== url) {
      windowObjectReference = window.open(url, name, strWindowFeatures);
      windowObjectReference.focus();
    } else {
      windowObjectReference.focus();
    }
    window.addEventListener('message', receiveMessage, false);
    previousUrl = url;
  };

  const receiveMessage = (event) => {
    const { data } = event;
    // data is the newly created credential's _id.$oid
    sendData(data);
    // fetch that single credential
    axios
      .get(
        `https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/credentials?tenant=${tenant}&id=${data}`
      )
      .then((response) => {
        // example: response.data[0] is the newly created credential
        if (!response.data || !response.data[0]) return;
        const displayValue = `${response.data[0].app} - ${response.data[0].name}`;
        handleNewAuth(data, displayValue);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // 8) Deleting
  const doDelete = async (credentialId) => {
    try {
      await axios.delete(
        `https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/credentials?id=${credentialId}`
      );
      // Re-load the credentials from the server
      handleLoad();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteConnection = (credentialId) => {
    if (reallyDelete === true && typeof credentialId === 'string') {
      setDeleting(true);
      doDelete(credentialId);
      setAuthName('');
      saveNode(node);
      setDeleting(false);
      setReallyDelete(false);
      handleMoreClose();
    }
  };

  // 9) Editing the name
  const handleOpenModal = () => {
    setOpenModalState(true);
  };
  const handleCloseModal = () => {
    setOpenModalState(false);
  };
  const handleNameChange = (val) => {
    setNameChange(val);
  };

  const doSubmit = async () => {
    try {
      // if we just created a brand new credential, use that ID
      const credIdToUpdate = newAuthId || node.data.credential;
      if (!credIdToUpdate) {
        handleCloseModal();
        return;
      }
      await axios.patch(
        `https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/credentials?id=${credIdToUpdate}&key=name&value=${namechange}`
      );
      setOpenModalState(false);
      handleLoad(); // reload all credentials
      // Then set the updated name as the "authName"
      // We can do a quick GET to fetch the updated credential:
      const updatedRes = await axios.get(
        `https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/credentials?tenant=${tenant}&id=${credIdToUpdate}`
      );
      if (updatedRes.data && updatedRes.data[0]) {
        const c = updatedRes.data[0];
        // find its index in the array for the # index
        const indexInAuths = auths.findIndex((x) => x._id.$oid === credIdToUpdate);
        const displayName = `${c.app} - ${c.name ?? 'unnamed'}#${indexInAuths + 1}`;
        setAuthName(displayName);
        sendAuth(displayName);
        sendData(credIdToUpdate);
        updateLastUsed(credIdToUpdate);
        testCredential(credIdToUpdate);
      }
      setNewAuthId(null);
    } catch (error) {
      console.error(error);
    }
  };
  function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  }
  
  function formatTimestamp(ts) {
    const date = new Date(ts);
    const month = date.toLocaleString('en-US', { month: 'short' });
    const day = date.getDate();
    const suffix = getOrdinalSuffix(day);
    const year = date.getFullYear();
    const time = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
    return `${month} ${day}${suffix}, ${year} ${time}`;
  }
  
  // 10) Menus for "Change" and "More"
  const [anchorElChange, setAnchorElChange] = useState(null);
  const [anchorElMore, setAnchorElMore] = useState(null);

  const handleChooseClick = () => {
    setAnchorElChange(spanRef.current);
  };
  const handleChooseClose = () => {
    setAnchorElChange(null);
  };
  const handleMoreClick = () => {
    setAnchorElMore(spanRef.current);
  };
  const handleMoreClose = () => {
    setAnchorElMore(null);
  };

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
      width: spanRef.current ? spanRef.current.offsetWidth : 583,
      marginTop: '8px',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    />
  ));

  // 11) The main UI
  return (
    <div>
      {/* If no credentials exist */}
      {auths.length === 0 ? (
        <div>
          <br />
          <div
            style={{
              display: 'flex',
              border: '1px solid silver',
              borderRadius: '5px',
              padding: '12px',
              marginBottom: '12px',
            }}
          >
            <div
              style={{
                float: 'left',
                display: 'flex',
                alignItems: 'center',
                marginRight: 'auto',
                marginTop: '4px',
              }}
            >
              <Avatar
                style={{
                  width: breakpoint === 'small' ? '24px' : null,
                  height: breakpoint === 'small' ? '24px' : null,
                }}
                src={icon}
              />
              <Typography
                variant="h6"
                style={{
                  marginLeft: '18px',
                  color: 'black',
                  fontWeight: 'bold',
                  fontSize: breakpoint !== 'large' ? '16px' : null,
                }}
              >
                Connect {applabel}
              </Typography>
            </div>
            {custombutton ? (
              <div style={{ flex: 1, alignItems: 'center', cursor: 'pointer' }}>
                <img
                  style={{ marginLeft: '6px', width: '100%' }}
                  src={custombutton}
                  onClick={() =>
                    addAuth(`https://editor.workload.co:5000/auth/${app}?uid=${user}&t=${tenant}`, 'authWindow')
                  }
                />
              </div>
            ) : (
              <button
                size="small"
                id="new-auth-button"
                className={classes.button2}
                color="primary"
                variant="contained"
                onClick={() =>
                  addAuth(`https://editor.workload.co:5000/auth/${app}?uid=${user}&t=${tenant}`, 'authWindow')
                }
              >
                <Typography
                  variant="h6"
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: breakpoint !== 'large' ? '16px' : null,
                  }}
                >
                  Sign in
                </Typography>
              </button>
            )}
          </div>
          <SecurityRoundedIcon style={{ color: 'gray', height: '18px', float: 'left', display: 'block' }} />
          <Typography style={{ fontSize: '14px', color: 'gray' }}>
            {applabel} is a secure partner with Workload.{' '}
            <a style={{ color: '#0069ea' }} href="https://workload.co/privacy" target="_blank" rel="noreferrer">
              Your credentials are encrypted &amp; can be removed at any time
            </a>
            .{' '}
            <a style={{ color: '#0069ea' }} href="https://automate.workload.co/connection" target="_blank" rel="noreferrer">
              Manage your connected accounts here
            </a>
            .
          </Typography>
        </div>
      ) : (
        // If credentials exist
        <>
          <Typography>
            <strong>{applabel} account:</strong> (required)
          </Typography>

          {/* Show success/failure badge if we have a credentialStatus */}
          {/* {credentialStatus === 'success' && (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '12px', gap: '8px' }}>
              <Badge classes={{ badge: classes.badgeSuccess }} variant="dot" overlap="circle">
                <span style={{ width: '12px', height: '12px' }} />
              </Badge>
              <Typography variant="subtitle2" style={{ color: '#4caf50' }}>
                Connection successful
              </Typography>
            </div>
          )} */}
          {credentialStatus === 'failure' && (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '12px', gap: '8px' }}>
              <Badge classes={{ badge: classes.badgeFailure }} variant="dot" overlap="circle">
                <span style={{ width: '12px', height: '12px' }} />
              </Badge>
              <Typography variant="subtitle2" style={{ color: '#f44336' }}>
                Connection expired, please reconnect an account
              </Typography>
            </div>
          )}

          <div style={{ marginTop: '12px' }}>
            <div
              ref={spanRef}
              style={{
                display: 'flex',
                border: '1px solid silver',
                borderRadius: '5px',
                padding: '12px',
                marginBottom: '12px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', marginRight: 'auto' }}>
                <Avatar
                  style={{
                    width: breakpoint === 'small' ? '24px' : null,
                    height: breakpoint === 'small' ? '24px' : null,
                  }}
                  src={icon}
                />
                <Typography
                  variant="h6"
                  style={{
                    marginLeft: '24px',
                    color: 'black',
                    fontWeight: 'bold',
                    fontSize: breakpoint !== 'large' ? '16px' : null,
                  }}
                >
                  {authname
                    ? authname.split('#')[0] === `${applabel} - undefined`
                      ? `${applabel} - unnamed`
                      : authname.split('#')[0]
                    : `Connect ${applabel}`}
                </Typography>
              </div>
              <button
                size="small"
                id="change-auth-button"
                className={classes.button2}
                color="primary"
                variant="contained"
                onClick={() => setAnchorElChange(spanRef.current)}
              >
                <Typography
                  variant="h6"
                  style={{ color: 'white', fontWeight: 'bold', fontSize: breakpoint !== 'large' ? '16px' : null }}
                >
                  {authname ? 'Change' : 'Choose'}
                </Typography>
              </button>
              {authname && (
                <IconButton onClick={() => setAnchorElMore(spanRef.current)} style={{ float: 'right', color: 'black' }}>
                  <MoreHorizRoundedIcon />
                </IconButton>
              )}
            </div>
            {/* "Change" dropdown */}
            <StyledMenu
              id="change-menu"
              anchorEl={anchorElChange}
              keepMounted
              open={Boolean(anchorElChange)}
              onClose={() => setAnchorElChange(null)}
            >
              <Typography style={{ padding: '10px', fontWeight: 700 }} variant="h6">
                Choose...
              </Typography>
              <Divider style={{ marginBottom: '6px' }} />
              {auths.length > 0
                ? auths.map((item, i) => {
                    const displayVal = `${item.app} - ${item.name ?? 'unnamed'}`;
                    const displayCreatedAt = item.createdAt ? "Created: "+ formatTimestamp(item.createdAt) : null
                    return (
                      <MenuItem
                        key={item._id.$oid}
                        onClick={() => {
                          setAuthName(displayVal);
                          setAnchorElChange(null);
                          updateLastUsed(item._id.$oid);
                          testCredential(item._id.$oid);
                          sendAuth(displayVal);
                          sendData(item._id.$oid);
                        }}
                      >
                        <ListItemIcon>
                          <Avatar
                            style={{
                              width: breakpoint === 'small' ? '18px' : '26px',
                              height: breakpoint === 'small' ? '18px' : '26px',
                            }}
                            src={icon}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={displayVal}
                          secondary={displayCreatedAt}
                          primaryTypographyProps={{
                            style: { fontWeight: 'bold', fontSize: '20px', marginLeft: '0px' },
                          }}
                        />
                      </MenuItem>
                    );
                  })
                : (
                  <MenuItem disabled>+ Add a new connection</MenuItem>
                )}
              <Divider />
              <div style={{ margin: '8px' }}>
                <Button
                  size="small"
                  id="new-auth-button"
                  className={classes.button3}
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setAnchorElChange(null);
                    addAuth(`https://editor.workload.co:5000/auth/${app}?uid=${user}&t=${tenant}`, 'authWindow');
                  }}
                >
                  <Typography style={{ color: '#0069ea', fontSize: breakpoint !== 'large' ? '12px' : null }}>
                    + Add new {applabel} account
                  </Typography>
                </Button>
              </div>
            </StyledMenu>

            {/* "More" dropdown */}
            <StyledMenu
              id="more-menu"
              anchorEl={anchorElMore}
              keepMounted
              open={Boolean(anchorElMore)}
              onClose={() => setAnchorElMore(null)}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              style={{width: '300px'}}
            >
              <MenuItem
                onClick={() => {
                  setAnchorElMore(null);
                  setOpenModalState(true);
                }}
              >
                <ListItemIcon>
                  <EditRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Edit connection name" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorElMore(null);
                  addAuth(
                    `https://editor.workload.co:5000/auth/${app}?uid=${user}&t=${tenant}&update=true&c_id=${node.data.credential}`,
                    'authWindow'
                  );
                }}
              >
                <ListItemIcon>
                  <DescriptionRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Update connection info" />
              </MenuItem>
              {reallyDelete ? (
                <MenuItem
                  onClick={() => {
                    setAnchorElMore(null);
                    handleDeleteConnection(node.data.credential);
                  }}
                >
                  {deleting ? (
                    <CircularProgress size={20} color="secondary" style={{ margin: '0 auto' }} />
                  ) : (
                    <ListItemIcon>
                      <DeleteRoundedIcon fontSize="small" style={{ color: 'red' }} />
                    </ListItemIcon>
                  )}
                  <ListItemText primary={deleting ? '' : 'Really delete?'} />
                </MenuItem>
              ) : (
                <MenuItem onClick={() => setReallyDelete(true)}>
                  <ListItemIcon>
                    <DeleteRoundedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Delete connection" />
                </MenuItem>
              )}
            </StyledMenu>
          </div>
          <FormHelperText>
            <SecurityRoundedIcon style={{ color: 'gray', height: '15px', float: 'left', display: 'block' }} />
            <Typography style={{ fontSize: '14px', color: 'gray' }}>
              {applabel} is a secure partner with Workload.{' '}
              <a style={{ color: '#0069ea' }} href="https://workload.co/privacy" target="_blank" rel="noreferrer">
                Your credentials are encrypted &amp; can be removed at any time
              </a>
              .{' '}
              <a style={{ color: '#0069ea' }} href="https://automate.workload.co/connection" target="_blank" rel="noreferrer">
                Manage your connected accounts here
              </a>
              .
            </Typography>
          </FormHelperText>
        </>
      )}

      <Modal open={openModalState} onClose={handleCloseModal}>
        <div style={modalStyle} className={classes.paper}>
          <h2>Edit connection name</h2>
          <TextField
            style={{ width: '100%' }}
            required
            variant="outlined"
            label="Name (required)"
            value={namechange}
            onChange={(e) => handleNameChange(e.target.value)}
            helperText="Enter a name that will help you remember which account this connection belongs to."
          />
          <Button
            variant="contained"
            color="primary"
            style={{ width: '100%', marginTop: '16px' }}
            onClick={doSubmit}
          >
            OK
          </Button>
        </div>
      </Modal>
    </div>
  );
}
