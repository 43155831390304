import React, {useEffect, useLayoutEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import ReactFlow, { Handle } from 'react-flow-renderer';
import axios from 'axios'
import { Card, CircularProgress, Snackbar, Paper, Grid,ClickAwayListener, Input, InputAdornment, InputBase,Popper,MenuItem,ListItemIcon,ListItemText, Avatar, Badge, Button, Chip, Typography, TextField, Tooltip } from '@material-ui/core';
import AppsRoundedIcon from '@material-ui/icons/OfflineBoltOutlined';
import AppsIcon from '@material-ui/icons/AppsRounded';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import MuiAlert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { alertName } from '../../redux/ducks/notification';
import {
  nodeAdded, edgeAdded, elementUpdated, notification, nameUpdated, elementDeleted, fetchWorkflow, nodeEdgeAdded, saveWorkflow, elementSelected,
  selectWorkflowElements, selectElementById, selectWorkflowName, selectWorkflowId, selectParentTree, selectWorkflowTemplate, selectWorkflowActive, selectCurrentElement,
} from '../../features/workflow/workflowSlice'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    marginLeft: theme.spacing(3),
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  highlighted: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    marginLeft: theme.spacing(0.7)
  },
  handle: {
    backgroundColor: 'white',
    height: 20,
    width: 20,
    marginBottom: '-15px',
    // border: '1px solid gray',
    borderRadius: 50,
    zIndex: 9,
    backgroundPosition: 'center center',
    backgroundImage: "url('/add-circle.svg')"
  },
  handleHalo: {
    backgroundColor: 'white',
    content: '+',
    height: 20,
    width: 20,
    borderRadius: 50,
    zIndex: 9,
    backgroundPosition: 'center center',
    backgroundImage: "url('/add-circle.svg')",
    // color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0.15,
      right: 0.15,
      left: 0.25,
      width: 20,
      height: 20,
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      animationIterationCount: 10,
      animationFillMode: 'forwards',
      border: '1px solid #0069ea',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
  card: {
    position: 'relative',
    width: '450px',
    paddingBottom: '35px',
    cursor: 'pointer',
    height: '50px',
    paddingTop: '9px',
    borderRadius: '4px',
    "& > *:hover": {
      border: '1px solid #0069ea'
    }
  },
  cardblack: {
    position: 'relative',
    width: '450px',
    paddingBottom: '35px',
    cursor: 'pointer',
    height: '200px',
    paddingTop: '9px',
    borderRadius: '4px'
  },
  more: {
    zIndex: 99999,

  },
  popper: {
      border: '1px solid lightgray',
      borderRadius: '4px',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      zIndex: 999,
      height: '50px',
      boxShadow: '0 5px 5px 5px lightgray',
      width: '200px'
  }
}));



const TriggerInput = (props) => {
  
  return <Input className='nodrag' startAdornment={
   <InputAdornment position="start">
     <CreateRoundedIcon style={{ color: 'silver' }} />
   </InputAdornment>
 } style={{ marginBottom: '15px', width: "300px", fontSize: '14px', fontWeight: 'bold' }}  onChange={props.onChange} defaultValue={props.value} value={props.value} id="name" placeholder="Name this step..." />
 
 }

const TriggerNode = ({ data, id }) => {
  //console.log('data from node is: ', data)
  //console.log('id from node is: ', id)
  const node = useSelector(selectElementById(id))
  const workflow_active = useSelector(selectWorkflowActive)
  const [name, setName] = useState(data.name);
  const [blurname, setBlurName] = React.useState()
  const [app, setApp] = useState(data.app);
  const [type, setType] = useState(data.type)
  const [label, setLabel] = useState(data.label);
  const [triggerlabel, setTriggerLabel] = useState(data.triggerlabel)
  const [icon, setIcon] = useState(data.icon);
  const [triggers, setTriggers] = useState([]);
  const [trigger, setTrigger] = useState({key:data.action, label:data.action});
  const [credentialId, setCredentialId] = useState(data.credential)
  const [authname, setAuthname] = useState(data.authname)
  const [pollingParams, setPollingParams] = useState(data.pollingParams)
  const [performList, setPerformList] = useState(data.performList)
  const [unsubscribe, setUnsubscribe] = useState(data.unsubscribe)
  const [triggerMessage, setTriggerMessage] = useState(data.triggerMessage)
  const [triggerChoices, setTriggerChoices] = useState(data.triggerChoices)
  const [triggerchoice, setChoice] = useState(data.triggerchoice)
  const [webhookKey, setWebhookKey] = useState(data.webhookKey)
  const [triggerChoiceTime, setTriggerChoiceTime] = useState(data.triggerChoiceTime)
  const [url, setURL] = useState(data.url)
  const [fields, setFields] = useState(data.fields)
  const [hasauth, setHasAuth] = useState(data.hasauth)
  const [testData, setTestData] = useState(data.testData)
const [renamingstep, setRenameStep] = useState(false)
const [nodrag, setNoDrag] = useState(false)
  const [badge, setBadge] = useState()
  const activeel = useSelector(selectCurrentElement)
  
  const [hover, setHover] = React.useState(false)
  const [hover2, setHover2] = React.useState(false)
  const [hover3, setHover3] = useState(false)
  const dispatch = useDispatch();
  const currentElement = useSelector(selectCurrentElement)
  //const [hovercard, setHoverCard] = React.useState(false)
  //console.log('haloeffect value is: ',haloeffect)
  const classes = useStyles();
  const ErrorBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: 'red',
      color: 'red',
      position: 'absolute',
      boxShadow: `0 0 0 3px ${theme.palette.background.paper}`,
    },
  }))(Badge);

  const SuccessBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: 'green',
      color: 'green',
      position: 'absolute',
      boxShadow: `0 0 0 3px ${theme.palette.background.paper}`,
    },
  }))(Badge);

  const WarningBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: 'orange',
      color: 'orange',
      position: 'absolute',
      boxShadow: `0 0 0 3px ${theme.palette.background.paper}`,
    },
  }))(Badge);

  const openDropdown = (event) => {
    event.stopPropagation(); // prevent parent click handler from firing
    setAnchorElMenu(event.currentTarget);
    setNoDrag(true)
  }

  const saveNode = () => {
    //   console.log('saving node')
    dispatch(elementUpdated(id,
      {
        name: name,
        type: type,
        app: app,
        label: label,
        triggerlabel: triggerlabel,
        icon: icon,
        hasauth: hasauth,
        action: trigger.key,
        triggerResource: Object.keys(triggers).length > 0 ? trigger ? triggers[trigger.key].action : '' : '',
        credential: credentialId,
        authname: authname,
        fields: fields,
        testData: testData,
        url: url,
        pollingParams: pollingParams,
        triggerMessage: triggerMessage,
        performList: performList,
        unsubscribe: unsubscribe,
        webhookKey: webhookKey,
        triggerChoices: triggerChoices,
        triggerchoice: triggerchoice,
        triggerChoiceTime: triggerChoiceTime
    },
      null
  ));
}

  const handleSaveClick = () => {
       console.log('trigger save clicked')
    dispatch(saveWorkflow(1))
  }

  const closeDropdown = (event) => {
    setAnchorElMenu(null);
    setNoDrag(false)
  }
  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Popper className={classes.popper}
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ));

  //handling of dropdown menu
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);

  const renameStep = (id) => {
    closeDropdown()
    //console.log('do stuff to rename this step: ', id)
    setRenameStep(true)
  }

  useEffect(()=> {
    //here you will have correct value in userInput 
   // console.log('new name is: ', name)
    saveNode()
},[name])

    useEffect(() => {
   //console.log('node data is: ', node)
    if (data.hasauth === true && !data.credential  || data.hasauth === true && data.credential === "" ) {
      setBadge('error')
    }

    else if (!data.action) {
      setBadge('error')
    }
    
    else if (data.performList ? !data.triggerChoices : !data.triggerMessage ?? data.triggerMessage === "") {
      setBadge('error')
    }

    else if (!data.testData ?? data.testData === "") {
      setBadge('error')
    }
    else {
      setBadge('ok')
    }
    }, [node])




const [running, setRunning] = useState(false)

const handleRunWorkflow = (webhook) => {
  if (workflow_active === false) {
  
    dispatch(notification({"message": "Workflow must be ACTIVE", "status": "warning"}))
   
  } else {
  setRunning(true)
  var data = {
    "webhook" : webhook
  }
  axios.post('https://editor.workload.co:5000/run', data)
  .then(function (response) {
      setRunning(false)
  
   dispatch(notification({"message": "Manual run complete", "status": "success"}))
  
    })
.catch(function (error) {
// handle error
setRunning(false)

dispatch(notification({"message": error.message, "status": "error"}))


console.log(error);
})
  }

}

const elements = useSelector(selectWorkflowElements);

const hasOutgoingEdge = elements.some((el) => el.source === id);


  return (
    

      <div className={ classes.card} onMouseEnter={() => setHover3(true)} onMouseLeave={() => setHover3(false)} >
        <Card style={{boxShadow: '-1px 5px 5px 1px lightgray', border:  currentElement === id ? '3px solid #0069ea' : null, backgroundColor:  currentElement === id ? '#e9f4fd' : null}}>
          {badge === 'error' ?
            <ErrorBadge
              style={{float: 'left', marginTop: '20px', marginLeft: '9px' }}
              overlap="circle"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              variant="dot"
            >
              <Avatar style={{ display: 'flex', float: 'left', marginTop: '0px', marginLeft: '0px',height:50, width:50, boxShadow: '1px 5px 5px 0px silver' }} label={data.name} src={data.icon} alt={data.name}>
                <AppsRoundedIcon style={{ width: 40, height: 40 }} />
              </Avatar>
            </ErrorBadge>
            :
            <SuccessBadge
              style={{ float: 'left', marginTop: '20px', marginLeft: '9px' }}
              overlap="circle"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              variant="dot"
            >
              <Avatar style={{ display: 'flex', float: 'left', marginTop: '0px', marginLeft: '0px',height:50, width:50, boxShadow: '1px 5px 5px 0px silver' }} label={data.name} src={data.icon} alt={data.name}>
                <AppsRoundedIcon style={{ width: 40, height: 40 }} />
              </Avatar>
            </SuccessBadge>}

          <div style={{ display: 'block', float: 'left', marginLeft: '10px' }}>
            <Typography style={{ fontSize: '14px', color: 'gray', marginTop: '20px' }}>Trigger</Typography>

            { renamingstep ? <div onBlur={()=>{ setRenameStep(false);setName(blurname); handleSaveClick()}}><TriggerInput onChange={e => { setBlurName(e.target.value); }} defaultValue={node ? node.data.name : data.name} value={blurname}  /></div>
          
            :
             <Typography noWrap={true} style={{display: 'block', marginTop: '0px', marginBottom: '20px', width: '250px', fontSize: '20px', fontWeight: 'bolder' }} >1. {node ? node.data.name : data.name}</Typography> }
          </div>
          <div style={{ position: 'absolute', display: 'flex', float: 'right', marginTop: '30px', right: 50 }} >
            {hover3 === true ? <Button variant="contained" color="primary"  >Edit</Button> : null}
          </div>
         
          <ClickAwayListener onClickAway={closeDropdown}>
        
          <div className="nodrag" style={{ cursor: 'pointer', float: 'right', marginRight: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '45px' }}>
            <MoreVertRoundedIcon style={{ position: 'absolute', float: 'right', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={openDropdown} />
            
            <StyledMenu 
              style={{height: data.app === 'schedule' ? '100px' : null}}
              id="customized-menu"
              anchorEl={anchorElMenu}
              
              open={Boolean(anchorElMenu)}
              onClose={closeDropdown}
            >
            {data.app === 'schedule' ? <MenuItem className="nodrag"  onClick={() => handleRunWorkflow(node.data.testData.webhookURL) }>
            <ListItemIcon>
             {running === true ? <CircularProgress size={30} color="secondary" /> : <PlayCircleOutlineRoundedIcon fontSize="small" color="black" /> }
            </ListItemIcon>
            <ListItemText style={{ marginRight: 20 }} primary={running ? "Running..." : "Run Workflow"} />
          </MenuItem> : null }
              <MenuItem className="nodrag"  onClick={() => renameStep(id) }>
                <ListItemIcon>
                  <EditRoundedIcon fontSize="small" color="black" />
                </ListItemIcon>
                <ListItemText style={{ marginRight: 20 }} primary="Rename Step" />
              </MenuItem>
            
            </StyledMenu>
          </div>
          </ClickAwayListener>
          
        </Card>
        {hasOutgoingEdge ? 
         <Tooltip title={<Typography style={{fontSize: '16px'}}>Add path</Typography>} placement="right">
         <Handle style={hover2 === true ? {
           backgroundColor: '#0069ea',
           marginBottom: '-15px',
           height: 20,
           width: 20,
           borderRadius: 50,
           //border: '1.5px solid gray',
           boxShadow: '0 3px 5px 2px lightgray',
           cursor: 'pointer',
           backgroundPosition: 'center center', backgroundImage: "url('/branch.svg')"
         } : {
           backgroundColor: '#0069ea',
           marginBottom: '-15px',
           height: 20,
           width: 20,
           borderRadius: 50,
           //border: '1px solid gray',
           cursor: 'pointer',
           backgroundPosition: 'center center', backgroundImage: "url('/branch.svg')"
         }}
           type="source"
           position="bottom"
           id="a"
           onMouseEnter={() => setHover2(true)}
           onMouseOut={() => setHover2(false)}
         />
       </Tooltip> :
        <Tooltip title={<Typography style={{fontSize: '16px'}}>Add step</Typography>} placement="right">
        <Handle style={hover2 === true ? {
          backgroundColor: '#0069ea',
          marginBottom: '-15px',
          height: 20,
          width: 20,
          borderRadius: 50,
          //border: '1.5px solid gray',
          boxShadow: '0 3px 5px 2px lightgray',
          cursor: 'pointer',
          backgroundPosition: 'center center', backgroundImage: "url('/add-circle.svg')"
        } : {
          backgroundColor: '#0069ea',
          marginBottom: '-15px',
          height: 20,
          width: 20,
          borderRadius: 50,
          //border: '1px solid gray',
          cursor: 'pointer',
          backgroundPosition: 'center center', backgroundImage: "url('/add-circle.svg')"
        }}
          type="source"
          position="bottom"
          id="a"
          onMouseEnter={() => setHover2(true)}
          onMouseOut={() => setHover2(false)}
        />
      </Tooltip>
}
      </div>
      
    
     
    
 
    
     

  );
};

export default TriggerNode;