import React from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import mixpanel from 'mixpanel-browser';
import { hotjar } from 'react-hotjar';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
// import store from './redux/configureStore';
import store from './app/store'
import { createMuiTheme, createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

const url = window.location.origin
if (!url.includes('workload') && !url.includes('https')) {
  window.location = `https:${url.split(':')[1]}`
}


const theme = createMuiTheme({
  overrides: {
    MuiStepIcon: {
     root: {
       '&$completed': {
         color: '#4caf50',
       },
       '&$error': {
        color: 'red',
      },
       '&$active': {
         color: '#0069ea',
       },
     },
     active: {},
     completed: {},
     error: {}
   },
  
},
palette: {
  type: "light",
  primary: { main: '#0069ea' },
  secondary: { main: '#ff5555' },
  success: { main: '#4caf50'}
}
});

ReactDOM.render(
 
    <HttpsRedirect>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </HttpsRedirect>,
  document.getElementById('root')

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
mixpanel.init('d410d8313a8736e962ffad2cfb6f4570');
//hotjar.initialize(2768116, 6);