import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import Avatar from '@material-ui/core/Avatar';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography, Button, Chip, Tooltip } from '@material-ui/core';
import { ContactSupportOutlined } from '@material-ui/icons';

const GreenSwitch = withStyles({
  switchBase: {
    color: '#ffffff',
    '&$checked': {
      color: '#4caf50',
    },
    '&$checked + $track': {
      backgroundColor: '#4caf50',
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: '5px'
  },
  toolbar: {
    // We use relative so we can absolutely-position the center text.
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    minHeight: theme.mixins.toolbar.minHeight, // keep standard height
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  leftSection: {
    display: 'flex',
    alignItems: 'center',
  },
  // The center text is absolutely positioned so it remains truly centered.
  centerContainer: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonContainer: {
    marginLeft: 'auto', 
    display: 'flex',
    gap: theme.spacing(2),
  },
}));

export const AppBarHeaderExecutions = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ background: 'inherit' }}>
        <Toolbar className={classes.toolbar}>
          {/* Left Section */}
          <div className={classes.leftSection}>
        <Tooltip title={<Typography style={{fontSize: '16px'}}>Exit to dashboard</Typography>} placement="top">
          <IconButton edge="start" color="default" aria-label="menu" onClick={()=>  window.location.href = "https://automate.workload.co/"}>
            <ChevronLeftRoundedIcon />
          <Avatar
            alt="Workload Canvas Editor"
            src="https://cdn.workload.co/wp-content/uploads/new-logo-only-512-512.png"
          />
          </IconButton>
          </Tooltip>
          <Chip 
          size="small"
          label="Workflow History"
          variant="outline"
          color="primary"
          style={{marginLeft: '16px'}}
          />           
          </div>

             {/* Center Section (Absolutely Centered) */}
             <div className={classes.centerContainer}>
            <Typography variant="body1" style={{ fontWeight: 700, fontSize: '18px', color: 'black' }}>
              {props.workflowName}
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 400, fontSize: '12px', color: 'black' }}>
            Run Timestamp: {props.workflowTime}
           </Typography>
          </div>
          {/* Buttons on the right side */}
          <div className={classes.buttonContainer}>
          <Tooltip title={<Typography style={{fontSize: '16px'}}>Replay this workflow</Typography>} placement="top">

            <Button 
            variant="outlined" 
            color="primary"
            onClick={props.onReplay}>
              Replay
            </Button>
            </Tooltip>
            <Tooltip title={<Typography style={{fontSize: '16px'}}>Edit this workflow</Typography>} placement="top">
            <Button 
            variant="contained" 
            color="primary"
            onClick={props.onEdit}
            >
              Edit
            </Button>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};
