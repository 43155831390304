import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {Card,CardMedia, CircularProgress, IconButton, Input, Typography, Divider, Button, Box, LinearProgress, TextField, Checkbox, RadioGroup, FormControlLabel, Radio, FormGroup, withStyles  } from "@material-ui/core";
import Inspector from 'react-json-inspector';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import axios from 'axios';



const Page = () => {
  

 

  /* React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []); */

     var pathArray = window.location.pathname.split('/');
    // console.log('pathArray is: ', pathArray)
     var formId = pathArray[2]
     if (!formId) {
      //  console.log('no form id...', formId)
     }
     else {
      //  console.log('form id exists: ', formId)
     }


  const [djangoId, setDjangoId] = useState(null);
  const [loading, setLoading] = useState('initializing');
  
  
  const [scaffoldId, setScaffoldId] = useState(null);
  const [success, setSuccess] = useState()
     const getDjangoWorkflow = () => {
        var config = {
          method: 'get',
          url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/scaffolddjangomap?scaffoldId=' + formId,
          headers: {
            'Content-Type': 'application/json'
          }
    
        };
        axios(config)
          .then(function (response) {
            var datalength = response.data.length;
           // console.log('length is ', datalength)
            if (datalength === 0) {
              //if the scaffoldmapper doesn't return anything, create a fresh workflow
              //console.log('saving new django, then posting to scaffolddjangomapper');
    
              setLoading('loaded');
            }
            else {
              //get the frontendWorkflow using id from the above function and return the workflow
            // console.log('get success to docdb for scaffolddjangomap', response.data);
              var idfromdjango = response.data[0].djangoId;
             // console.log('djangoid from mapper is ', idfromdjango);
              setDjangoId(idfromdjango)
              setScaffoldId(response.data[0].scaffoldId)
              setLoading('loaded');
              var data4 = {id: idfromdjango}
              let config_send = {
                method: 'get',
                url: 'https://editor.workload.co:5000/retrievePage',
                params: data4
                };
            
                axios(config_send)
                .then((response) => {
              console.log('response: ', response.data)
                setElements(response.data.fields)
               // setWebhookURL(response.data.webhook_url)
              }).catch((error) => {
                console.log(error);
                
                });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }

      useEffect(() => {
        if (loading != 'loaded') {
          getDjangoWorkflow()
         // console.log('getting djangoworkflow')
        } else {
    
        }
      }, [loading])

      const [elements, setElements] = useState()
      const htmlString = '<div><h1>Hello, World!</h1><p>This is a paragraph.</p></div><button onclick="console.log(\'siiiike!\')">Click Here for Sex!</button>';

     
    
      
   
    return (
        <div dangerouslySetInnerHTML={{ __html: htmlString }} />

    );
};

export default Page;