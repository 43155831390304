import React, { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import ReactFlow, {
  ReactFlowProvider,
  Background,
} from "react-flow-renderer";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchExecution,
  selectFlowElements,
  selectWorkflowID,
  elementSelected as executionElementSelected,
} from "../features/execution/executionSlice";
import {
  fetchWorkflow,
  selectWorkflowTemplateNodes,
  elementSelected as workflowElementSelected,
  selectNotification,
  notification,
} from "../features/workflow/workflowSlice";

import ExecutionNode from "./CustomNodes/ExecutionNode";
import FilterEdge from "./CustomEdges/FilterEdge";
import { AppBarHeaderExecutions } from "./AppBarExecutions";

import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  CircularProgress,
  Snackbar,
  Tabs,
  Tab,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Inspector from "react-json-inspector";

// ============== DAGRE FOR LAYOUT ==============
import dagre from "dagre";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// Helper to link each tab to a panel
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

// ============== STYLING ==============
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  dialogPaper: {
    minHeight: "80vh",
    maxHeight: "80vh",
    overflowY: "scroll",
  },
  fab: {
    "& > *": {
      margin: theme.spacing(1),
    },
    position: "fixed",
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    zIndex: 9,
  },
}));

// We want a gray background that fills the entire screen behind the flow
const flowStyle = {
  background: "#f2f2f2",
  height: "100vh",
  width: "100vw",
  zIndex: 0,
  cursor: "grab",
};

// ============== DAGRE LAYOUT HELPERS ==============
const nodeWidth = 450;
const nodeHeight = 80;
function getLayoutedElements(elements, direction = "TB") {
  // 1) Create a new graph
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: direction, nodesep: 50, ranksep: 100 });

  // 2) Separate out nodes and edges
  const nodes = elements.filter((el) => !el.source);
  const edges = elements.filter((el) => el.source && el.target);

  // 3) Add nodes to the graph
  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  // 4) Add edges
  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  // 5) Perform the layout
  dagre.layout(dagreGraph);

  // 6) Update node positions
  const layoutedNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    return {
      ...node,
      position: {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      },
    };
  });

  return [...layoutedNodes, ...edges];
}

// ============== MAIN COMPONENT ==============
export default function ExecViewFlow({ executionID }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
// State for execution data and the formatted date
const [executionData, setExecutionData] = useState(null);
const [dateRunFormatted, setDateRunFormatted] = useState("");

  // ========== Local states ==========
  const [layoutedElements, setLayoutedElements] = useState([]);
  const [workflowData, setWorkflowData] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState(0); // for Tab switch
  const [sentData, setSentData] = useState(null);
  const [triggerElement, setTriggerElement] = useState(null);
  const [running, setRunning] = useState(false);

  // ========== Redux states/selectors ==========
  const elements = useSelector(selectFlowElements); // from executionSlice
  const workflowID = useSelector(selectWorkflowID);
  const workflow = useSelector(selectWorkflowTemplateNodes); // from workflowSlice
  const notify = useSelector(selectNotification);

  // For showing an alert
  const [openalert, setOpenAlert] = useState(false);
  const handleClickAlert = () => setOpenAlert(true);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") return;
    setOpenAlert(false);
  };

  // ========== On mount, fetch the execution ==========
  // Fetch execution details
  useEffect(() => {
    dispatch(fetchExecution(executionID)).then((actionResult) => {
      if (actionResult && actionResult.payload) {
        setExecutionData(actionResult.payload);
      }
    });
  }, [executionID, dispatch]);

  // When executionData updates, extract and format the date_run field.
  useEffect(() => {
    if (executionData && executionData.date_run) {
      const dt = new Date(executionData.date_run);
      const formatted = dt.toLocaleString("en-US", {
        month: "numeric",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      });
      setDateRunFormatted(formatted);
    }
  }, [executionData]);

  // ========== If we have a workflowID, fetch the workflow ==========
  useEffect(() => {
    if (workflowID) {
      dispatch(fetchWorkflow(workflowID)).then((actionResult) => {
        if (actionResult && actionResult.payload) {
          setWorkflowData(actionResult.payload);

          // If there's a "trigger" node, store it for re-run
          const pollOrWebhook = actionResult.payload.elements.filter(
            (el) => el.type === "trigger"
          );
          if (pollOrWebhook.length > 0) {
            setTriggerElement(pollOrWebhook[0]);
          }
        }
      });
    }
  }, [workflowID, dispatch]);

  // ========== Layout the elements via DAGRE whenever they change ==========
  useEffect(() => {
    if (elements && elements.length > 0) {
      const layouted = getLayoutedElements(elements, "TB");
      setLayoutedElements(layouted);
    }
  }, [elements]); 
  // *** NEW useEffect: Merge icon URLs from workflowData into execution elements (nodes)
  useEffect(() => {
    if (workflowData && workflowData.elements && elements && elements.length) {
      const mergedElements = elements.map((el) => {
        // Only for nodes (no 'source' property)
        if (!el.source) {
          const wfNode = workflowData.elements.find((w) => w.id === el.id);
          if (wfNode && wfNode.data.icon) {
            return { ...el, data: { ...el.data, icon: wfNode.data.icon } };
          }
        }
        return el;
      });
      console.log('mergedElements: ', mergedElements)
      const layouted = getLayoutedElements(mergedElements, "TB");
      setLayoutedElements(layouted);
    }
  }, [elements, workflowData]);
  console.log('elements is: ', elements)
  console.log('workkflowData is: ', workflowData)


  // ========== Show any notifications as a Snackbar ==========
  useEffect(() => {
    if (notify && notify.status) {
      handleClickAlert();
    }
  }, [notify]);

  // ========== Flatten object helper (for mapping test data) ==========
  const flattenObj = (ob) => {
    let result = {};
    for (const i in ob) {
      if (typeof ob[i] === "object" && ob[i] !== null) {
        const temp = flattenObj(ob[i]);
        for (const j in temp) {
          result[i.replace("body.", "") + "." + j] = temp[j];
        }
      } else {
        result[i] = ob[i];
      }
    }
    return result;
  };

  // ========== Build a "sentData" object from the node’s fields ==========
  const handleSentData2 = (fieldsArray) => {
    // Filter out empty or hidden
    const results = fieldsArray.filter(
      (obj) => obj.value !== undefined && obj.value !== "" && obj.hidden !== true
    );

    // Flatten
    const checkrefs = flattenObj(
      results.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.value }), {})
    );

    // Regex to match any references like ${nodes.xyz.outputFields.something}
    const widgetRe = /\${(([^}][^}]?|[^}]}?)*)}/gi;

    // For each property that includes “${…}”
    for (const property in checkrefs) {
      if (checkrefs[property].toString().includes("${")) {
        const matcharray = [...checkrefs[property].match(widgetRe)];
        matcharray.forEach((item) => {
          const splitprop = item.split(".");
          const nodelook = splitprop[1];
          const nodekey = splitprop.slice(-1);
          const clean = nodekey.join(".").replace("}", "");

          // find the node data
          const iso_node = elements.filter((x) => x.id === nodelook);
          const getvalue = iso_node[0]?.data?.testData;

          // recursively search
          const recursiveSearch = (obj, searchKey, results = []) => {
            const r = results;
            if (obj && typeof obj === "object") {
              Object.keys(obj).forEach((key) => {
                const value = obj[key];
                if (key === searchKey && typeof value !== "object") {
                  r.push(value);
                } else if (typeof value === "object") {
                  recursiveSearch(value, searchKey, r);
                }
              });
            }
            return r;
          };
          const replaced_value = recursiveSearch(getvalue, clean)[0];
          const cleanref = checkrefs[property].replaceAll(item, replaced_value);
          checkrefs[property] = cleanref;
        });

        // If property includes “body.” rename it
        if (property.includes("body.")) {
          const new_prop = property.replace("body.", "");
          checkrefs[new_prop] = checkrefs[property];
          delete checkrefs[property];
        }
      }
    }

    setSentData(checkrefs);
  };

  // ========== Whenever we select a node, build “sentData” from its fields ==========
  useEffect(() => {
    if (selectedElement?.data?.fields) {
      handleSentData2(selectedElement.data.fields);
    }
  }, [selectedElement]);

  // ========== Re-run function (executed from the “Replay” button) ==========
  const reRun = async () => {
    setRunning(true);
    const triggerTestData = elements.filter(ele => ele.id === triggerElement?.id)[0].data.testData
    const djangoData = {
      operation: "testNode",
      data: {
        workflow_id: workflowID,
        start_node: triggerElement?.id,
        start_node_data: triggerTestData,
      },
    };

    const config = {
      method: "post",
      url: "https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller",
      headers: {
        "Content-Type": "application/json",
      },
      data: djangoData,
    };

    try {
      await axios(config);
      dispatch(notification({ status: "success", message: "Workflow replay succeeded" }));
    } catch (err) {
      dispatch(notification({ status: "error", message: "Replay failed: " + err }));
    }
    setRunning(false);
  };

  // ========== “Edit” callback (navigates to the editor) ==========
  const user = localStorage.getItem("user");
  const tenant = localStorage.getItem("tenant");
  const handleEditClick = () => {
    if (!workflowID) return;
    axios
      .get(
        "https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/scaffolddjangomap?djangoId=" +
          workflowID
      )
      .then((response) => {
        const scaffoldId = response.data[0].scaffoldId;
        window.location.href =
          "https://editor.workload.co/?u=" + user + "&t=" + tenant + "&id=" + scaffoldId;
      })
      .catch(() => {
        dispatch(notification({ status: "error", message: "Could not find editor link." }));
      });
  };

  // ========== Render the details modal for the selected node/edge ==========
  const renderSelectedElement = () => {
    if (!selectedElement) return null;

    // ACTION node
    if (selectedElement.type === "action") {
      return (
        <Dialog
          classes={{ paper: classes.dialogPaper }}
          open={isModalOpen}
          onBackdropClick={() => {
            setSelectedElement(null);
            setValue(0);
            setIsModalOpen(false);
          }}
          maxWidth={"md"}
          fullWidth
        >
          <>
            <DialogTitle>
              {workflow && workflow[selectedElement.id]?.name}
            </DialogTitle>
            <DialogContent>
              <Tabs
                value={
                  selectedElement.data.type === "polling" ||
                  selectedElement.data.type === "webhook"
                    ? 1
                    : value
                }
                onChange={(e, val) => setValue(val)}
                aria-label="simple tabs example"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab style={{ textTransform: "none" }} label="Input data" {...a11yProps(0)} />
                <Tab style={{ textTransform: "none" }} label="Output data" {...a11yProps(1)} />
              </Tabs>

              <TabPanel
                value={
                  selectedElement.data.type === "polling" ||
                  selectedElement.data.type === "webhook"
                    ? 1
                    : value
                }
                index={0}
              >
                <Box>
                  <Inspector data={sentData} isExpanded={() => true} />
                </Box>
              </TabPanel>

              <TabPanel
                value={
                  selectedElement.data.type === "polling" ||
                  selectedElement.data.type === "webhook"
                    ? 1
                    : value
                }
                index={1}
              >
                <Box>
                  <Inspector
                    data={selectedElement.data.testData}
                    isExpanded={() => true}
                  />
                </Box>
              </TabPanel>
            </DialogContent>
          </>
        </Dialog>
      );
    }

    // FILTER edge
    if (selectedElement.type === "filter") {
      return (
        <Dialog
          open={isModalOpen}
          onBackdropClick={() => {
            setIsModalOpen(false);
            setSelectedElement(null);
            setValue(0);
          }}
        >
          <Box p={3}>
            <Inspector data={selectedElement.data.fields} isExpanded={() => true} />
          </Box>
        </Dialog>
      );
    }

    return null;
  };

  // ========== RENDER ==========

  return (
    <>
      {layoutedElements && layoutedElements.length > 0 ? (
        <div
          //ref={useRef(null)}
          tabIndex="0"
          style={{ width: "100vw", height: "100vh" }}
        >
          <AppBarHeaderExecutions
            style={{ width: "100vw" }}
            workflowName={workflowData ? workflowData.name : "Loading..."}
            workflowTime={dateRunFormatted}
            onReplay={reRun}
            onEdit={handleEditClick}
          />

          <ReactFlowProvider>
            <ReactFlow
              elements={layoutedElements}
              nodeTypes={{
                action: ExecutionNode,
                trigger: ExecutionNode,
              }}
              edgeTypes={{
                filter: FilterEdge,
              }}
              style={flowStyle}
              defaultPosition={[0, 0]}
              nodesDraggable={false}
              onElementClick={(ev, el) => {
                // Mark in Redux that we selected it
                dispatch(workflowElementSelected(el.id));
                dispatch(executionElementSelected(el.id));

                // Locally track it so we can show a modal
                setSelectedElement(null);
                setSelectedElement(
                  layoutedElements.find((item) => item.id === el.id)
                );
                setIsModalOpen(true);
              }}
              onPaneClick={() => {
                setSelectedElement(null);
                setIsModalOpen(false);
              }}
              onLoad={(instance) => instance.fitView({ padding: 0.2 })}
            >
              {renderSelectedElement()}
              <Background variant="dots" gap={16} size={1} color="#d7d5d2" />
            </ReactFlow>

            {/* Notification Snackbar */}
            {notify && notify.status ? (
              <Snackbar
                open={openalert}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <Alert
                  onClose={handleCloseAlert}
                  severity={notify.status.status}
                  variant="filled"
                >
                  <Typography style={{ fontSize: "16px" }}>
                    {notify.status.message}
                  </Typography>
                </Alert>
              </Snackbar>
            ) : null}
          </ReactFlowProvider>
        </div>
      ) : (
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <CircularProgress />
        </div>
      )}
    </>
  );
}
