import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import Joyride from 'react-joyride'; // Removed unnecessary destructured imports
import mixpanel from 'mixpanel-browser';
import { hotjar } from 'react-hotjar';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar,SvgIcon, CircularProgress, Typography, Fab, Card, Snackbar, LinearProgress, Box, Drawer, TextField, Button, Modal, Container, Paper, Grid, IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import { Visibility, VisibilityOff, CheckCircleRounded, SentimentVerySatisfied, SettingsVoiceRounded } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import MobileStepper from '@material-ui/core/MobileStepper';

// React Flow Renderer and Dagre for layout
import ReactFlow, {
    ReactFlowProvider,
    useStore,
    removeElements,
    addEdge,
    Background,
    Controls,
    MiniMap,
    isNode,
    applyNodeChanges,
    applyEdgeChanges,
    useZoomPanHelper 
} from 'react-flow-renderer'; // Ensured correct imports for React Flow Renderer
import dagre from 'dagre';
import { v4 as uuid } from 'uuid'; // Kept uuid import for node ID generation

// Custom Components and Nodes
import { AppBarHeader } from './components/AppBar';
import { AppBarHeaderDummy } from './components/AppBarDummy';
import ActionPanel from './components/NodePanels/ActionPanel';
import TriggerPanel from './components/NodePanels/TriggerPanel';
import FilterPanel from './components/EdgePanels/FilterPanel';
import CreateTriggerPanel from './components/EdgePanels/CreateTriggerPanel';
import CreateSupportTicketPanel from './components/EdgePanels/CreateTicketPanel';
import ActionNode from './components/CustomNodes/ActionNode';
import TriggerNode from './components/CustomNodes/TriggerNode';
import FilterEdge from './components/CustomEdges/FilterEdge';
import ConnectionLine from './components/ConnectionLine';

// Redux Slice Actions
import {
    nodeAdded,
    edgeAdded,
    elementUpdated,
    selectNotification,
    notification,
    nameUpdated,
    elementDeleted,
    fetchWorkflow,
    nodeEdgeAdded,
    saveWorkflow,
    elementSelected,
    selectWorkflowElements,
    selectElementById,
    selectWorkflowName,
    selectWorkflowId,
    selectParentTree,
    selectWorkflowTemplateNodes,
    selectWorkflowActive,
    selectCurrentElement,
    createWorkflow,
    createTemplate0,
    createTemplate5,
    createTemplate1,
    createTemplate2,
    createTemplate4,
    acceptTemplateNew,
    acceptTemplateExist
} from './features/workflow/workflowSlice';

import { selectWorkflowID } from './features/execution/executionSlice';

const modalheight = window.innerHeight;
const modalwidth = window.innerWidth;

function getModalStyle() {
    const top = 5
    //const left = 50 + rand();

    return {
        top: '${top}%',
        margin: 'auto'
      //  left: ${left}%,
      //  transform: translate(-${top}%, -${left}%),
    };
}

let breakpointZoom = ''

if (modalwidth < 450) {
    breakpointZoom = 0
} else if (modalwidth < 720 && modalwidth > 449) {
    breakpointZoom = 1
} else if (modalwidth > 721) {
    breakpointZoom = 1
}



let breakpointPosition = ''

if (modalwidth < 450) {

    var breakpointPositionWidth = modalwidth / 2 - 225
    breakpointPosition = [breakpointPositionWidth, 20]

} else if (modalwidth < 720 && modalwidth > 390) {

    var breakpointPositionWidth = modalwidth / 2 - 425
    breakpointPosition = [breakpointPositionWidth, 20]

} else if (modalwidth > 721) {

    var breakpointPositionWidth = modalwidth / 2 - 625
    breakpointPosition = [breakpointPositionWidth, 20]

}



const useStyles = makeStyles((theme) => ({
    fab: {
        '& > *': {
            margin: theme.spacing(1),

        },
        position: 'fixed',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        zIndex: 9
    },
    modal: {
        position: 'absolute',
        width: '350px',
        //height: modalheight * .85,
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #000',
        borderRadius: '4px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflowY: 'scroll'
    },

    modalSmall: {
        position: 'absolute',
        width: '66%',
       
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #000',
        borderRadius: '4px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflowY: 'scroll'
    },
    modaltour: {
        position: 'absolute',
        width: '50%',
        height: modalheight * .75,
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #000',
        borderRadius: '4px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflowY: 'scroll'
    },
    modalverify: {
        position: 'absolute',
        width: '50%',
        height: '40%',
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #000',
        borderRadius: '4px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflowY: 'scroll'
    },
    drawerAnimate: {

        animationName: '$fade',
    },
    '@keyframes fade': {
        '0%': { opacity: '0' },
        '100%': { opacity: '1' },
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        maxWidth: 5000,
        backgroundColor: '#f5f5f5',
        
      },
      paper: {
        padding: theme.spacing(4),
        maxWidth: 1000,
        width: '100%',
        marginTop: '50px'
      },
      form: {
        marginTop: theme.spacing(2),
      },
      button: {
       // backgroundColor: '#0069ea',
        color: 'white',
        textTransform: 'none',
        marginTop: theme.spacing(2),
        borderRadius: 8
       // '&:hover': {
        //  backgroundColor: '#0075ea',
       // },
      },
      googleButton: {
        width: '100%',
        marginBottom: theme.spacing(2),
        backgroundColor: 'rgb(66, 133, 244)'
      },
      leftContent: {
        paddingRight: theme.spacing(4),
        borderRight: '1px solid ${theme.palette.divider}',
      },
      rightContent: {
        paddingLeft: theme.spacing(4),
      },
      iconContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: theme.spacing(2),
      },
      icon: {
        marginRight: theme.spacing(2),
        height: 40,
        width: 40,
      },
      benefits: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
      },
      checkIcon: {
        color: 'green',
        marginRight: theme.spacing(1),
      },
      trustedSection: {
        marginTop: theme.spacing(4),
        textAlign: 'center',
      },
      companyLogos: {
        marginTop: theme.spacing(2),
      },
      logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center', // Added to center logos
        marginBottom: theme.spacing(2),
      },
      logo: {
        maxWidth: 150,
        height: 'auto',
        margin: theme.spacing(2),
      },
      progressBar: {
        height: 10,
        '& .MuiLinearProgress-bar': {
          backgroundColor: (props) => props.color, // Dynamic color
        },
      },
}));


const GoogleLogo = () => (
  <SvgIcon style={{ width: '20px', height: '20px' }} viewBox="0 0 24 24">
   <svg xmlns="http://www.w3.org/2000/svg" width="0.98em" height="1em" viewBox="0 0 256 262"><path fill="#4285f4" d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622l38.755 30.023l2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"/><path fill="#34a853" d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055c-34.523 0-63.824-22.773-74.269-54.25l-1.531.13l-40.298 31.187l-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"/><path fill="#fbbc05" d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82c0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602z"/><path fill="#eb4335" d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0C79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"/></svg>
  </SvgIcon>
);


const nodeTypes = {
    action: ActionNode,
    trigger: TriggerNode
};

const edgeTypes = {
    filter: FilterEdge
}

export default ({ workflowID = null }) => {
    // const [workflow, setWorkflow] = useState(null)
    // const [elements, setElements] = useState([{ id: uuid(), type: 'trigger', position: { x: 200, y: 200 }, data: { name: 'Click to Add Trigger' } }])
    const selectedElementID = useSelector(selectCurrentElement)
    const selectedElement = useSelector(selectElementById(selectedElementID))
    // const [parentTree, setParentTree] = useState({})

    const [openalert, setOpenAlert] = React.useState(false);
    //console.log(workflow)
    //console.log(elements)
    //console.log(convertToBackendNodes(elements))
    const [source, setSource] = useState(null);
    const sourceRef = useRef();
    const [target, setTarget] = useState(null);
    const targetRef = useRef();
    const [stepIndex, setStepIndex] = useState(0)
    const dispatch = useDispatch();
    const name = useSelector(selectWorkflowName)
    const elements = useSelector(selectWorkflowElements)
    const workflow = useSelector(selectWorkflowTemplateNodes)
    const active = useSelector(selectWorkflowActive)
    
    const [modalStyle] = React.useState(getModalStyle);
    const [modalopen, setModalOpen] = useState(false);
    const [modalopentour, setModalOpenTour] = useState(false)
    const [modalopencodeverify, setModalOpenCodeVerify] = useState(false)
    const notify = useSelector(selectNotification)
   
  
   
  /* useEffect(() => {
   var tourReady = localStorage.getItem('tourReady')
   var template = localStorage.getItem('template')
   var visitCount = localStorage.getItem('visitCount')

   if (modalwidth > 1136 && visitCount < '4' && template === '5' && tourReady !== 'false') {
    //toggle this to turn the tour on or off
    setModalOpenTour(false)
   } else if (tourReady === 'false') {
       setModalOpenTour(false)
       //console.log('tourReady is: ', tourReady)
   }
  }, []) */


   const [openstep, setOpenStep] = useState(true)
    useEffect(() => {
    if (openstep === true && elements.length > 0 && workflow) {
 
        const workflowobj = Object.entries(workflow)
      //  console.log('useEffect Elements are, ', workflowobj.slice(-1))
      //  console.log('Elements length are, ', elements.length)
      //  console.log('Workflow length are, ', workflow)
      const filtered = workflowobj.filter(step => {
        const stepobj = step[1].outputFields
        const isEmpty = Object.keys(stepobj).length === 0;
        
        return isEmpty === true;
      });
      // 👇️ [{id: 1, name: 'Alice', country: 'Canada'},
     
     // console.log('filtered: ',filtered);
      if (filtered.length === 0){
        setOpenStep(false)
      } else {
        const x = filtered[0]
        const f = x[1].id
       // console.log('element x is: ', x)
    //setSelectedElement(elements.find(item => item.id == f))
        dispatch(elementSelected(f))
        setOpenStep(false)
       
      }
    }

}, [openstep])  
 
  const [width, setWindowWidth] = useState(0)
  const [breakpoint, setBreakPoint] = useState('')
 
      
 
   useEffect(() => { 

     updateDimensions();

     window.addEventListener('resize', updateDimensions);
     return () => 
       window.removeEventListener('resize',updateDimensions);
    }, [])

    const updateDimensions = () => {
      const width = window.innerWidth
      setWindowWidth(width)
    }
  
    useEffect(() => {
        if (width > 721) {
          setBreakPoint('large')
          }
          else if (width < 720 && width > 431) {
           
              setBreakPoint('medium')
          }
          else if (width < 431) {
              setBreakPoint('small')
          }
    }, [width])
    

    const handleModalOpen = () => {
        setModalOpen(true);
       // hotjar.event('signup-modal-open');
        mixpanel.track('Signup Modal Open', {

        });
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleModalCloseTour = () => {
        setModalOpenTour(false);
        localStorage.setItem('tourReady', false)
    };

    const handleModalCloseCodeVerify = () => {
        setModalOpenCodeVerify(false);
        localStorage.setItem('codeverify', false)
    };

    const handleDesktopModalClose = () => {
        setDesktopReminderModal(false);
    };


    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();
    let id = query.get("id")
    if (id === null) {
        id = uuid();
     
    }
    const [user, setUser] = useState(query.get("u"));
    const [tenant, setTenant] = useState(query.get("t"))
    const template = query.get("template")
    const template_id = query.get("template_id")
    const app1 = query.get("app1")
    const action1 = query.get("action1")
    const app2 = query.get("app2")
    const action2 = query.get("action2")
    const cook = localStorage.getItem('tok_')
    if (user) {
      localStorage.setItem('user', user)
    }
    if (tenant) {
      localStorage.setItem('tenant', tenant)
    }

    useEffect(() => {
      if (!user && !template_id) {
        sendLoggedOutToAuthorizer()
      }
    }, [user])
    

    const handleClickAlert = () => {
        setOpenAlert(true);
    };
    useEffect(() => {
        if (notify && notify.status) {
           // console.log('alert is: ', notify.status.message)
            handleClickAlert();
        }
    }, [notify])

    useEffect(() => {
        sourceRef.current = source
    }, [source])

    useEffect(() => {
        
        targetRef.current = target
       // console.log('targtref now: ', targetRef.current)
    }, [target])

    

    useEffect(() => {
        if (modalwidth > 719){
            
        const script = document.createElement('script');

        script.src = "https://js.hs-scripts.com/6870309.js";
        script.async = true;
        script.id = "hs-script-loader";

        document.body.appendChild(script);
        window.hsConversationsSettings = {
            identificationEmail: localStorage.getItem('email'),
            
         };

        return () => {
            document.body.removeChild(script);
        }
        
    }}, []);
    const [accessDenied, setAccessDenied] = useState(false)
    const [workflowloading, setWorkflowLoading] = useState(false)
    useEffect(() => {
      //get the workflow from the server
      
      if (workflowID) {
          if (user && tenant && id && !cook) { 
             //commenting out for now....
             setWorkflowLoading(false); 
             // setAccessDenied(true) 
           // console.log('fix this...')
          }
         else {
          setWorkflowLoading(false)
          dispatch(fetchWorkflow(workflowID))
      
         }
          
      }
     /*  else if (user && tenant && id) {
          console.log('checking if workflow belongs to tenant: ', tenant)
          console.log('checking on workflow id: ', id)
          setWorkflowLoading(false)
          dispatch(fetchWorkflow(workflowID))
          if (!cook) {
              setWorkflowLoading(false)
               setAccessDenied(true)
          }
         
         

      } */
      else if (template_id && cook) {
         // console.log('user has a cookie and is using a template')
         // console.log('template id is: ', template_id)
          mixpanel.track('Share Accepted', {
              'url': 'https://editor.workload.co/?template_id='+template_id,
              'user': user
          });
          setWorkflowLoading(true)
          dispatch(acceptTemplateExist({ template_id: template_id, token: cook }))
      }
      else if (template_id && !cook) {
         // console.log('user is not signed up and is using a template')
         // console.log('template id is: ', template_id)
         // console.log('uuid is: ', id)
          mixpanel.track('Share Accepted', {
              'url': 'https://editor.workload.co/?template_id='+template_id,
              'user': user
          });
          setWorkflowLoading(true)
          dispatch(acceptTemplateNew({ template_id: template_id, scaffoldId: id}))
         
         
      }
      else if (template == 0) {
        //  console.log('load template 0')
        //  console.log('id is going to be: ', id)
        setWorkflowLoading(true)
          dispatch(createTemplate0({ id: id, app: app1,cook: cook ? true : false, token: cook ? cook : null }))
          
         
      } else if (template == 1) {
       //   console.log('load template 1')
        //  console.log('id is going to be: ', id)
        setWorkflowLoading(true)
          dispatch(createTemplate1({ id: id, app: app1, action: action1, cook: cook ? true : false, token: cook ? cook : null }))
          
         
      }
      else if (template == 2) {
        //  console.log('load template 2')
         // console.log('id is going to be: ', id)
        //  console.log(app1 + " and " + app2)
        setWorkflowLoading(true)
          dispatch(createTemplate2({ id: id, app1: app1, app2: app2, cook: cook ? true : false, token: cook ? cook : null }))
        
          
      }
      else if (template == 4) {
       //  console.log('load template 4')
         //   console.log('id is going to be: ', id)
            setWorkflowLoading(true)
             dispatch(createTemplate4({ id: id, app1: app1, action1: action1, app2: app2, action2: action2, cook: cook ? true : false, token: cook ? cook : null }))
             
           
         }
      else if (template == 5) {
        //  console.log('load template 5')
          localStorage.setItem('template',5)
         // console.log('id is going to be: ', id)
         setWorkflowLoading(true)
          dispatch(createTemplate5({ id: id, cook: cook ? true : false, token: cook ? cook : null}))
        
      }
      else {
          
              localStorage.setItem('template',5)
              setWorkflowLoading(false)
              mixpanel.identify(user) 
              dispatch(createWorkflow(id))
             
          //console.log('creating createWorklfow for some reason')
          
         
      }
  }, [])

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };

    const updateNode = node => {
        dispatch(elementUpdated(node.id, null, node.position))
    }
   
    const handleElementClick = (event, el) => {
     
        // console.log('element ' + JSON.stringify(el) + ' clicked')
        
        // setSelectedElement(null)
        // setSelectedElement(elements.find(item => item.id == el.id))
      /*   if (el.type === 'trigger' && stepIndex === 0){
            setStepIndex(stepIndex + 1)
        }

        if (el.type === 'action' && stepIndex === 5){
            setStepIndex(stepIndex + 1)
        } */

       // console.log('HANDLE ELEMENT CLICKED...: ', event.target.outerHTML)
        if (event.target.outerHTML.startsWith("<div data-handleid")) {
          return
        }

        if (event.target.outerHTML.startsWith('<span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">Delete Step')){
          if (reactFlowInstance) {
            reactFlowInstance.fitView({ padding: 0.1 }); // Layout nodes on initial load
           }
           return
        }
        if (event.target.outerHTML.startsWith('<span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">Duplicate')){
          if (reactFlowInstance) {
            reactFlowInstance.fitView({ padding: 0.1 }); // Layout nodes on initial load
           }
           return
        }

        if (el.type === 'trigger') {
          handlePaneClick()
          dispatch(elementSelected(el.id))
        } else {
         // handlePaneClick()
        dispatch(elementSelected(el.id))
        }

       // hotjar.event('node-click');
        mixpanel.track('Node Selected', {
            'app': el.app,
            'user': user
        });
    
    }
  
    const [createTrigger, setCreateTrigger] = useState(false)
    const [createTriggerApp, setCreateTriggerApp] = useState('')

    const [createSupportTicket, setCreateSupportTicket] = useState(false)
    const [createSupportTicketApp, setCreateSupportTicketApp] = useState('')

    const handlePaneClick = (event) => {
    //reactFlowInstance.fitView({padding: .2})
    console.log('handlePaneClick')
        dispatch(saveWorkflow(1))
        // setSelectedElement(null);
        dispatch(elementSelected(null))
        setCreateTrigger(false)
        setCreateSupportTicket(false)
        // dispatch(deletefilterclick(filterC))
        if (reactFlowInstance) {
          reactFlowInstance.fitView({ padding: 0.1 }); // Layout nodes on initial load
         }

    }
    

    
    const handleCreateTrigger = (event) => {
      //  console.log('event is: ', event)
        handlePaneClick();
        setCreateTrigger(true)
        setCreateTriggerApp(event)
    }

    const handleCreateSupportTicket = (event) => {
       // console.log('event is: ', event)
        handlePaneClick();
        setCreateSupportTicket(true)
        setCreateSupportTicketApp(event)
    }

    const handleNodeMove = (event, node) => {
        updateNode(node)
        console.log('handleNodeMoved')
        handleSaveClick();
    }

    const handleonConnectStart = (event, { nodeId, handleType }) => {
        setTarget(null)
        //  console.log('handle connect start event', event)
        // console.log('handle connect start nodeId', nodeId)
        setSource(nodeId);
        // console.log('handle connect start handleType', handleType)
        handleSaveClick();
    }
const [reactFlowInstance, setReactFlowInstance] = useState()




     
    
    
    // const {project} = useZoomPanHelper();
    //console.log('reactFlowInstance is, ', reactFlowInstance)
    //const firstelement = reactFlowInstance?.getElements({})
    //console.log('firstelement is, ', firstelement)
    //const fstelmnt = Object.assign({}, firstelement)
    //console.log('fstelmt is, ', fstelmnt[0])
    //const f = fstelmnt[0]
    //console.log('f is, ',f)
    
    //console.log('zoom instance: ', reactFlowInstance)
  
    
    /* const handleLineDrop_old = (event) => {

          console.log('handleLineDrop event is ', event)
        //   console.log('event is LINE DROP ')
        // const rfBounds = rfWrapper.current.getBoundingClientRect();
        const position = reactFlowInstance.project({
            x: event.clientX - 225,
            y: event.clientY + 30
        })
        // var x = event.layerX - 10;
        // var y = event.layerY - 55;
        var x = position.x;
        var y = position.y;
        // console.log('x equals ', x)
        // console.log('y equals ', y)
        // console.log('source' + sourceRef.current)
        // addNode(x, y);
        
        if (event.toElement.dataset.nodeid !== sourceRef.current && event.toElement.dataset.nodeid !== undefined ) {
            console.log('target exists: ' + event.toElement.dataset.nodeid)
            
            dispatch(edgeAdded('filter', sourceRef.current, event.toElement.dataset.nodeid ))
            setTarget(null)
            handleSaveClick();
        }
        else {
            console.log('source exists: ', sourceRef.current)
           
           if (workflow[sourceRef.current] && workflow[sourceRef.current].type === 'polling') {
          console.log('target is now: ', target)
            dispatch(nodeEdgeAdded('action', x+450, y, 'filter', sourceRef.current))
            setSource(null)
            dispatch(notification({"status": "info", "message": "Action step added"}))
            handleSaveClick();
         
            setFirstLoad(true)
           } else if (workflow[sourceRef.current] && workflow[sourceRef.current].type === 'webhook') {
            dispatch(nodeEdgeAdded('action', x+450, y, 'filter', sourceRef.current))
            setSource(null)
            dispatch(notification({"status": "info", "message": "Action step added"}))
            handleSaveClick();
           
            setFirstLoad(true)
           } else if (workflow[sourceRef.current] && Object.keys(workflow[sourceRef.current]['connections']).length !== 0) {
            console.log('it is this one!!: ', workflow[sourceRef.current] )
            dispatch(nodeEdgeAdded('action', x+450, y, 'filter', sourceRef.current))
            handlePaneClick();
            setSource(sourceRef.current)
            dispatch(notification({"status": "info", "message": "Action step added"}))
            handleSaveClick();
            handleOpenElement(workflow[sourceRef.current])
            setFirstLoad(true)
           } else if (workflow[sourceRef.current] && Object.keys(workflow[sourceRef.current]['connections']).length === 0) {
            dispatch(nodeEdgeAdded('action', x, y, 'filter', sourceRef.current))
            setSource(null)
            handleSaveClick();
            
            dispatch(notification({"status": "info", "message": "Action step added"}))
            setFirstLoad(true)
          
           }
           else {
            
            dispatch(nodeEdgeAdded('action', x, y, 'filter', sourceRef.current))
            setSource(null)
            handleSaveClick();
            dispatch(notification({"status": "info", "message": "Action step added"}))
            setFirstLoad(true)
          
           }
        }

    }
 */
    const handleLineDrop = (event) => {
      const position = reactFlowInstance.project({
        x: event.clientX - 225,
        y: event.clientY + 30
      });
    
      const x = position.x;
      const y = position.y;
    
      if (event.toElement.dataset.nodeid !== sourceRef.current && event.toElement.dataset.nodeid !== undefined) {
       // console.log('target exists: ' + event.toElement.dataset.nodeid);
        dispatch(edgeAdded('filter', sourceRef.current, event.toElement.dataset.nodeid));
        setTarget(null);
        console.log('handleLineDrop')
        handleSaveClick();
      } else {
        let newNodeId = uuid(); // Create a unique ID for the new node
       // console.log('node isl...... : ',workflow[sourceRef.current])
        // Handle case when adding an action node from the trigger
        if (workflow[sourceRef.current] && workflow[sourceRef.current].type === 'polling') {
          dispatch(nodeEdgeAdded('action', x, y, 'filter', sourceRef.current, newNodeId));
        } else if (workflow[sourceRef.current] && workflow[sourceRef.current].type === 'webhook') {
          dispatch(nodeEdgeAdded('action', x + 450, y, 'filter', sourceRef.current, newNodeId));
        } else {
          dispatch(nodeEdgeAdded('action', x, y, 'filter', sourceRef.current, newNodeId));
        }
        handlePaneClick()
        console.log('handleLineDrop')

        handleSaveClick();
        // Select the newly added node and update the side panel
        dispatch(elementSelected(newNodeId));
        setFirstLoad(true);
       // console.log('new node is is: ', newNodeId)
        
      }
   
    };
    


    const handleSaveClick = () => {
       // dispatch(notification({"status": "success", "message": "Your changes were auto-saved..."}))
          console.log('save clicked')
        dispatch(saveWorkflow(1))
       // hotjar.event('save-click');

    }

    const flowStyle = { background: '#f2f2f2', height: '100vh', width: '100vw', zIndex: 0,  cursor: 'grab' }

    const onElementsRemove = (elementsToRemove) => {

        //  console.log('delete ', elementsToRemove);
        // setElements(elements => removeElements([elementsToRemove], elements))
         handlePaneClick();
layoutNodes()
        if (reactFlowInstance) {
          reactFlowInstance.fitView({ padding: 0.1 }); // Layout nodes on initial load
         }
       // hotjar.event('remove-click');
    }



    const onConnect = (params) => {
      //  console.log('onConnect params are: ', params)
        setTarget(params.target)
     //   dispatch(edgeAdded('filter', params.source, params.target))
        // setElements(els => addEdge({ ...params, animated: true, type: 'filter', style: { stroke: 'gray', strokeWidth: 2 }, data: { text: 'empty', fields: baseFields } }, els))
    }

    const handleKeyDownSave = event => {
        // console.log('console log event ', event)

        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === 's') {
            event.preventDefault();
            handleSaveClick();
            dispatch(notification({ "status": "success", "message": name + " was auto-saved" }))
        } else {
            return;
        }
    }

    const [firstload, setFirstLoad] = useState(false)

   

    useEffect(() => {
     // console.log("Elements changed:", elements);
      if (firstload === true && elements.length > 0 && workflow) {
        const workflowobj = Object.entries(workflow);
        const lastElement = workflowobj.slice(-1);
        const newNodeId = lastElement[0][1]?.id;
    
        if (newNodeId) {
        //  console.log("New node to select:", newNodeId);
          dispatch(elementSelected(newNodeId));
          setOpenStep(true)
        }
        setFirstLoad(false);
      }
    }, [firstload]);
    
const [triggerData, setTriggerData] = useState(null)
const [actionData, setActionData] = useState(null)
     useEffect(() => {
        if (triggerData == null) {
        //console.log('workflow elements are:: ', elements.filter(element => element.type !== 'filter'))
    setTriggerData(elements.filter(element => element.type !== 'filter')[0])
    setActionData(elements.filter(element => element.type !== 'filter')[1])
        }
    }, [elements])
 


    const [run, setTourStart] = useState(false)
    

    const [desktopreminderloading, setDesktopReminderLoading] = useState(false)
    const [desktopReminderModal, setDesktopReminderModal] = useState(false)
    const [deskworkemail, setDeskWorkemail] = useState();
    const [validworkemail, setValidworkemail] = useState(true)
    const handleDeskWorkemailChange = (e) => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(e.match(mailformat))
            {
              //.log('matches email address format')
            setValidworkemail(true);
            //handleDesktopReminder();

            }
            else
            {
             //   console.log('does not match email format :(')
            setValidworkemail(false)
            }
        }

    const handleDesktopReminder = () => {
        setDesktopReminderLoading(true)
        let data =  {
            email: deskworkemail,
         }

        let config = {
        method: 'post',
        url: 'https://editor.workload.co:5000/addnewcontact',
        headers: {  
            'Content-Type': 'application/json'
        },
        data : data
        };

        axios(config)
        .then((response) => {
         //   console.log('added to onboarding list')
       // console.log(JSON.stringify(response.data));
        const data2 = JSON.stringify(
            {
                email: deskworkemail,
                href: window.location.href
             }
        )
        let config_send = {
            method: 'post',
            url: 'https://editor.workload.co:5000/sendreminder',
            headers: { 
                
                'Content-Type': 'application/json'
            },
            data : data2
            };
    
            axios(config_send)
            .then((response) => {
          //  console.log(JSON.stringify(response.data));
          //  console.log('sent template email')
        setDesktopReminderLoading(false)
        setDesktopReminderModal(false)
    })
})
    .catch((error) => {
    console.log(error);
    });

    }

    const [pw, setPw] = useState();
    const [pwvalid, setPwvalid] = useState(true);
    const [pwvalidmessage, setPwValidMessage] = useState([])
    const [progress, setProgress] = useState(20); // For progress bar
  const [progressColor, setProgressColor] = useState('red'); // Default color of the progress bar
  const [progressHuman, setProgressHuman] = useState('Weak'); // Default state of the password 

  const handlePwProgress = (password) => {
    const messages = [];

    let progressValue = 20;

   // Rule 1: Password length between 12 and 512 characters
   if (password.length >= 12 && password.length <= 512) {
    progressValue += 20; // Increment for passing length rule
  } else {
    messages.push('Password must be between 12 and 512 characters.');
  }

  // Rule 2: Password must contain at least one alpha character (only if length is >= 1)
  if (password.length >= 12 && /[a-zA-Z]/.test(password)) {
    progressValue += 30; // Increment for passing alpha rule
  } else if (password.length >= 1) {
    messages.push('Password must contain at least one alphabetical character.');
  }

  // Rule 3: Passwords shorter than 20 characters must not have 3 or more repeating characters (only if length >= 2)
  if (password.length >= 12 && password.length < 20) {
    if (/(.)\1{2,}/.test(password)) {
      messages.push('Passwords shorter than 20 characters must not have 3 or more consecutive repeating characters.');
    } else {
      progressValue += 30; // Increment for passing repetition rule
    }
  } else if (password.length >= 20 && messages.length === 0) {
    progressValue += 30; // Passwords longer than 20 characters pass by default
  }

   // console.log('messages: ', messages);
    setProgress(progressValue);

    // Change color based on the progress
    if (progressValue < 35) {
      setProgressColor('red');
      setProgressHuman('Weak')
    } else if (progressValue < 91) {
      setProgressColor('orange');
      setProgressHuman("Moderate")
    } else {
      setProgressColor('green');
      setProgressHuman('Strong')
    }
  };
    const handlePwValid = () => {
      // Define the password rules
      
      const lengthValid = pw.length >= 12 && pw.length <= 512;
      const containsAlpha = /[a-zA-Z]/.test(pw);
      const noRepeatingChars = pw.length >= 20 || !/(.)\1{2,}/.test(pw);
     // console.log('no repeating chars is : ', noRepeatingChars)
      const messages = [];  // Array to hold the validation failure messages
      // Check if all rules are satisfied
      if (lengthValid && containsAlpha && noRepeatingChars) {
          setPwvalid(true);
           handleModalSignup(); // Proceed with form submission if needed
      } else {
        if (pw.length === 0) {
          messages.push("Password can't be blank.")
        }
        if (lengthValid === false) {
          messages.push("Password must be between 12 and 512 characters.")
        }
        if (containsAlpha === false) {
          messages.push("Password must contain at least one Alphabet character.")
        }
        if (noRepeatingChars === false) {
          messages.push("Password can't have more than 3 repeating characters.")
        }
        setPwValidMessage(messages)
          setPwvalid(false);
      }
  };
const [signuperror, setSignupError] = useState()
    const handleModalSignup = () => {
        if (pwvalid === false) {
            return;
        }
        console.log('handlemodalsignup')

        dispatch(saveWorkflow(1))
        
        setSubmitted(true);
       // console.log('w is: ', workemail);
       // console.log('p is: ', pw);
       // hotjar.event('signup-modal-submit');
        mixpanel.track('Modal Signup Submit', {
            'user': workemail
        });
        window.gtag('config', 'AW-11220054099');
        window.gtag('event', 'conversion', {'send_to': 'AW-11220054099/E1nbCOnS5rMYENPgkeYp'});

        var signup = {
            method: 'post',
            url: 'https://backend.workload.co/api/auth/sign-up',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                email: workemail,
                password: pw,
                invitationToken: null,
                emailVerified: true
            }
        }

        axios(signup)
            .then(function (response) {
               // console.log('api token ', response.data);

                const newtoken = response.data;
                localStorage.setItem("tok_", newtoken)
                var me = {
                    method: 'get',
                    url: 'https://backend.workload.co/api/auth/me',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + newtoken
                    }
                }

                axios(me)
                    .then(function (res) {
                     //   console.log('me data ', res.data);
                     //   console.log('user id', res.data.id)
                        const newid = res.data.id
                        mixpanel.identify(newid);

                        mixpanel.people.set({ "email": workemail });
                        localStorage.setItem('user', newid)
                        localStorage.setItem('email', workemail)
                        var workspace = {
                            method: 'post',
                            url: 'https://backend.workload.co/api/tenant',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + newtoken
                            },
                            data: {
                                data: {
                                    name: "My First Workspace"
                                }
                            }
                        }

                        axios(workspace)
                            .then(function (res2) {
                                const newtenant = res2.data.id
                             //   console.log('workspace data ', res2.data);
                                var raw = JSON.stringify({ "data": { "name": "Untitled Workflow (Draft)", "active": false } });
                                var createscaffoldwf = {
                                    method: 'post',
                                    url: 'https://backend.workload.co/api/tenant/' + newtenant + '/workflow',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + newtoken
                                    },
                                    data: raw
                                }

                                axios(createscaffoldwf)
                                    .then(function (res3) {
                                     //   console.log('workspace data ', res3.data);
                                        const newworkflowid = res3.data.id

                                        // window.location.replace("https://editor.workload.co/?u="+newid+"&t="+res2.data.id+"&id="+id+"&c="+newtoken)
                                        var mapdata = {
                                            method: 'post',
                                            url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/scaffolddjangomap',
                                            headers: {
                                                'Content-Type': 'application/json',

                                            },
                                            data: {
                                                scaffoldId: newworkflowid,
                                                djangoId: workflowID,
                                                tenant: newtenant
                                            }
                                        }

                                        axios(mapdata)
                                            .then(function (res4) {
                                                //console.log('djangomap data ',res4.data);
                                                let data =  {
                                                    email: workemail,
                                                 }
                                        
                                                let config = {
                                                method: 'post',
                                                url: 'https://editor.workload.co:5000/addnewcontact',
                                                headers: {  
                                                    'Content-Type': 'application/json'
                                                },
                                                data : data
                                                };

                                                axios(config)
                                                .then((response) => {
                                             //   console.log(JSON.stringify(response.data));
                                                window.history.pushState("","","https://editor.workload.co/?u=" + newid + "&t=" + newtenant + "&id=" + newworkflowid + "&c=" + newtoken)
                                                handleModalClose()
                                                setUser(newid)
                                                setTenant(newtenant)
                                                const verify_code = ("" + Math.random()).substring(2, 6)
                                                localStorage.setItem('vc',verify_code)
                                                localStorage.setItem('email',workemail)
                                                

                                                setModalOpenCodeVerify(true)
                                                }).then((response) => {
                                                    const data_code = JSON.stringify(
                                                        {
                                                            email: workemail,
                                                            code: localStorage.getItem('vc')
                                                         }
                                                    )
                                                    let config_send = {
                                                        method: 'post',
                                                        url: 'https://editor.workload.co:5000/sendcode',
                                                        headers: { 
                                                            
                                                            'Content-Type': 'application/json'
                                                        },
                                                        data : data_code
                                                        };
                                                
                                                        axios(config_send)
                                                        .then((response) => {
                                                     //  console.log(JSON.stringify(response.data));
                                                })
                                                })
                                                .catch((error) => {
                                                console.log(error);
                                                
                                                });
                                               
                                            })
                                    })
                            })
                    })
            })
            .catch(function (error) {
                console.log('there was an error', error.response.data)
                setSignupError(error.response.data)
                setSubmitted(false)
                console.log(error);
            });
    }
   
    const [workemail, setWorkemail] = useState();
    const [validemail, setValidemail] = useState(true)
    const [screenOne, setScreenOne] = useState(true)
    const handleEmailChange = (e) => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if( e !== undefined ?? e !== null ?? e.match(mailformat))
            {
              //.log('matches email address format')
            setValidemail(true);
          //  handleModalSignup();
          setScreenOne(false)

            }
            else
            {
             //   console.log('does not match email format :(')
            setValidemail(false)
            }
        }
        const [validcode, setValidcode] = useState()
    const handleEvaluateCodeVerify = () => {
        var code = localStorage.getItem('vc')
        var basic1 =  document.getElementById('outlined-basic1').value
        var basic2 =  document.getElementById('outlined-basic2').value
        var basic3 =  document.getElementById('outlined-basic3').value
        var basic4 =  document.getElementById('outlined-basic4').value
        var input_code = basic1 + basic2 + basic3 + basic4
      
        if (code === input_code) {
            
            handleModalCloseCodeVerify()
        }
        else  {
           
            document.getElementById('outlined-basic1').value = ''
            document.getElementById('outlined-basic2').value = ''
            document.getElementById('outlined-basic3').value = ''
            document.getElementById('outlined-basic4').value = ''
            document.getElementById('outlined-basic1').focus()

        }
    }
    const handleKeyUp1 = () => {
         document.getElementById('outlined-basic2').focus()
    }
    const handleKeyUp2 = () => {
        document.getElementById('outlined-basic3').focus()
    }
    const handleKeyUp3 = () => {
        document.getElementById('outlined-basic4').focus()
    }
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );
    const handleKeyUp4 = async () => {
        var code = localStorage.getItem('vc')
        var basic1 =  document.getElementById('outlined-basic1').value
        var basic2 =  document.getElementById('outlined-basic2').value
        var basic3 =  document.getElementById('outlined-basic3').value
        var basic4 =  document.getElementById('outlined-basic4').value
        var input_code = basic1 + basic2 + basic3 + basic4
        if (input_code !== code) {
            setValidcode(false)
        }
        await delay(1500)
        setValidcode(true)
       handleEvaluateCodeVerify()
    }
   
    const handlePaste = async (e) => {
        e.preventDefault();
        var data = e.clipboardData.getData('Text')
        var data_arr = Array.from(data)
        document.getElementById('outlined-basic1').value = ''
        document.getElementById('outlined-basic1').value = data_arr[0]
        document.getElementById('outlined-basic2').value = data_arr[1]
        document.getElementById('outlined-basic3').value = data_arr[2]
        document.getElementById('outlined-basic4').value = data_arr[3]
        document.getElementById('outlined-basic4').focus()
    }
   
    const [submitted, setSubmitted] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
      const classes = useStyles({ color: progressColor });
      const handleGoogleSignIn = () => {
        mixpanel.track('Sign In With Google Clicked', {

        });
      }
    const body = (
       
 <Container className={classes.container} style={{marginTop: breakpoint !== 'large' ? '280px' : null}}>
      <Paper className={classes.paper}>
      <AppBarHeader selectedElement={selectedElement} breakpoint={breakpoint} isactive={active} style={{ width: '100vw' }} />

        <Grid container spacing={4}>
          {/* Left Side */}
          <Grid item xs={12} md={6} className={classes.leftContent}>
            <div className={classes.iconContainer}>
              {/* Placeholder for the  Icons */}{!triggerData?.data.app ? null : <>
              <Avatar className={classes.icon} src={triggerData?.data.icon} style={{height: '75px', width: '75px'}} />
              <Avatar className={classes.icon} src={actionData?.data.icon} style={{height: '75px', width: '75px'}} />
              </>
              }
            </div><br/>
            <Typography variant="h5" gutterBottom>
                {!triggerData?.data.app ? "Join a worldwide network of professionals automating their work with Workload." : triggerData?.data.name !== "Click to Add Trigger" ? actionData?.data.name + " from a " + triggerData?.data.name : "Connect "+triggerData?.data.label+" to "+actionData?.data.label+" without writing code"}
             
            </Typography><br/>
            <div className={classes.benefits}>
              <CheckCircleRounded className={classes.checkIcon} />
              <Typography variant="subtitle1" >
                No credit card to start
              </Typography>
            </div>
            <div className={classes.benefits}>
              <CheckCircleRounded className={classes.checkIcon} />
              <Typography variant="subtitle1" >
                Easy setup, no coding required
              </Typography>
            </div>
            <div className={classes.benefits}>
              <CheckCircleRounded className={classes.checkIcon} />
              <Typography variant="subtitle1" >
                Premium features, apps, and support included
              </Typography>
            </div>
          </Grid>

          {/* Right Side (Form) */}
          <Grid item xs={12} md={6} className={classes.rightContent}>
           
          <img  height={breakpoint !== 'large' ? '25px' : '35px'} src="https://cdn.workload.co/wp-content/uploads/cropped-new-workload-logo-high-res-900-×-193-px.png" />
            <br />
            <br/>
             {screenOne === true ? <>
             {/* Google Sign-up button */}
{/*              <Button
             onClick={handleGoogleSignIn}
      variant="contained"
      style={{
        backgroundColor: '#4285F4',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', // Centers the text horizontally
        padding: '10px 20px',
        borderRadius: '5px',
        fontWeight: 'bold',
        fontSize: '16px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        width: '100%',
        position: 'relative',
        height: '50px', // Added to control button height
        textTransform: 'none'
      }}
      fullWidth
    >
      <div
        style={{
          position: 'absolute',
          left: '20px', // Position the logo to the left
          display: 'flex',
          alignItems: 'center', // Vertically center the logo
          justifyContent: 'center',
          width: '32px',
          height: '32px',
          backgroundColor: '#fff', // White background for logo
          borderRadius: '8px', // Rounded corners for square background
        }}
      >
        <GoogleLogo />
      </div>
              Sign in with Google</Button> */}

              {/* OR Divider */}
     {/*  <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
        <hr style={{ flexGrow: 1, border: 'none', borderTop: '1px solid #ccc' }} />
        <span style={{ margin: '0 10px', fontWeight: 'bold', color: '#666' }}>OR</span>
        <hr style={{ flexGrow: 1, border: 'none', borderTop: '1px solid #ccc' }} />
      </div> */} </>: null } 
      

            {/* Form */}
            <form className={classes.form} noValidate autoComplete="off">
            {screenOne === true ? <> <TextField
                required
                fullWidth
                autoFocus
                type="email"
                id="workemail"
                label="Work Email (required)"
                variant="outlined"
                margin="normal"
                error={validemail === true ? 0 : 1}
                helperText={validemail === true ? null : "Invalid Email"}
                onChange={(e) => setWorkemail(e.target.value)}
              />
               <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    label="First Name"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                  />
                </Grid>
              </Grid> </>: null }
             { screenOne === true ? null : <><Typography>Signing up as {workemail}.</Typography>  <TextField
                required
                fullWidth
                type={showPassword ? "text" : "password"}
                id="password"
                label="Create password (required)"
                variant="outlined"
                margin="normal"
                error={pwvalid ? 0 : 1}
                helperText={pwvalid ? null : pwvalidmessage.join(" ")}
                onChange={(e) => {setPw(e.target.value); handlePwProgress(e.target.value)}} 
                //onBlur={handlePwValid}
                InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
              />
               {/* Progress bar */}
      <Box mt={2}>
        <LinearProgress
          variant="determinate"
          value={progress}
          style={{ height: 10, backgroundColor: 'lightgray', borderRadius: 8}}
          className={classes.progressBar}
        />
      </Box>

      {/* Display progress as text */}
      <Typography variant="body2" color="textSecondary">
        {progressHuman} password
      </Typography>
              </> }
              {/*  <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    label="First Name"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                  />
                </Grid>
              </Grid> */}
               {signuperror ? <Alert style={{marginBottom:'10px'}} variant="filled" severity="error">
           {signuperror} <a  href="https://automate.workload.co/auth/signin"><Typography style={{color: 'white', textDecoration: 'underline'}}>Click here to sign in.</Typography></a>
         </Alert> : null }
              <Button
                fullWidth
                variant="contained"
                className={classes.button}
                size="large"
                color="secondary"
                disabled={submitted ?? pwvalid === false ?? validemail === false ? 1 : 0}
                onClick={() => {screenOne === true ? handleEmailChange(workemail) : handlePwValid() }}
              >
                <Typography variant="h6">Get started free</Typography>
              </Button>
            </form>

            <Typography variant="caption" color="textSecondary" display="block" style={{ marginTop: '16px' }}>
              By signing up, you agree to Workload's <a href="https://www.workload.co/terms" target="_blank">terms of service</a> and <a href="https://www.workload.co/privacy" target="_blank">privacy policy</a>.
            </Typography>
          </Grid>
        </Grid>
              {/* Trusted Section with Company Logos */}
        <div className={classes.trustedSection}>
          <Typography variant="subtitle1" >
            Trusted by companies from startups to enterprises
          </Typography>
          <Grid container justfiyContent="center" direction="row">
            <Grid item xs>
              <img
                src="https://www.workload.co/wp-content/uploads/stackstreet-1.png"
                alt="StackStreet Logo"
                className={classes.logo}
              />
            </Grid>
            <Grid item xs >
              <img
                src="https://www.workload.co/wp-content/uploads/delphix.png"
                alt="Delphix Logo"
                className={classes.logo}
              />
            </Grid>
            <Grid item xs >
              <img
                src="https://www.workload.co/wp-content/uploads/servicetitan-clear.png"
                alt="ServiceTitan Logo"
                className={classes.logo}
              />
            </Grid>
            <Grid item xs >
              <img
                src="https://www.workload.co/wp-content/uploads/rubius-clear.png"
                alt="Rubius Logo"
                className={classes.logo}
              />
            </Grid>
            <Grid item xs >
              <img
                src="https://www.workload.co/wp-content/uploads/visa.png"
                alt="Visa Logo"
                className={classes.logo}
              />
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Container>       
    );

    const body_tour = (
        <div style={modalStyle} className={classes.modaltour}>
            <Typography style={{ fontSize: '26px',fontWeight: 500, color: '#303030', textAlign: 'center', padding: '50px 15% 10px 15%' }} id="simple-modal-title">👋 <strong>Welcome!</strong><br/>Behind this popup is the power to automate all your boring work.</Typography>
            <Typography style={{ fontSize: '20px',fontWeight: 400, color: '#303030', textAlign: 'center', padding: '10px 0px 10px 0px' }} id="simple-modal-title">We'd love to show you how - <mark style={{ background: 'linear-gradient(to top, gold 50%, transparent 50%' }}>it'll be fast, promise.</mark></Typography>
            <img style={{display: 'flex', justfiyContent: 'center', alignItems: 'center', padding: '10px 10px 15px 10px', margin: 'auto', cursor: 'pointer' }} height='20%' src="https://cdn.workload.co/wp-content/uploads/welcometour.gif" onClick={()=> {mixpanel.track('Tour Start', {'user': user}); handleModalCloseTour(); setTourStart(true)}}/>
            <Button style={{boxShadow: '2px 2px 5px #303030', display: 'flex', justfiyContent: 'center', alignItems: 'center', padding: '10px', height: '50px', width: '200px', backgroundColor: '#0069ea', borderRadius: '4px', border: 'none', cursor: 'pointer', margin: 'auto' }} onClick={()=> {mixpanel.track('Tour Start', {'user': user}); handleModalCloseTour(); setTourStart(true)}}><div><Typography style={{ alignText: 'center', fontWeight: 'bold', color: 'white', float: 'left', display: 'block' }}>START A QUICK TOUR</Typography></div></Button> <br/>
            <div style={{width:'150px', margin: 'auto', justifyContent: 'center'}} onClick={()=> {handleModalCloseTour(); setTourStart(false); localStorage.setItem('tourReady',false)}}><Typography style={{ fontSize: '14px', color: 'black', textAlign: 'center', padding: '25px 15px 15px 15px', cursor: 'pointer' }} id="simple-modal-subtitle">Skip the tour</Typography></div>
            <Alert severity="info" style={{ margin: 'auto', justifyContent: 'center' }} variant="filled"><Typography style={{fontSize: '14px'}} id="simple-modal-title">Complete your first milestone by automating an example process.</Typography></Alert>

            
        </div>
    );

    const body_codeverify = (
      <Container className={classes.container} style={{marginTop: breakpoint !== 'large' ? '280px' : null}}>
      <Paper className={classes.paper}>
      <AppBarHeader selectedElement={selectedElement} breakpoint={breakpoint} isactive={active} style={{ width: '100vw' }} />

            <Typography style={{ fontSize: '26px',fontWeight: 500, color: '#303030', textAlign: 'center', padding: '50px 15% 10px 15%' }} id="simple-modal-title">Please check your email.</Typography>
            <Typography style={{ fontSize: '20px',fontWeight: 400, color: '#303030', textAlign: 'center', padding: '10px 0px 10px 0px' }} id="simple-modal-title">We've sent a verification code to: <mark style={{ background: 'linear-gradient(to top, gold 50%, transparent 50%' }}>{workemail}</mark></Typography>
            <Typography style={{ fontSize: '16px',fontWeight: 400, color: 'grey', textAlign: 'center', padding: '50px 15% 10px 15%' }} id="simple-modal-title">Be sure to mark it as NOT SPAM if the email wound up in your spam folder.</Typography>

            <div id='container' style={{width: '100%', display: 'flex', marginBottom: '25px'}}>
            <div id='thing1' style={{width: '20%', padding: '2%'}}><TextField style={{border: validcode === false ? '2px solid red' : null, borderRadius: validcode === false ? '4px' : null}} autoFocus InputProps={{max: 1, style: { fontSize: 40, paddingLeft: 20 } }} id="outlined-basic1"  variant="outlined" onKeyUp={(e) => { handleKeyUp1(e.target.value); }} onPaste={ (e) => handlePaste(e) } /></div>
            <div id='thing2' style={{width: '20%', padding: '2%'}}><TextField style={{border: validcode === false ? '2px solid red' : null, borderRadius: validcode === false ? '4px' : null}} InputProps={{max: 1, style: { fontSize: 40, paddingLeft: 20 } }} id="outlined-basic2"  variant="outlined" onKeyUp={(e) => { handleKeyUp2(e.target.value); }} /></div>
            <div id='thing3' style={{width: '20%', padding: '2%'}}><TextField style={{border: validcode === false ? '2px solid red' : null, borderRadius: validcode === false ? '4px' : null}} InputProps={{max: 1, style: { fontSize: 40, paddingLeft: 20 } }} id="outlined-basic3"  variant="outlined" onKeyUp={(e) => { handleKeyUp3(e.target.value);}}  /></div>
            <div id='thing4' style={{width: '20%', padding: '2%'}}><TextField style={{border: validcode === false ? '2px solid red' : null, borderRadius: validcode === false ? '4px' : null}} InputProps={{max: 1, style: { fontSize: 40, paddingLeft: 20 } }} id="outlined-basic4"  variant="outlined" onKeyUp={(e) => { handleKeyUp4(e.target.value); }}  /></div>
            </div>
            <Button style={{textTransform: 'none', boxShadow: '2px 2px 5px #303030', display: 'flex', justfiyContent: 'center', alignItems: 'center', padding: '10px', height: '50px', width: '200px', backgroundColor: '#0069ea', borderRadius: '4px', border: 'none', cursor: 'pointer', margin: 'auto' }} onClick={()=> {mixpanel.track('Verify Email', {'user': user}); handleModalCloseTour(); }}><div><Typography style={{ alignText: 'center', fontWeight: 'bold', color: 'white', float: 'left', display: 'block' }}>Verify</Typography></div></Button> <br/>
            
            <Alert severity="warning" style={{ margin: 'auto', justifyContent: 'center' }} variant="filled"><Typography style={{fontSize: '14px'}} id="simple-modal-title">Didn't get a code? Click to resend.</Typography></Alert>

            
        </Paper>
        </Container>
    );

    const body_desktop_reminder = (
       
            <div style={modalStyle} className={breakpoint !== 'large' ? classes.modalSmall : classes.modal}>
                <img style={{ marginBottom: breakpoint !== 'large' ? '20px' : '50px' }} height='25px' src="https://cdn.workload.co/wp-content/uploads/cropped-new-logo-only-900x193-1.png" />
                
                <br />
                <Typography style={{width: '100%', fontSize: breakpoint !== 'large' ? '30px' : null}} variant="h4" id="simple-modal-title">Switch to <mark style={{ background: 'linear-gradient(to top, gold 50%, transparent 50%' }}>Desktop</mark></Typography>
                <br/>
                <Typography style={{ fontSize: breakpoint !== 'large' ? '14px' :'18px' }} id="simple-modal-subtitle">It looks like you're on mobile. Our software really does its magic on desktop. Hit <strong>Remind Me</strong> to get an email with your unique sign in link!</Typography>
                <br />
                
                <TextField error={validworkemail === true ? 0 : 1} helperText={validworkemail === true ? "Enter a valid email address" : "INVALID EMAIL"} style={{ width: '100%' }} id="workemail" variant="outlined" label="Work Email" onChange={(e) => setDeskWorkemail(e.target.value)} /><br /><br />
                
                <Button variant="text" color="default" style={{ float: 'left', textTransform: 'none', fontSize: breakpoint === 'small' ? '14px' : null }} onClick={() => setDesktopReminderModal(false)}>Dismiss</Button>
                <Button disabled={deskworkemail ? 0 : 1} style={{ width: breakpoint === 'small' ? '140px' : '175px', textTransform: 'none', float: 'right', display: 'block'}} variant='contained' color='primary' onClick={() => handleDeskWorkemailChange(deskworkemail)}>{ desktopreminderloading === true ? <CircularProgress size={30} color="secondary" /> : 
                <div>
               
                <Typography style={{float: 'left', fontWeight: 'bold', color: 'white', marginTop: '2px',fontSize: breakpoint === 'small' ? '14px' : null }}>Remind Me</Typography> 
                <ScheduleRoundedIcon style={{ marginLeft: '5px', color: 'white', float: 'right', display: 'block' }} /></div> }  
                </Button> 
                
                <br />
            </div>
    );

    const sendLoggedOutToAuthorizer = (isLoggedOut) => { // the callback. Use a better name
      //  console.log('user is logged out? ', isLoggedOut);
        handleModalOpen();
    };

    const [counter, setCounter] = useState(7);
    useEffect(() => {
        const timer =
          counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
          if (counter === 0 && !user && modalwidth < 430){
           // setDesktopReminderModal(true);
          }
        return () => clearInterval(timer);
      }, [counter]);


    const steps =  [
        {
          target: '#trigger-empty',
          title: (<div style={{fontSize: '18px', fontWeight: 600}}>This a trigger step</div>),
          content: (<div>Click inside the <strong>App dropdown</strong> to start automating a business process.<br/><br/><div style={{fontSize: '12px', color: 'gray'}}>A trigger is the first step in a workflow. We'll listen for changes to data in an app and then perform actions later on as a result of those changes.</div><MobileStepper style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex',maxWidth: 200,flexGrow: 1, margin: 'auto'}} variant="dots" steps={12} position="bottom" activeStep={stepIndex} /></div>),
          disableBeacon: true,
          hideCloseButton: false,
          disableOverlayClose: true,
          disableScrolling: true,
          showSkipButton: true,
          hideFooter: true,
          placement: 'right',
          spotlightClicks: true,
          styles: {
              options: {
                primaryColor: '#0069ea',
                textColor: 'black',
                zIndex: 99999,
              },
            }
        },
        {
          target: '#trigger-combo-box-demo',
          title: (<div style={{fontSize: '18px', fontWeight: 600}}>Choose trigger app</div>),
          content: (<div>For this example, type <strong>Workload Demo</strong> into the search box.<br/><br/><div style={{fontSize: '12px', color: 'gray'}}>This is the app where we will listen for events that will start a business process.</div><MobileStepper style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex',maxWidth: 200,flexGrow: 1, margin: 'auto'}} variant="dots" steps={12} position="bottom" activeStep={stepIndex} /></div>),
          placement: 'right',
          disableBeacon: true,
          disableScrolling: true,
          hideCloseButton: false,
          disableOverlayClose: false,
          showSkipButton: true,
          hideFooter: true,
          disableOverlay: true,
          styles: {
            options: {
                primaryColor: '#0069ea',
                textColor: 'black',
                zIndex: 99999,
              },
            }
        },
        {
            target: '#trigger-choose-event',
            title: (<div style={{fontSize: '18px', fontWeight: 600}}>Choose trigger event</div>),
            content: (<div>Our process will start <i>when a new user is created in Workload Demo</i>.<br/><br/>Select <strong>New User Created</strong> from the dropdown list and click <strong>Continue</strong>.<br/><br/><div style={{fontSize: '12px', color: 'gray'}}>An event is something that happens inside of an app that kicks off a workflow.</div><MobileStepper style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex',maxWidth: 200,flexGrow: 1, margin: 'auto'}} variant="dots" steps={12} position="bottom" activeStep={stepIndex} /></div>),
            placement: 'right',
            disableBeacon: true,
            hideFooter: true,
            hideCloseButton: true,
            disableOverlay: true,
            styles: {
              options: {
                  primaryColor: '#0069ea',
                  textColor: 'black',
                  zIndex: 99999,
                },
              }
          },
          {
            target: '#trigger-save',
            title: (<div style={{fontSize: '18px', fontWeight: 600}}></div>),
            content: (<div>If you see sample data, click on <strong>Save & Close</strong> at the bottom.<br/><br/><div style={{fontSize: '12px', color: 'gray'}}>This is the data we can reference throughout the rest of the workflow.</div><MobileStepper style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex',maxWidth: 200,flexGrow: 1, margin: 'auto'}} variant="dots" steps={12} position="bottom" activeStep={stepIndex} /></div>),
            placement: 'right-end',
            disableBeacon: true,
            hideCloseButton: true,
            hideFooter: true,
            offset: 0,
            disableOverlay: true,
            styles: {
              options: {
                  primaryColor: '#0069ea',
                  textColor: 'black'
                
                },
              }
          },
          {
            target: '#root',
            title: (<div style={{fontSize: '18px', fontWeight: 600}}><br/>🙌🏼 You're on a roll!<br/><img src="https://cdn.workload.co/wp-content/uploads/workloadtourhalf.gif" height='200px' /></div>),
            content: (<div><strong>Now let's create an action.</strong><br/><br/><div style={{fontSize: '12px', color: 'gray'}}>An automated process must consist of at least two elements, a trigger and an action.</div></div>),
            placement: 'center',
            disableBeacon: true,
            hideCloseButton: true,
            hideFooter: false,
            showSkipButton: true,
            showProgress: false,
            hideBackButton: true,
            disableOverlay: false,
            styles: {
              options: {
                  primaryColor: '#0069ea',
                  textColor: 'black',
                  zIndex: 99999,
                },
              }
          },
          {
            target: '#edit-button-action',
            title: (<div style={{fontSize: '18px', fontWeight: 600}}>This is an action step</div>),
            content: (<div>Click inside the <strong>action step</strong> to finish building your business process.<br/><br/><div style={{fontSize: '12px', color: 'gray'}}>Actions come after the trigger step in a workflow. Actions are used to create, update, and search data in other software tools automatically!</div></div>),
            disableBeacon: true,
            hideCloseButton: false,
            disableOverlayClose: true,
            disableScrolling: true,
            showSkipButton: true,
            hideFooter: true,
            placement: 'right',
            spotlightClicks: true,
            styles: {
                options: {
                  primaryColor: '#0069ea',
                  textColor: 'black',
                  zIndex: 99999,
                },
              }
          },
          {
            target: '#action-combo-box-demo',
            title: (<div style={{fontSize: '18px', fontWeight: 600}}>Choose action app</div>),
            content: (<div>Like before, type <strong>Workload Demo</strong> into the search box.<br/><br/><div style={{fontSize: '12px', color: 'gray'}}>This is the app where we will create, update, or search data to automate business processes.</div><MobileStepper style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex',maxWidth: 200,flexGrow: 1, margin: 'auto'}} variant="dots" steps={12} position="bottom" activeStep={stepIndex} /></div>),
            placement: 'right',
            disableBeacon: true,
            disableScrolling: true,
            hideCloseButton: false,
            disableOverlayClose: false,
            showSkipButton: true,
            hideFooter: true,
            disableOverlay: true,
            styles: {
              options: {
                  primaryColor: '#0069ea',
                  textColor: 'black',
                  zIndex: 99999,
                },
              }
          },
          {
            target: '#choose-action',
            title: (<div style={{fontSize: '18px', fontWeight: 600}}>Choose action</div>),
            content: (<div>We want to <i>create a new Todo item</i> when a <i>new contact is created</i> in our worklfow.<br/><br/>Select <strong>Create Todo</strong> from the dropdown list.<br/><br/><div style={{fontSize: '12px', color: 'gray'}}>An action is a step in the workflow that creates, updates, or searches data inside of an app.</div><MobileStepper style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex',maxWidth: 200,flexGrow: 1, margin: 'auto'}} variant="dots" steps={12} position="bottom" activeStep={stepIndex} /></div>),
            placement: 'right',
            disableBeacon: true,
            disableScrolling: true,
            hideFooter: true,
            offset: 0,
            hideCloseButton: true,
            disableOverlay: true,
            styles: {
              options: {
                  primaryColor: '#0069ea',
                  textColor: 'black',
                  zIndex: 99999,
                },
              }
          },
          {
            target: '#action-setup',
            title: (<div style={{fontSize: '18px', fontWeight: 600}}>Set up action<br/><img src="https://cdn.workload.co/wp-content/uploads/mapfieldsdemo.gif" width='100%' /></div>),
            content: (<div>Map the <strong>contact name</strong> data from the trigger step by clicking into the <strong>Title</strong> field.<br/><br/><div style={{fontSize: '12px', color: 'gray'}}>This is how you reference data when buildling a worklfow.</div><MobileStepper style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex',maxWidth: 200,flexGrow: 1, margin: 'auto'}} variant="dots" steps={12} position="bottom" activeStep={stepIndex} /></div>),
            placement: 'right',
            disableBeacon: true,
            hideCloseButton: true,
            hideFooter: true,
            disableOverlay: true,
            styles: {
              options: {
                  primaryColor: '#0069ea',
                  textColor: 'black',
                  zIndex: 99999,
                },
              }
          },
          {
            target: '#actions-test',
            title: (<div style={{fontSize: '18px', fontWeight: 600}}>Test action</div>),
            content: (<div>Send sample data to the app by clicking on <strong>Test Action</strong>.<br/><br/><div style={{fontSize: '12px', color: 'gray'}}>Sending sample data ensures that the workflow will execute properly when it is turned on.</div><MobileStepper style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex',maxWidth: 200,flexGrow: 1, margin: 'auto'}} variant="dots" steps={12} position="bottom" activeStep={stepIndex} /></div>),
            placement: 'right',
            disableBeacon: true,
            hideCloseButton: true,
            hideFooter: true,
            disableOverlay: true,
            styles: {
              options: {
                  primaryColor: '#0069ea',
                  textColor: 'black',
                  zIndex: 99999,
                },
              }
          },
          {
            target: '#actions-save',
            title: (<div style={{fontSize: '18px', fontWeight: 600}}>Save & Close action</div>),
            content: (<div>Click <strong>Save & Close</strong> to commit this step.<br/><br/><div style={{fontSize: '12px', color: 'gray'}}>This step ensures the data references are saved properly during run-time.</div><MobileStepper style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex',maxWidth: 200,flexGrow: 1, margin: 'auto'}} variant="dots" steps={12} position="bottom" activeStep={stepIndex} /></div>),
            placement: 'right',
            disableBeacon: true,
            hideCloseButton: true,
            hideFooter: true,
            disableOverlay: true,
            styles: {
              options: {
                  primaryColor: '#0069ea',
                  textColor: 'black',
                  zIndex: 99999,
                },
              }
          },
          {
            target: '#root',
            title: (<div style={{fontSize: '18px', fontWeight: 600}}><br/>BOOM SHAKALAKA!<br/><img src="https://cdn.workload.co/wp-content/uploads/welcometourcomplete.gif" height='200px' /></div>),
            content: (<div><strong>You just created your first automated process using Workload.</strong><br/><br/><div style={{fontSize: '12px', color: 'gray'}}>An automated process must consist of at least two elements, a trigger and an action.</div></div>),
            placement: 'center',
            disableBeacon: true,
            hideCloseButton: true,
            hideFooter: false,
            showSkipButton: true,
            showProgress: false,
            hideBackButton: true,
            disableOverlay: false,
            locale: {last: 'Finish'},
            styles: {
              options: {
                  primaryColor: '#0069ea',
                  textColor: 'black',
                  zIndex: 99999,
                },
              }
          },
      ];
const [renderready, setRenderReady] = useState(false)
      const handleJoyrideCallback = (props) => {
        const { action, index, status, type, lifecycle } = props;
       // console.log('joyride props are: ', props)
      // console.log('joyride stepIndex = ', stepIndex)

        if (action === 'next' && index === 4){
            mixpanel.track('Tour: Step 5 Completed', {
                'user': user
            });
            setStepIndex(5)
        }

        if (action === 'next' && index === 11){
            mixpanel.track('Tour: Step 11 Completed', {
                'user': user
            });
            setTourStart(false)
        }

        if (action === 'update'){
            setRenderReady(true)
        }
        if (action === 'next'){
            setRenderReady(false)
        }

        if (action === 'close') {
            setTourStart(false)
        }
        
        

      }

       useEffect(() => {
        if (run === true && renderready === true && stepIndex === 1 && selectedElement && selectedElement.data.app === 'workload-demo'){
            mixpanel.track('Tour: Step 1 Completed', {
                'user': user
            });
            setStepIndex(stepIndex + 1)
        }

        if (run === true && renderready === true && stepIndex === 2 && selectedElement && selectedElement.data.action === 'newUser'){
            mixpanel.track('Tour: Step 2 Completed', {
                'user': user
            });
            setStepIndex(stepIndex + 1)
        }

        if (run === true && renderready === true && stepIndex === 3 && selectedElement === undefined){
            mixpanel.track('Tour: Step 3 Completed', {
                'user': user
            });
            setStepIndex(stepIndex + 1)
        }

        if (run === true && renderready === true && stepIndex === 4 && selectedElement && selectedElement.data.testData){
            mixpanel.track('Tour: Step 4 Completed', {
                'user': user
            });
            setStepIndex(stepIndex + 1)
        }

        if (run === true && renderready === true && stepIndex === 6 && selectedElement && selectedElement.data.type === 'workload-demo'){
            mixpanel.track('Tour: Step 6 Completed', {
                'user': user
            });
            setStepIndex(stepIndex + 1)
        }

        if (run === true && renderready === true && stepIndex === 7 && selectedElement && selectedElement.data.action === 'createTodo'){
            mixpanel.track('Tour: Step 7 Completed', {
                'user': user
            });
            setStepIndex(stepIndex + 1)
        }

        if (run === true && renderready === true && stepIndex === 8 && selectedElement && selectedElement.data.fields[0].value && selectedElement.data.fields[0].value.title){
            mixpanel.track('Tour: Step 8 Completed', {
                'user': user
            });
            setStepIndex(stepIndex + 1)
        }

        if (run === true && renderready === true && stepIndex === 9 && selectedElement && selectedElement.data.fields[0].value && selectedElement.data.testData){
            mixpanel.track('Tour: Step 9 Completed', {
                'user': user
            });
            setStepIndex(stepIndex + 1)
        }

        if (run === true && renderready === true && stepIndex === 10 && selectedElement === undefined){
            mixpanel.track('Tour: Step 10 Completed', {
                'user': user
            });
            setStepIndex(stepIndex + 1)
        }


      }, [selectedElement]) 
      
      
      const colors = [
        'primary',
        'secondary',
      ];
      
      const [color, setColor] = useState(0);
    
      useEffect(() => {
        if (workflowloading === true) {
          const interval = setInterval(() => {
            setColor((v) => (v === 2 ? 0 : v + 1));
          }, 1500);
        }
      }, []); 

     //dagre graph layouting to make evenly spaced nodes and auto layout to share lines evenly
   // Define the size and spacing of nodes
const nodeWidth = 450;
const nodeHeight = 80;
const nodeSpacingX = 20;
const nodeSpacingY = 100;

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
dagreGraph.setGraph({ rankdir: 'TB', nodesep: nodeSpacingX, ranksep: nodeSpacingY });

const getLayoutedElements = (elements) => {
  const nodes = elements.filter(el => !el.source); // Elements without a source are nodes
  const edges = elements.filter(el => el.source && el.target); // Elements with source and target are edges

  // Add nodes to the Dagre graph
  nodes.forEach(node => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  // Add edges to Dagre graph
  edges.forEach(edge => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  // Perform layout
  dagre.layout(dagreGraph);

  // Update the nodes' position based on Dagre layout
  // Update the nodes' position based on Dagre layout
const layoutedNodes = nodes.map(node => {
  const dagreNode = dagreGraph.node(node.id);
  
  // Return a new node object with updated position
  return {
    ...node, // spread existing node properties
    position: {
      x: dagreNode.x - nodeWidth / 2,
      y: dagreNode.y - nodeHeight / 2,
    },
  };
});

  return [...layoutedNodes, ...edges];
};
   
const [nodes, setNodes] = useState(elements.filter(el => !el.source));
const [edges, setEdges] = useState(elements.filter(el => el.source && el.target));

const layoutNodes = useCallback(() => {
  const layoutedElements = getLayoutedElements(elements);
  const layoutedNodes = layoutedElements.filter(el => !el.source);
  const layoutedEdges = layoutedElements.filter(el => el.source && el.target);
  setNodes([...layoutedNodes]);
  setEdges([...layoutedEdges]);
 // 
 /* if (reactFlowInstance) {
  reactFlowInstance.fitView({ padding: 0.1 }); // Layout nodes on initial load
 } */

  
}, [elements]);

// set the scroll boundaries to prevent infinite scrolling/panning...
// Compute a fixed translateExtent using useMemo.
const fixedTranslateExtent = useMemo(() => {
  if (nodes && nodes.length > 0) {
    const padding = 200; // adjust as needed
    const xs = nodes.map((n) => n.position.x + 225);
    const ys = nodes.map((n) => n.position.y);
    const minX = Math.min(...xs) - padding;
    const minY = Math.min(...ys) - padding;
    const maxX = Math.max(...xs) + padding;
    const maxY = Math.max(...ys) + padding;
   
    return [[minX, minY], [maxX, maxY]];
  }
  return [[0, 0], [1000, 1000]]; // default if no nodes
}, [nodes]); // Only re-compute if nodes array changes




const onLoad = (reactFlowInstance) => {
  setReactFlowInstance(reactFlowInstance)
};

 useEffect(() => {
   if (reactFlowInstance) {
     reactFlowInstance.fitView({ padding: 0.1 });
   }
 }, [reactFlowInstance]);

// Trigger layout on load or with a button (you can integrate this anywhere)
useEffect(() => {
 
  layoutNodes(); // Layout nodes on initial load
 
  
}, [elements]);
 
    try {
        return (
            <>
            
                {workflow && elements && accessDenied === false ?
                    
                    <AppBarHeader selectedElement={selectedElement} breakpoint={breakpoint} isactive={active} sendLoggedOutToAuthorizer={sendLoggedOutToAuthorizer} style={{ width: '100vw' }} /> :
                   <> <AppBarHeaderDummy style={{ width: '100vw' }} />
                   <div style={{width: '100%', display: 'flex'}}>
                   <div style={{justifyContent: 'center', margin: '0 auto', width: '75%'}}>
                   <div style={{ marginTop: '50px'}}>
                   <Typography variant="h3">No access to workflow</Typography>
                   </div>
                   <div style={{marginTop: '24px'}}><Typography style={{fontSize: '20px'}} variant="body">You either don't have permission to see this Workflow or the Workflow does not exist.
                    Please try reloading the page or switching to a different account.</Typography></div>
                    <div style={{marginTop: '24px', justifyContent: 'center'}}><Typography style={{fontSize: '16px'}} variant="body">Need help? Contact support .</Typography></div>
                   </div>
                   </div>  </>}
                {workflowloading === false && workflow && elements.length > 0 && accessDenied === false ?
                    <div onKeyDown={handleKeyDownSave} tabIndex='0' style={{ width: '100vw', height: '100vh' }}>      
                                   
                        <ReactFlowProvider>
                            <div>
                                <ReactFlow
                                    elements={[...nodes, ...edges]}
                                    translateExtent={fixedTranslateExtent}
                                    connectionLineType="smoothstep"
                                    connectionLineStyle={{ stroke: '#0069ea', strokeWidth: 2 }}
                                    style={flowStyle}
                                    connectionLineComponent={ConnectionLine}
                                    onLoad={onLoad}
                                    onElementsRemove={onElementsRemove}
                                    onConnect={onConnect}
                                    onConnectStart={handleonConnectStart}
                                    onConnectStop={handleLineDrop}
                                    selectNodesOnDrag={false}
                                    onElementClick={handleElementClick}
                                    deleteKeyCode={248}
                                    onPaneClick={handlePaneClick}
                                    nodeTypes={nodeTypes}
                                    edgeTypes={edgeTypes}
                                    onNodeDragStop={handleNodeMove}
                                    snapToGrid={true}
                                    snapGrid={[20, 20]}
                                    //maxZoom={1.5}
                                    //defaultZoom={defaultZoom}
                                    nodesDraggable={false}
                                    //defaultPosition={breakpointPosition}
                                    paneMoveable={selectedElement ? false: true}
                                    zoomOnScroll={false}
                                    zoomOnPinch={false}
                                    zoomOnDoubleClick={false}
                                    //showZoom={true}
                                    //showFitView={true}
                                    
                                   // showInteractive={false}
                                    panOnScroll={true}
                                //    sendLoggedOutToAuthorizer={sendLoggedOutToAuthorizer}
                                >
                                  
                                <Joyride
                                 callback={handleJoyrideCallback}
                                 continuous
                                 disableScrollParentFix={true}
                                 run={run}
                                 disableScrolling={false}
                                 showProgress
                                 showSkipButton
                                 stepIndex={stepIndex}
                                 steps={steps}
                             />
                                   {notify && notify.status ? <Snackbar open={openalert} autoHideDuration={6000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                                        <Alert onClose={handleCloseAlert} severity={notify.status.status} variant="filled">
                                           <Typography style={{fontSize: '16px'}}>{notify.status.message}</Typography>
                                        </Alert>
                                    </Snackbar> : null }
                                    
                                    <div style={{ position: 'relative', zIndex: 5 }}>
                                    
                                   {createTrigger === true ?  <CreateTriggerPanel key={'create-trigger-panel'} breakpoint={breakpoint} user={user} tenant={tenant} app={createTriggerApp} onPaneClick={handlePaneClick}   /> : null}
                                   {createSupportTicket === true ?  <CreateSupportTicketPanel key={'create-support-ticket-panel'} breakpoint={breakpoint} user={user} tenant={tenant} app={createSupportTicketApp} onPaneClick={handlePaneClick}   /> : null}
                                        {
                                            selectedElement ?
                                            
                                                selectedElement.type === 'action' ?

                                                    <ActionPanel key={selectedElement?.id} breakpoint={breakpoint} user={user} tenant={tenant} selectedNode={selectedElement} onPaneClick={handlePaneClick} sendNode={updateNode} deleteNode={onElementsRemove} workflowID={workflow.id} sendLoggedOutToAuthorizer={sendLoggedOutToAuthorizer}/>
                                                    : selectedElement.type === 'trigger' ?
                                                      <TriggerPanel key={selectedElement?.id} className='trigger-card' breakpoint={breakpoint} user={user} tenant={tenant} selectedNode={selectedElement} onPaneClick={handlePaneClick} createCustomTrigger={handleCreateTrigger} createSupportTicket={handleCreateSupportTicket} sendNode={updateNode} sendLoggedOutToAuthorizer={sendLoggedOutToAuthorizer} /> 
                                                        : selectedElement.type === 'filter' ?
                                                            <FilterPanel key={selectedElement?.id} breakpoint={breakpoint} user={user} tenant={tenant} selectedEdge={selectedElement} onPaneClick={handlePaneClick} sendEdge={updateNode}  /> :
                                                             

                                                            null
                                                : null
                                                
                                        }

                                    </div>
                       
                                    <Modal
                                    style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                                       
                                        open={modalopen}
                                        //onClose={handleModalClose}
                                        aria-labelledby="simple-modal-title"
                                       
                                    >
                                        {body}
                                    </Modal>
                                    <Modal
                                    style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                                        open={desktopReminderModal}
                                        onClose={handleDesktopModalClose}
                                        aria-labelledby="simple-modal-title"
                                       
                                    >
                                        {body_desktop_reminder}
                                    </Modal>
                                    <Modal
                                    style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                                        open={modalopentour}
                                        onClose={handleModalCloseTour}
                                        aria-labelledby="simple-modal-title"
                                       
                                    >
                                        {body_tour}
                                    </Modal>
                                    <Modal
                                    style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                                        open={modalopencodeverify}
                                      // onClose={handleModalCloseCodeVerify}
                                        aria-labelledby="simple-modal-title"
                                       
                                    >
                                        {body_codeverify}
                                    </Modal>{ elements?.length > 8 ? 
                                    <MiniMap
                                    style={{marginBottom: '65px',position: 'absolute',left: 50}}
                                        nodeStrokeColor={(n) => {
                                        if (n.style?.background) return n.style.background;
                                        if (n.type === 'trigger') return '#ff0072';
                                        if (n.type === 'action') return '#0069ea';
                                        

                                        return '#eee';
                                        }}
                                        nodeColor={(n) => {
                                        if (selectedElement?.id === n.id) return '#000000'
                                        if (n.style?.background) return n.style.background;
                                        if (n.type === 'trigger') return '#ff0072';
                                        if (n.type === 'action') return '#0069ea';
                                        
                                        return '#fff';
                                        }}
                                        nodeBorderRadius={2}
                                    /> : null }
                                     <Controls style={{marginBottom: '65px'}} />
                                    <Background
                                        variant="dots"
                                        gap={16}
                                        size={1}
                                        color={"#d7d5d2"}
                                        />
                                </ReactFlow>
                            </div>
                        </ReactFlowProvider>
                    </div> :
                    workflowloading === true ? <div style={{margin: 'auto', width: '100%', display: 'flex', justifyContent: 'center', top: '50%'}} ><CircularProgress style={{ left: '50%', top: '50%', position: 'absolute' }} color="secondary" size={50} /></div> : null }
            </>
        )
    }
    catch (e) {

        var config = {
            method: 'post',
            url: 'https://api.airtable.com/v0/appb76LyMibAJXsfE/FrontendErrors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer key3FrNljA0k5KIGo'
            },
            data: {
                'records': [
                    {
                        'fields': {
                            'Error source': 'flowApp',
                            'Error': e.toString()
                        }
                    }
                ]
            }
        }

        axios(config)

        return (
            <div>
                Something broke, please refresh
            </div>
        )
    }
};
