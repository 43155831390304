import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Panel.module.css';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider, Typography, Button, TextField, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { WorkflowWidget } from '../helpers/panel-functions';
import { FieldsRender } from '../helpers/panel-functions';
import {
  elementUpdated,
  selectElementById,
  selectFilterMode
} from '../../features/workflow/workflowSlice';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    border: '1px solid #e0e0e0',
    borderRadius: 4,
    padding: theme.spacing(2),
    width: '90%',
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(4)
  },
  selectField: {
    width: '97.5%',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    textTransform: 'none'
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
    backgroundColor: '#0069ea',
    fontWeight: 500
  },
  filterHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
}));

function FilterPanel({ selectedEdge, onPaneClick, parentTree }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Retrieve the edge from Redux
  const edge = useSelector(selectElementById(selectedEdge.id));
  const filterMode = useSelector(selectFilterMode); // 'filter' or 'delay'
  const delayOnly = filterMode?.mode === 'delay';
  // Local state for basic edge info
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [label, setLabel] = useState('');
  const [icon, setIcon] = useState('');
  const [fields, setFields] = useState([]);
  const [testData, setTestData] = useState(null);

  // Local state for filter rules.
  // "andFilters" is an array of rule objects; "orGroups" is an array of arrays of rule objects.
  const [andFilters, setAndFilters] = useState([]);
  const [orGroups, setOrGroups] = useState([]);

  // When the edge from Redux changes (or on mount), load its data into local state.
  useEffect(() => {
    if (edge && edge.data) {
      setName(edge.data.name || '');
      setType(edge.data.type || '');
      setLabel(edge.data.label || '');
      setIcon(edge.data.icon || '');
      setFields(edge.data.fields || []);
      setTestData(edge.data.testData || null);

      // Parse the filter rule from fields[1] if it exists.
      // We expect fields[1].value to be an array where the first element is an array of AND rules
      // and subsequent elements (if any) are OR groups.
      const ruleValue = edge.data.fields?.[1]?.value;
      if (
        Array.isArray(ruleValue) &&
        ruleValue.length > 0 &&
        Array.isArray(ruleValue[0]) &&
        ruleValue[0].length > 0
      ) {
        setAndFilters(ruleValue[0]);
        setOrGroups(ruleValue.slice(1));
      } else {
        // Otherwise, initialize with a default AND rule
        setAndFilters([{ lhs: '', conditionType: '', rhs: '' }]);
        setOrGroups([]);
      }
    }
  }, [edge]);

  // Helper function to auto generate a filter name
  function extractFieldName(lhs) {
    const regex = /\$\{([^}]+)\}/;
    const match = lhs.match(regex);
    if (match) {
      const parts = match[1].split('.');
      return parts[parts.length - 1]; // Return the last part, e.g. "date"
    }
    return lhs;
  }
  const generateFilterName = () => {
    

    let parts = [];

    // Build string for AND filters
    if (andFilters.length > 0) {
      const andPart = andFilters
        .map(filter => {
          const lhsDisplay = filter.lhs && filter.lhs.includes('${')
          ? extractFieldName(filter.lhs)
          : filter.lhs || "";
          return `${lhsDisplay}`.trim();
        })
        .filter(s => s !== "")
        .join(" AND ");
      if (andPart) {
        parts.push(andPart);
      }
    }

    // Build string for each OR group
    if (orGroups.length > 0) {
      orGroups.forEach(group => {
        const groupPart = group
          .map(filter => {
            const lhsDisplay = filter.lhs && filter.lhs.includes('${')
          ? extractFieldName(filter.lhs)
          : filter.lhs || "";
            return `${lhsDisplay}`.trim();
          })
          .filter(s => s !== "")
          .join(" AND ");
        if (groupPart) {
          parts.push(`(${groupPart})`);
        }
      });
    }

    if (parts.length > 0) {
      return "Filter: " + parts.join(" OR ");
    }
    return "Filter";
  };

  // Handlers for AND filters
  const handleAddAndFilter = () => {
    setAndFilters([...andFilters, { lhs: '', conditionType: '', rhs: '' }]);
  };

  // Handler for adding an OR group
  const handleAddOrGroup = () => {
    setOrGroups([...orGroups, [{ lhs: '', conditionType: '', rhs: '' }]]);
  };

  // Handler for adding another filter to an existing OR group
  const handleAddFilterToOrGroup = (groupIndex) => {
    const updatedOrGroups = orGroups.map((orGroup, i) =>
      i === groupIndex ? [...orGroup, { lhs: '', conditionType: '', rhs: '' }] : orGroup
    );
    setOrGroups(updatedOrGroups);
  };

  // Handler for filter changes; works for both "and" and "or" groups.
  // For "and", group is the string 'and'; for OR groups, group is the numeric index.
  const handleFilterChange = (group, index, key, value) => {
    if (group === 'and') {
      const updatedAndFilters = andFilters.map((filter, i) =>
        i === index ? { ...filter, [key]: value } : filter
      );
      setAndFilters(updatedAndFilters);
    } else {
      const updatedOrGroups = orGroups.map((orGroup, gIndex) =>
        gIndex === group
          ? orGroup.map((filter, i) =>
              i === index ? { ...filter, [key]: value } : filter
            )
          : orGroup
      );
      setOrGroups(updatedOrGroups);
    }
  };

  // Handler to delete a filter rule from AND or OR groups
  const handleDeleteFilter = (group, index) => {
    if (group === 'and') {
      const updatedAndFilters = andFilters.filter((_, i) => i !== index);
      setAndFilters(updatedAndFilters);
    } else {
      const updatedOrGroups = orGroups
        .map((orGroup, gIndex) =>
          gIndex === group ? orGroup.filter((_, i) => i !== index) : orGroup
        )
        .filter((orGroup) => orGroup.length > 0);
      setOrGroups(updatedOrGroups);
    }
  };

  // Save button handler – combine AND and OR rules into the second field’s value,
  // auto-generate a name, and dispatch update.
  const handleSave = () => {
    if (delayOnly) {
      // For delay-only, update only fields[2] (assuming that's the delay field)
      const updatedDelayField = {
        ...fields[2],
        // (You might want to update its value here if needed)
      };
      const newFields = [fields[0], fields[1], updatedDelayField];
      const updatedData = { name, type, label, icon, fields: newFields, testData };
      dispatch(elementUpdated(selectedEdge.id, updatedData, null));
    } else {
    const combinedRules = [andFilters, ...orGroups];
    const updatedRuleField = {
      ...fields[1],
      value: combinedRules
    };

    // Auto-generate filter name
    const autoName = generateFilterName();
    // Reconstruct the complete fields array (assuming fields[0] and fields[2] remain unchanged)
    const newFields = [fields[0], updatedRuleField, fields[2]];

    const updatedData = {
      name: autoName === 'Filter' ? null : autoName,
      type,
      label,
      icon,
      fields: newFields,
      testData
    };
    dispatch(elementUpdated(selectedEdge.id, updatedData, null));
  }
    onPaneClick();
  };
// If delayOnly is true, render only the delay field (fields[2])
if (delayOnly) {
  return (
    <div className={styles.Panel} style={{ paddingBottom: '60px', cursor: 'default' }}>
      <Box className={classes.container} style={{ backgroundColor: 'white', marginTop: '80px' }}>
        <WorkflowWidget
          value={fields[2]?.value || ''}
          onChange={(val) => {
            // Update only the delay field value in the fields array.
            const updatedFields = [...fields];
            updatedFields[2] = {
              ...updatedFields[2],
              value: val
            };
            setFields(updatedFields);
          }}
          // Use the delay field's schema if it exists; otherwise provide a default.
          schema={fields[2]?.schema || { type: 'string', title: 'Delay Time (seconds)' }}
        />
      </Box>
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={handleSave} className={classes.button}>
          Save delay
        </Button>
      </Box>
    </div>
  );
}

  return (
    <>
      <div className={styles.Panel} style={{ paddingBottom: '60px', cursor: 'default' }}>
        <Box className={classes.container} style={{ backgroundColor: 'white' }}>
          {/* Render AND filters */}
          {andFilters.map((filter, index) => (
            <Box key={index} mb={2} className={classes.container}>
              <Box className={classes.filterHeader}>
                <Typography variant="subtitle2">
                  {index === 0 ? "Only continue if" : "And"}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Button
                    onClick={() => handleDeleteFilter('and', index)}
                    size="small"
                    variant="text"
                    style={{ textTransform: 'none' }}
                  >
                    <DeleteIcon fontSize="small" color="primary" />
                    <Typography color="primary" variant="body2">
                      Remove rule
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <WorkflowWidget
                value={filter.lhs}
                onChange={(val) => handleFilterChange('and', index, 'lhs', val)}
                schema={{
                  type: 'string',
                  title: 'Choose Field...'
                }}
              />
              <TextField
                select
                label="Choose condition..."
                value={filter.conditionType}
                onChange={(e) => handleFilterChange('and', index, 'conditionType', e.target.value)}
                variant="outlined"
                className={classes.selectField}
              >
                <MenuItem value="stringEqual">(Text/Number) Equals</MenuItem>
                <MenuItem value="stringUnequal">(Text/Number) Does not equal</MenuItem>
                <MenuItem value="listContains">(Text) Contains</MenuItem>
                <MenuItem value="listNotContain">(Text) Does not contain</MenuItem>
                <MenuItem value="greaterThan">(Number) Greater than</MenuItem>
                <MenuItem value="lessThan">(Number) Less than</MenuItem>
                <MenuItem value="isTrue">(Boolean) Is true</MenuItem>
                <MenuItem value="isFalse">(Boolean) Is false</MenuItem>
                <MenuItem value="valueExists">Exists</MenuItem>
                <MenuItem value="valueNotExists">Does not exist</MenuItem>
              </TextField>
              <WorkflowWidget
                value={filter.rhs}
                onChange={(val) => handleFilterChange('and', index, 'rhs', val)}
                schema={{
                  type: 'string',
                  title: 'Enter/select value...'
                }}
              />
            </Box>
          ))}
          <Button variant="outlined" color="primary" onClick={handleAddAndFilter} className={classes.button}>
            + And
          </Button>
        </Box>

        <Divider className={classes.divider} light={true} variant="fullWidth" />

        {/* Render OR groups */}
        {orGroups.map((orGroup, groupIndex) => (
          <Box
            key={groupIndex}
            className={classes.container}
            style={{ backgroundColor: groupIndex % 2 === 0 ? '#f9f9f9' : 'white' }}
          >
            {orGroup.map((filter, index) => (
              <Box key={index} mb={2} className={classes.container}>
                <Box className={classes.filterHeader}>
                  <Typography variant="subtitle2">
                    {index === 0 ? "Or continue if" : "And"}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Button
                      onClick={() => handleDeleteFilter(groupIndex, index)}
                      size="small"
                      variant="text"
                      style={{ textTransform: 'none' }}
                    >
                      <DeleteIcon fontSize="small" color="primary" />
                      <Typography color="primary" variant="body2">
                        Remove rule
                      </Typography>
                    </Button>
                  </Box>
                </Box>
                <WorkflowWidget
                  select
                  value={filter.lhs}
                  onChange={(val) => handleFilterChange(groupIndex, index, 'lhs', val)}
                  schema={{ type: 'string', title: 'Choose Field...' }}
                />
                <TextField
                  select
                  label="Choose condition..."
                  value={filter.conditionType}
                  onChange={(e) => handleFilterChange(groupIndex, index, 'conditionType', e.target.value)}
                  variant="outlined"
                  className={classes.selectField}
                >
                  <MenuItem value="stringEqual">(Text/Number) Equals</MenuItem>
                  <MenuItem value="stringUnequal">(Text/Number) Does not equal</MenuItem>
                  <MenuItem value="listContains">(Text) Contains</MenuItem>
                  <MenuItem value="listNotContain">(Text) Does not contain</MenuItem>
                  <MenuItem value="greaterThan">(Number) Greater than</MenuItem>
                  <MenuItem value="lessThan">(Number) Less than</MenuItem>
                  <MenuItem value="isTrue">(Boolean) Is true</MenuItem>
                  <MenuItem value="isFalse">(Boolean) Is false</MenuItem>
                  <MenuItem value="valueExists">Exists</MenuItem>
                  <MenuItem value="valueNotExists">Does not exist</MenuItem>
                </TextField>
                <WorkflowWidget
                  value={filter.rhs}
                  onChange={(val) => handleFilterChange(groupIndex, index, 'rhs', val)}
                  schema={{ type: 'string', title: 'Enter/select value...' }}
                />
              </Box>
            ))}
            <Button variant="outlined" color="primary" onClick={() => handleAddFilterToOrGroup(groupIndex)} className={classes.button}>
              + And
            </Button>
          </Box>
        ))}
        <Button variant="outlined" color="primary" onClick={handleAddOrGroup} className={classes.button}>
          + Add "Or" rule group
        </Button>

        {/* Save button */}
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleSave} className={classes.button}>
            Save filter
          </Button>
        </Box>
      </div>
    </>
  );
}

export default FilterPanel;
