import React, { useEffect, useState, useRef, useMemo} from 'react';
import {
  BrowserRouter,
  useLocation, useHistory
} from "react-router-dom";
import mixpanel from 'mixpanel-browser';
import axios from 'axios';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ContactSupportRoundedIcon from '@material-ui/icons/ContactSupportRounded';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import CloudDoneOutlinedIcon from '@material-ui/icons/CloudDoneOutlined';
import CloudOutlinedIcon from '@material-ui/icons/CloudOutlined';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';
import OndemandVideoRoundedIcon from '@material-ui/icons/OndemandVideoRounded';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import LooksOneRoundedIcon from '@material-ui/icons/LooksOneRounded';
import LooksTwoRoundedIcon from '@material-ui/icons/LooksTwoRounded';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import VerifiedUserRoundedIcon from '@material-ui/icons/VerifiedUserRounded';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import Badge from '@material-ui/core/Badge';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import { Typography,Button, Tooltip, Menu,Popper, MenuItem,ListItem, ListItemIcon, ListItemText, StepContent, Modal, Avatar, TextField, OutlinedInput, Divider, InputAdornment, InputLabel, FormHelperText } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import MuiAlert from '@material-ui/lab/Alert';
import {
  selectWorkflowActive, selectWorkflowName, notification, selectActiveEnabled, activeEnabled, selectWorkflowElements, elementUpdated, elementSelected, selectElementById,
  nameUpdated, activeUpdated, saveWorkflow, createTrigger, deleteTrigger
} from '../features/workflow/workflowSlice';
import {
  tokenUpdated
} from '../features/externalState/externalSlice'

const modalheight = window.innerHeight;
const modalwidth = window.innerWidth;
function getModalStyle() {
  const top = 5
  //const left = 50 + rand();

  return {
      top: `${top}%`,
      margin: 'auto'
    //  left: `${left}%`,
    //  transform: `translate(-${top}%, -${left}%)`,
  };
}

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.default,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.default,
      },
    },
  },
}))(MenuItem);

const GreenSwitch = withStyles({
  switchBase: {
    color: '#ffffff',
    '&$checked': {
      color: '#4caf50',
    },
    '&$checked + $track': {
      backgroundColor: '#4caf50',
    },
  },
  checked: {},
  track: {},
})(Switch);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: '5px',


  },
  right: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '2px',
    float: 'right'

  },
  shareright: {
  
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'right',
    marginLeft: '2px',
    float: 'right',
    right: 0,
    position: 'absolute',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2)

  },
  modalshare: {
    position: 'absolute',
    width: '80%',
    height: modalheight * .75,
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    borderRadius: '4px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: 'scroll'
},
modalsharesuccess: {
  position: 'absolute',
  width: '80%',
  height: modalheight * .3,
  backgroundColor: theme.palette.background.paper,
  border: '0px solid #000',
  borderRadius: '4px',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'scroll'
},
  title: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(4)
  },
  marginSmall: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2)
  },
  switch: {
    backgroundColor: 'gray',
    border: '1px solid',
    borderRadius: '40px',
    width: '100px',
    marginLeft: theme.spacing(15),
    alignItems: 'center',
    float: 'right',
    display: 'flex-end',
    right: 0,
    position: 'absolute',
    marginRight: theme.spacing(10)
  },
  switchSmall: {
    backgroundColor: 'gray',
    border: '1px solid',
    borderRadius: '40px',
    width: '100px',
    marginLeft: theme.spacing(15),
    alignItems: 'center',
    float: 'right',
    display: 'flex-end',
    bottom: theme.spacing(4),
    right: theme.spacing(0),
    position: 'fixed' ,
    marginRight: theme.spacing(2),
    zIndex: 9 
  },
  help: {
    marginLeft: theme.spacing(2),
    float: 'right',
    display: 'flex-end',
    right: 0,
    alignItems: 'center',
    //position: 'absolute',
    marginRight: theme.spacing(2)

  },
  input: {
    padding: '0px 20px 0px 0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    border: 'none',
   
    fontSize: '18px',
    '&:focus': { 
      outline: 'none',
      borderBottom: '2px solid black'
      }

  },
  helpSmall: {
    marginLeft: theme.spacing(2),
    float: 'right',
    display: 'flex-end',
    right: 0,
    alignItems: 'center',
    //position: 'absolute',
    marginRight: theme.spacing(1)
  },
  avatarlarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  undoRedo: {
    marginLeft: theme.spacing(1),
    cursor: 'pointner'
  },
  pulse: {
    animation: '$pulse 1s ease-in-out infinite',
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
      opacity: 1,
    },
    '50%': {
      transform: 'scale(1.1)',
      opacity: 0.7,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 1,
    },
  },
}));



export const AppBarHeader = ({
  sendLoggedOutToAuthorizer, breakpoint, selectedElement
}) => {
  //console.log('selected elemt: ', selectedElement)
  const classes = useStyles();
  const dispatch = useDispatch();
  //console.log('isactive: ', isactive);
  // const getactive = useSelector((state) => state.active.active);
  // const existingwf = useSelector((state) => state.existingwf.existingwf);
  
  // const getwfname = useSelector((state) => state.wfname.wfname);
  // const djangoid = useSelector((state) => state.djangoid.djangoid);
  // const tokenid = useSelector((state) => state.tokenid.tokenid);
  
  const [cookie, setCookie] = useState();
  const [modalStyle] = React.useState(getModalStyle);
  const active = useSelector(selectWorkflowActive)
  const active_enabled = useSelector(selectActiveEnabled)
 // console.log('active enabled: ', active_enabled)
  const name = useSelector(selectWorkflowName)
  const [wfname, setWFName] = useState(name ?? 'Untitled Workflow');
  const [alert, setAlert] = useState({
    "status": "info",
    "message": "Firing up the engines. Please wait..."
});
const [openalert, setOpenAlert] = React.useState(false);


  useEffect(() => {
    if (cookie) {
      const queryParams = new URLSearchParams(location.search)
      queryParams.delete('c')
        history.replace({
          search: queryParams.toString(),
        })

    } else {
      const queryParams = new URLSearchParams(location.search)
      if (queryParams.has('c')) {
        const token = query.get("c");
      //  console.log(token)
        localStorage.setItem('tok_', token)
        setCookie(token);
        dispatch(tokenUpdated(token))
        // console.log('from token, cookie is, ', cookie)
        queryParams.delete('c')
        history.replace({
          search: queryParams.toString(),
        })
      }
      else {
       // console.log('already ran once, removed \'c\'')
       const token = localStorage.getItem('tok_')
       setCookie(token);
       dispatch(tokenUpdated(token))

      }
    }
  }, [cookie])


  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const id = query.get("id")
  const user = query.get("u");
  const tenant = query.get("t");


  const location = useLocation()
  const history = useHistory()


  // useEffect(() => {
  //   if (cookie) {
  //     //console.log('cookie from Useffect is, ', cookie)
  //     //console.log('cookie length is ', cookie.length)
  //     dispatch(tokenId(cookie))
  //   }
  // }, [cookie])

  

     useEffect(() => {
     console.log('getactive is '+active);
   }, [active]) 

   useEffect(() => {
    if (alert) {
    //    console.log('alert is: ', alert)
        handleClickAlert();
    }
}, [alert])
 //  useEffect(() => {
 //    if (wfname.length > 0) {
  //     console.log('useEffect changed wfname ', wfname);
 //      setWFName(wfname);
 //    }
 //  }, [wfname])

  // const handleWFSubmit = event => {
  //   event.preventDefault();
  //   dispatch(wfName(wfname))
  //   setWFName('');

  //   // console.log('appBar log: ' + wfname)
  // };

  const handleWFSubmitKeyDown = event => {
    setWFName(null)
    if (event.key === 'Enter') {
      event.preventDefault();
      // dispatch(wfName(wfname))
       setWFName(event.target.value);
      dispatch(nameUpdated(event.target.value))

      event.target.blur();
      dispatch(notification({ "status": "success", "message": "Workflow name updated: "+event.target.value }))
      // console.log('appBar log from Enter key: ' + wfname)
    }

  };

  // const w3 = {
  //   active: !getactive
  // };


  // const w2 = {
  //   name: getwfname
  // };
  const elements = useSelector(selectWorkflowElements)
  
  const [iconsfiltered, setIconsFiltered] = useState()
  const triggerNode = elements ? elements.filter(x => x.type === 'trigger') : null
  const node = useSelector(selectElementById(triggerNode[0]?.id))
 // console.log('what is the triger node? ', triggerNode[0])
  const [triggerMessage, setTriggerMessage ] = useState(triggerNode[0] ? triggerNode[0].data.triggerMessage : null)

  useEffect(() => {
    let isMounted = true
      //console.log('elements are: ', elements);
      if (isMounted) {
      const test_data_filtered = elements.filter(x => x.type !== 'filter')
      const test_data = test_data_filtered.map((item) => item.data.testData)
      const test_data_boolean = test_data.filter(x => x === "" || undefined)
      const test_data_boolean_exists = test_data_filtered.some(e => e.data.testData === undefined || "")
      const icons = elements.map((item) => item.data.icon);
        const icons_filtered = icons.filter(x => x !== undefined);
        setIconsFiltered(icons_filtered)
   // console.log('test_data_boolean is: ', test_data_boolean.length)
    
    const needsCredentials = test_data_filtered.filter(x => x.data.credential === undefined && x.data.hasauth === true)
    //console.log('test data filtered: ', needsCredentials.length)
   // console.log('test data boolean exists: ', test_data_boolean_exists)
    if (test_data_boolean.length > 0 || test_data_boolean_exists === true || needsCredentials.length > 0) {
      

      dispatch(activeEnabled(false)); 
     // handleScaffoldUpdate(); 
     // handleSaveClick();
      


    } 
    else {

      dispatch(activeEnabled(true)); 
     
      
    }
  }
  return () => { isMounted = false };
    }, [elements]) 
 

  const closeFrame = () => {
    if (user){
      if (!name){
        dispatch(nameUpdated('Untitled Workflow (Draft)'))
        const icons = elements.map((item) => item.data.icon);
        const icons_filtered = icons.filter(x => x !== undefined);
     //   console.log(name)
   // console.log(active)
  //  console.log(cookie)
   // console.log(tenant)
    var data = {
      "operation": "updateScaffold",
      "data": {
        "name": name,
        "active": active ? active : false,
        "icons": icons_filtered,
        "cookie": cookie,
        "tenant": tenant,
        "id": id
      }
    };
    // console.log('data send to scaffold ', data)
    var config = {
      method: 'post',
      url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
      data: data,
      headers: {
        'Content-Type': 'application/json'
      }
    };
    axios(config)
      .then(function (response) {
        //  console.log('response from Scaffold '+JSON.stringify(response.data));
        window.location.href = "https://automate.workload.co/workflow?authToken="+cookie
      })
      .catch(function (error) {
        // console.log(error);
      });
       
      } else {
        //console.log(name)
       // console.log('workflow info: ', elements)
        const icons = elements.map((item) => item.data.icon);
        const icons_filtered = icons.filter(x => x !== undefined);
        //console.log('icon items are: ', icons_filtered)
        
    //console.log(active)
   // console.log(cookie)
    //console.log(tenant)
    var data2 = {
      "operation": "updateScaffold",
      "data": {
        "name": name,
        "active": active ? active : false,
        "icons": icons_filtered,
        "cookie": cookie,
        "tenant": tenant,
        "id": id
      }
    };
    // console.log('data send to scaffold ', data)
    var config2 = {
      method: 'post',
      url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
      data: data2,
      headers: {
        'Content-Type': 'application/json'
      }
    };
    axios(config2)
      .then(function (response) {
        //  console.log('response from Scaffold '+JSON.stringify(response.data));
        window.location.href = "https://automate.workload.co/workflow?authToken="+cookie;
      })
      .catch(function (error) {
        console.log(error);
        window.location.href = "https://automate.workload.co/workflow?authToken="+cookie
      });
        
      }
      
    } else {

    // let url =
    //  window.location != window.parent.location
    //    ? document.referrer
    //    : document.location.href;
    //    window.location.href = url;
    window.location.href = "https://automate.workload.co/workflow?authToken="+cookie;
    }
  };

  //const handleDjangoNameUpdate = () => {
  //  console.log('code reached handleDjangonameupdate')
  // const workflow = saveWorkflow();
  //  Object.assign(workflow, w2, w3);
  // console.log('Send this to django: ', workflow);
  // var data = {
  //  "operation": "updateWorkflow",
  //  "id": djangoid,
  //  "data": workflow
  //};
  /* var config = {
    method: 'post',
    url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
    data: data,
    headers: { 
      'Content-Type': 'application/json'
    }
  };
  axios(config)
  .then(function (response) {
    console.log('response from Djangocaller '+JSON.stringify(response.data));
    handleScaffoldUpdate()
},)
  .catch(function (error) {
    console.log(error);
  });
  } */

  const handleScaffoldUpdate = () => {
   // console.log(name)
  //  console.log(active)
   // console.log(cookie)
   // console.log(tenant)
  // console.log('is it coming from here??')
    var data = {
      "operation": "updateScaffold",
      "data": {
        "name": name,
        "active": !active,
        "cookie": cookie,
        "tenant": tenant,
        "icons": iconsfiltered,
        "id": id
      }
    };
    // console.log('data send to scaffold ', data)
    var config = {
      method: 'post',
      url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
      data: data,
      headers: {
        'Content-Type': 'application/json'
      }
    };
    axios(config)
      .then(function (response) {
       //   console.log('response from Scaffold '+JSON.stringify(response.data));

      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const handleSaveClick = () => {
    console.log('save clicked!!!')
    dispatch(saveWorkflow(1))
}

const saveNode = () => {
   console.log('saving node')
  // console.log(node)
  dispatch(elementUpdated(triggerNode.id,
      {...triggerNode, triggerMessage: triggerMessage},
      null
  ));
}

const handleSwitchChange = () => {
  dispatch(activeUpdated(!active)); 
  handleScaffoldUpdate(); 
  handleSaveClick();
  console.log('do we have a perform list? ', Boolean(node.data.performList))
  console.log('what is it? ', node.data.performList)

  if (!active === true && node.data.performList) {
    dispatch(createTrigger(1)).then((response)=>{
     // console.log('createTriggerResponse: ', response);
      if (response.error) {
     //    console.log('there was an error!!!')
         dispatch(activeUpdated(!active)); 
         dispatch(notification({ "status": "error", "message": "Error: There was a problem activating the workflow." }))
        
      } else {
        setTriggerMessage(response.payload.triggerMessage)
        console.log('handleSwitchSchange')
        saveNode()
        handleSaveClick()

      }
});
  } 
  if (!active === false) {
   // console.log('we are now deleting the webhook if it exists...')
    dispatch(deleteTrigger(1)).then((response)=>{
    //  console.log('deleteTriggerResponse: ', response);
      if (response.error) {
      //   console.log('there was an error!!!')
         dispatch(activeUpdated(!active)); 
         dispatch(notification({ "status": "error", "message": "Error: There was a problem pausing the workflow." }))
        
      } else {
        setTriggerMessage()
        console.log('Savedd')
        saveNode()
        handleSaveClick()

      }
});

  }
  !active === true ? dispatch(notification({ "status": "success", "message": "Workflow status: ACTIVE" })): dispatch(notification({ "status": "warning", "message": "Workflow status: PAUSED" }));
}
const handleClickAlert = () => {
  setOpenAlert(true);
};

const handleCloseAlert = (event, reason) => {
  if (reason === 'clickaway') {
      return;
  }
  setOpenAlert(false);
};

const helpClick = () => {
  window.open("https://www.workload.co/help");
  handleHelpClose();
};

const supportClick = () => {
  window.open("https://www.workload.co/docs/get-help/");
  handleHelpClose();
};

const videoClick = () => {
  window.open("https://www.youtube.com/channel/UC_ZGfDbvjlfE0Pi2S7tgFWg/featured");
  handleHelpClose();
};

const [anchorEl, setAnchorEl] = React.useState(null);

  const handleHelpClick = (event) => {
    setAnchorEl(event.currentTarget);
    mixpanel.track('AppBar Help Clicked', {
    });
  };

  const handleHelpClose = () => {
    setAnchorEl(null);
  };
  const [content, setContent] = useState(name);
  const [width, setWidth] = useState(0);
  const [modalopenshare, setModalOpenShare] = useState(false)
  const [modalopensharesuccess, setModalOpenShareSuccess] = useState(false)
  const span = useRef();

  useEffect(() => {
    setWidth(span.current.offsetWidth+100);
    console.log('appbar title width: ', width)
  }, [content]);

  const handleShareClick = () => {
    mixpanel.track('Share Click', {
      title: name,
      url: 'https://editor.workload.co/?u='+user+'&t='+tenant+'&id='+id
    });
    setModalOpenShare(true)
   
  }

  const handleModalCloseShare = () => {
    setModalOpenShare(false);
};

const handleModalCloseShareSuccess = () => {
  setModalOpenShareSuccess(false);
};

const [sharedtitle, setSharedTitle] = useState()
const [shareddescription, setSharedDescription] = useState()

const handleUndo = () => {
  dispatch({ type: 'UNDO' });
  handleSaveClick()

};
const handleRedo = () => {
  dispatch({ type: 'REDO' });
};

 // Select the workflow loading state from Redux
 const loading = useSelector((state) => state.workflow.present.loading);
  
 // Local state to control cloud icon animation
 const [isSaving, setIsSaving] = useState(false);
 const [tooltipOpen, setTooltipOpen] = useState(false);

 // Watch the Redux loading state for "saving" or "saved" changes.
 useEffect(() => {
   if (loading === 'saving') {
     setIsSaving(true);
   } else if (loading === 'saved') {
     // Stop animation after a delay (e.g., 1s) so the user sees the animation finish
     const timer = setTimeout(() => {
       setIsSaving(false);
     }, 1000);
     return () => clearTimeout(timer);
   }
 }, [loading]);

 useEffect(() => {
  if (loading === 'saving') {
    setTooltipOpen(true);
  } else if (loading === 'saved') {
    // Stop animation after a delay (e.g., 1s) so the user sees the animation finish
    const timer = setTimeout(() => {
      setTooltipOpen(false);
    }, 3000);
    return () => clearTimeout(timer);
  }
}, [loading]);

// Get the undo history from the undoable reducer.
const pastActions = useSelector((state) => state.workflow.past);
// Local state to control the anchor element for the Undo dropdown.

const [anchorElUndo, setAnchorElUndo] = useState(null);

// Issues notification status icon code:::

const [anchorElNotifications, setAnchorElNotifications] = useState(null);

// Compute issues as an array of objects
const issues = useMemo(() => {
  const issuesList = [];
  elements.forEach((el) => {
    if (el.type === 'trigger' || el.type === 'action') {
    const stepName = el.data.name || el.id;
    const icon = el.data.icon || ''; // provide a fallback if needed
    // Example: Check for missing app selection
    if (!el.data.type || el.data.type === '' || el.data.type === 'Click to Add Trigger') {
      issuesList.push({
        id: el.id,
        icon: icon,
        stepName,
        issueText: 'Missing app selection.',
      });
    }
    // Missing event (for action nodes)
    if (el.type === 'action' && (!el.data.action || el.data.action === '')) {
      issuesList.push({
        id: el.id,
        icon: icon,
        stepName,
        issueText: 'Missing event.',
      });
    }
    // Missing credential if required
    if (el.data.hasauth && (!el.data.credential || el.data.credential === '')) {
      issuesList.push({
        id: el.id,
        icon: icon,
        stepName,
        issueText: 'Missing credential.',
      });
    }
    // Check required fields
    if (Array.isArray(el.data.fields)) {
      el.data.fields.forEach((field) => {
        if (field.required && (!field.value || field.value === '')) {
          issuesList.push({
            id: el.id,
            icon: icon,
            stepName,
            issueText: `Missing field "${field.name}".`,
          });
        }
      });
    }
    // Missing testData or testData error
    if (!el.data.testData || el.data.testData === '') {
      issuesList.push({
        id: el.id,
        icon: icon,
        stepName,
        issueText: 'Missing test data.',
      });
    } else if (typeof el.data.testData === 'object' && el.data.testData.error) {
      issuesList.push({
        id: el.id,
        icon: icon,
        stepName,
        issueText: 'Test data error.',
      });
    }
  }
  });
  return issuesList;
}, [elements]);


  const body_share = (
    <div style={modalStyle} className={classes.modalshare}>
      <Alert severity="info" style={{ margin: 'auto', justifyContent: 'center', backgroundColor: 'black' }} variant="filled"><Typography style={{fontSize: '18px'}} id="simple-modal-title">Customize your shared workflow template</Typography></Alert><br/><br/>
      <div id='share_container' style={{marginLeft: '10%', marginRight: '10%'}}>
      <AvatarGroup style={{marginBottom: '16px', marginTop: '20px'}} max={20}>
        {iconsfiltered?.map((item) =>
        <Avatar src={item} className={classes.avatarlarge}/>)}
      </AvatarGroup>
      <MuiAlert icon={<LooksOneRoundedIcon fontSize="36px" style={{color: '#0069ea'}}  />} style={{ margin: 'auto', backgroundColor: 'whitesmoke', }} ><Typography style={{fontSize: '18px', color: 'black'}} id="simple-modal-title">Explain what this workflow does.</Typography></MuiAlert><br/><br/>

      <TextField fullWidth  id="workflow_title" variant="outlined" label="Workflow title" defaultValue={name} helperText={"Example of a GREAT title:\n\r\"Get Slack notifications for new Mailchimp subscribers\""} onChange={(e) => setSharedTitle(e.target.value)} /><br /><br />

      <MuiAlert icon={<LooksTwoRoundedIcon fontSize="36px" style={{color: '#0069ea'}} />} style={{ margin: 'auto', backgroundColor: 'whitesmoke', }} ><Typography style={{fontSize: '18px', color: 'black'}} id="simple-modal-title">Describe how it works.</Typography></MuiAlert><br/><br/>

      <TextField multiline={true} minRows={10} rows={10} fullWidth  id="workflow_description" variant="outlined" label="Workflow description" helperText={"Example of a GREAT description:\n\r\"Keep track of your new Mailchimp subscribers in near real-time by setting up this Mailchimp to Slack integration. Once set up, Workload will notify you in Slack every time someone new subscribes to your Mailchimp list.\""} onChange={(e) => setSharedDescription(e.target.value)} /><br /><br />

      {/* <div style={{width:'150px', margin: 'auto', justifyContent: 'center'}} onClick={()=> {handleModalCloseShare();}}><Typography style={{ fontSize: '18px', color: 'black', textAlign: 'center', padding: '25px 15px 15px 15px', cursor: 'pointer', textDecoration: 'underline' }} id="simple-modal-subtitle">Preview</Typography></div> */}
      <Button style={{boxShadow: '2px 2px 5px #303030', display: 'flex', justfiyContent: 'center', alignItems: 'center', padding: '10px', height: '50px', width: '200px', backgroundColor: '#0069ea', borderRadius: '4px', border: 'none', cursor: 'pointer', margin: 'auto' }} onClick={()=> {handleModalCloseShare(); setModalOpenShareSuccess(true)}}><div><Typography style={{ alignText: 'center', fontWeight: 'bold', color: 'white', float: 'left', display: 'block' }}>Share</Typography></div></Button> <br/>
       
        
      </div>
       
        

        
    </div>
);

const body_share_success = (
  <div style={modalStyle} className={classes.modalsharesuccess}>
    <Alert severity="success" style={{ margin: 'auto', justifyContent: 'center' }} variant="filled"><Typography style={{fontSize: '18px'}} id="simple-modal-title">You've shared your workflow template!</Typography></Alert><br/><br/>
    <div id='share_container' style={{marginLeft: '10%', marginRight: '10%'}}>
    
    <MuiAlert icon={<VerifiedUserRoundedIcon fontSize="36px" />} severity="success" style={{ margin: 'auto', backgroundColor: 'whitesmoke', }} ><Typography style={{fontSize: '18px', color: 'dimgray'}} id="simple-modal-title">Sensitive information like account connections, data, and execution logs <strong>are not shared.</strong></Typography></MuiAlert><br/><br/>

    <InputLabel htmlFor="outlined-adornment-password"><Typography variant="h6" style={{ color: 'black' }}><strong>Workflow URL</strong></Typography></InputLabel>
      <FormHelperText id={"descriptionntext"}><Typography variant="body2">Copy this workflow URL and share it with colleagues.</Typography> </FormHelperText>
      <br />
      <OutlinedInput
          disabled
          style={{ width: '100%', color: 'black' }}
          id={'workflow-copy'}
          key={'workflow-copy'}
          label={'workflow URL'}
          defaultValue={'https://editor.workload.co/?template_id='+id}
          helperText={'Copy this URL and share it with colleagues and co-workers.'}
          variant="outlined"
          endAdornment={
              <InputAdornment position="end" className={classes.icon}>
                  <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                          navigator.clipboard.writeText('https://editor.workload.co/?template_id='+id);  mixpanel.track('Share Click - Success', {
                            title: sharedtitle,
                            description: shareddescription,
                            url: 'https://editor.workload.co/?template_id='+id
                          });
                          dispatch(notification({ "status": "info", "message": "Copied to clipboard" }))
                      }}

                      edge="end"
                  >
                      <Divider orientation="vertical" flexItem style={{ marginRight: '8px' }} />
                      <FileCopyOutlinedIcon />

                  </IconButton>
                  <Typography style={{ marginTop: '40px', marginLeft: '-25px' }} variant="caption" display="block" gutterBottom>
                      copy
                  </Typography>
              </InputAdornment>
          }
          labelWidth={70}
      />

    
    
      
    </div>
     
      

      
  </div>
);

  return (
    <div className={classes.root} >
      <AppBar position="fixed" style={{ background: 'white', zIndex: 99 }}>
        <Toolbar>
        <Tooltip title={<Typography style={{fontSize: '16px'}}>Exit to dashboard</Typography>} placement="top">
          <IconButton onClick={() => closeFrame()} edge="start" className={classes.menuButton} color="default" aria-label="menu">
            <ChevronLeftRoundedIcon />
            <Avatar size={breakpoint !== 'large' ? '20px' : 'large'} style={{marginLeft: breakpoint !== 'large' ? '5px' : '15px',width: breakpoint !== 'large' ? '30px' : null, height: breakpoint !== 'large' ? '30px' : null}} alt="Workload Canvas Editor" src="https://cdn.workload.co/wp-content/uploads/new-logo-only-512-512.png" />
          </IconButton></Tooltip>

          <Tooltip
              title={
                <Typography style={{ fontSize: '10px' }}>
                  {isSaving ? 'Saving changes...' : 'All changes saved'}
                </Typography>
              }
              placement="top"
              open={tooltipOpen}  // force open while saving
            >
            {isSaving ? (
              // Cloud icon without checkmark, pulsing
              
              <CloudOutlinedIcon
                className={classes.pulse}
                style={{ color: 'grey', fontSize: breakpoint !== 'large' ? '20px' : undefined }}
              />
             
            ) : (
              // Cloud icon with check mark (final state)
              <CloudDoneOutlinedIcon
                style={{ color: 'grey', fontSize: breakpoint !== 'large' ? '20px' : undefined }}
              />
            )}
          </Tooltip>

          <FormControl className={breakpoint !== 'large' ? classes.marginSmall : classes.margin}>
          <span id="hide" style={{position: 'absolute', opacity: 0, zIndex: -100, whiteSpace: 'pre'}} ref={span}>{content}</span>
            <input
             className={classes.input}
            
              id="input-with-icon-adornment" type="WFName" placeholder="Name your workflow" defaultValue={ name } key={name} onChange={(e) => setContent(e.target.value)} onKeyDown={handleWFSubmitKeyDown} onBlur={(e) => { dispatch(nameUpdated(e.target.value)); handleScaffoldUpdate();setWFName(e.target.value); handleSaveClick(); dispatch(notification({ "status": "success", "message": "Workflow name updated: "+e.target.value }))}} 
              style={{ minWidth: breakpoint !== 'large' ? '150px' : '275px', maxWidth: breakpoint !== 'large' ? '250px' : '450px', width, fontWeight: 700 }}
              
            />
           
          </FormControl>
         { breakpoint !== 'large' ? null : <CreateRoundedIcon style={{marginLeft: '-25px',zIndex: 100, color: 'silver',fontSize: breakpoint !== 'large' ? '20px' : '20px' }} /> }
          <div className={classes.helpSmall}>
          
          <Tooltip title={<Typography style={{fontSize: '16px'}}>Support & documentation</Typography>} placement="top">
          {breakpoint !== 'large' ? <IconButton onClick={handleHelpClick}><HelpOutlineRoundedIcon /></IconButton> : 
          <Button variant='contained' color='primary' style={{textTransform: 'none'}} onClick={handleHelpClick} startIcon={<HelpOutlineRoundedIcon />} >
          Get Help</Button> }</Tooltip>
              <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleHelpClose}
            >
              <StyledMenuItem onClick={supportClick} >
                <ListItemIcon>
                  <ContactSupportRoundedIcon  />
                </ListItemIcon>
                <ListItemText primary="Help Desk" />
              </StyledMenuItem>
              <StyledMenuItem onClick={helpClick} >
                <ListItemIcon>
                  <MenuBookRoundedIcon  />
                </ListItemIcon>
                <ListItemText primary="Documentation" />
              </StyledMenuItem>
              <StyledMenuItem onClick={videoClick} >
                <ListItemIcon>
                  <OndemandVideoRoundedIcon  />
                </ListItemIcon>
                <ListItemText primary="Video tutorials" />
              </StyledMenuItem>
            </StyledMenu> 
           
          </div>
         {/* UNDO/REDO Buttons */}
<div className={classes.undoRedo}>
  {/* Undo Button + Dropdown Group */}
  <div
   onMouseEnter={(e) => {setAnchorElUndo(e.currentTarget); }}
   onMouseLeave={() => setAnchorElUndo(null)}
    style={{ display: 'inline-block', cursor: 'pointer' }} // keep the group together
  >
    <div>
    {/* <Tooltip title={<Typography style={{ fontSize: '16px' }}>Undo</Typography>}> */}
      <IconButton onClick={handleUndo} disabled={pastActions.length === 0 ? true : false}>
        <UndoIcon />
      </IconButton>
    {/* </Tooltip> */}
    </div>
    {pastActions.length === 0 ? null : 
    <Popper
      id="undo-menu"
      anchorEl={anchorElUndo}
      open={Boolean(anchorElUndo)}
      onClose={() => setAnchorElUndo(null)}
      disableAutoFocus
      disableEnforceFocus
      style={{marginTop: '14px', zIndex: 9999, backgroundColor: 'white', border: '1px solid whitesmoke', borderRadius: 8}}
  anchorOrigin={{
    vertical: "bottom",
    horizontal: "center",
  }}
  transformOrigin={{
    vertical: "bottom",
    horizontal: "center",
  }}
    >
      {pastActions.slice(-10).reverse().map((past, index) => (
        
          <ListItem key={index}>
            <Typography variant="caption">{`Undo ${past.description}`}</Typography>
          </ListItem>
        
      ))}
    </Popper>
    }
  </div>

  {/* Redo Button */}
  <Tooltip title={<Typography style={{ fontSize: '16px' }}>Redo</Typography>}>
    <IconButton onClick={handleRedo}>
      <RedoIcon />
    </IconButton>
  </Tooltip>
</div>
<div>
<Tooltip title={<Typography style={{ fontSize: '16px' }}>{issues.length > 0 ? issues.length.toString() + " workflow issues" : "No issues to fix"}</Typography>}>
  <IconButton onClick={(e) => setAnchorElNotifications(e.currentTarget)}>
    <Badge badgeContent={issues.length} color="error">
      <NotificationsNoneIcon />
    </Badge>
  </IconButton>
  </Tooltip>
  <Menu
    anchorEl={anchorElNotifications}
    open={Boolean(anchorElNotifications)}
    onClose={() => setAnchorElNotifications(null)}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    style={{ marginTop: '50px' }}
  >
    <Typography style={{ paddingLeft: '12px', fontWeight: 500, fontSize: '18px' }}>
      Workflow Issues
    </Typography>
    <Typography style={{ paddingLeft: '12px', color: 'gray', fontSize: '14px' }} variant="caption">
      See issues, errors, and warnings on each step
    </Typography>
    <Divider style={{ margin: '8px' }} />
    <Typography style={{ paddingLeft: '12px', fontWeight: 500, fontSize: '14px' }}>Steps</Typography>
    {issues.length === 0 ? (
      <MenuItem>No issues found.</MenuItem>
    ) : (
      issues.map((issueObj, index) => (
        <MenuItem key={index} style={{ whiteSpace: 'normal', lineHeight: 1.2 }} onClick={()=> {setAnchorElNotifications(null); dispatch(elementSelected(issueObj.id))}}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={issueObj.icon} style={{ width: 24, height: 24, marginRight: 8 }} />
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {issueObj.stepName}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 32 }}>
              <WarningRoundedIcon style={{ fontSize: 16, color: 'orange', marginRight: 4 }} />
              <Typography  style={{ color: 'gray', fontSize: 16 }}>
                {issueObj.issueText}
              </Typography>
            </div>
          </div>
        </MenuItem>
      ))
    )}
  </Menu>
 
</div>
         { selectedElement && breakpoint !== 'large' ? null :  <div className={breakpoint !== 'large' ? classes.switchSmall : classes.switch} style={{backgroundColor: active_enabled === false ? 'lightgray' : null }}>
          <Tooltip title={<Typography style={{fontSize: '16px'}}>{active_enabled === false ? 'Disabled - fix issues' : !active ? 'Click to activate' : 'Click to deactivate'}</Typography>} placement="bottom">
            <FormControlLabel className={classes.right} style={{ color: '#000000' }}
              control={
                <GreenSwitch
                
                  checked={active}
                  name="checkedB"
                  disabled={active_enabled === true ? false : true }
                  onChange={() => handleSwitchChange()}
                />
              }
              label={<Typography style={{ color: 'white', fontWeight: 'bold' }} variant='button' >{!active ? 'OFF' : 'ON'}</Typography>}

            /></Tooltip>
      
          </div> }
          <div className={classes.shareright}>
          <Tooltip title={<Typography style={{fontSize: '16px'}}>{active_enabled === false ? 'Sharing disabled' : 'Share this workflow'}</Typography> }>
            <IconButton aria-label="share" onClick={()=> active_enabled === false ? null : handleShareClick()} >
           
            <ShareRoundedIcon style={{color: active_enabled === false ? 'lightgray' : null}} />
          </IconButton>
          </Tooltip>
          </div>
        </Toolbar>
        <Snackbar open={openalert} autoHideDuration={3000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
        <Alert onClose={handleCloseAlert} severity={alert.status}>
            {alert.message}
        </Alert>
    </Snackbar>
    <Modal
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
          open={modalopenshare}
          onClose={handleModalCloseShare}
          aria-labelledby="simple-modal-title"
          
      >
          {body_share}
      </Modal>

      <Modal
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
          open={modalopensharesuccess}
          onClose={handleModalCloseShareSuccess}
          aria-labelledby="simple-modal-title"
          
      >
          {body_share_success}
      </Modal>
      </AppBar>
    </div>
  );
}
