import Form from '@rjsf/material-ui'
import React, { useState, Fragment, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { Button, Fab,MenuItem, Accordion,Card,TableSortLabel, FormLabel,Table, TableHead, Radio, RadioGroup,FormControlLabel,FormControl, AccordionSummary, AccordionDetails, CircularProgress, Tooltip, Divider, List, ListItem, ListItemText, Typography, Grid, Paper, Box, TextField, Stepper, Step, StepButton, StepContent, IconButton, Menu, TableContainer, TableRow, TableCell, TableBody, TablePagination, TableFooter, InputAdornment } from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import axios from 'axios';
import { AppBarHeaderDummy } from "../components/AppBarIntegrator";
import { makeStyles } from '@material-ui/core/styles';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Controlled as CodeMirror2 } from 'react-codemirror2';

import 'codemirror/theme/darcula.css';

import { BuildRounded, ExpandMoreRounded, OfflineBoltRounded, SettingsRounded, SpellcheckRounded, VpnKeyRounded } from '@material-ui/icons';
import SearchRounded from '@material-ui/icons/SearchRounded';

require('codemirror/mode/python/python')


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }

Function.deserialise = function (key, data) {
    return (data instanceof Array && data[0] == 'window.Function') ?
        new (Function.bind.apply(Function, [Function].concat(data[1], [data[2]]))) :
        data
        ;
};

Function.prototype.toJSON = function () {
    var whitespace = /\s/;
    var pair = /\(\)|\[\]|\{\}/;

    var args = new Array();
    var string = this.toString();

    var fat = (new RegExp(
        '^\s*(' +
        ((this.name) ? this.name + '|' : '') +
        'function' +
        ')[^)]*\\('
    )).test(string);

    var state = 'start';
    var depth = new Array();
    var tmp;

    for (var index = 0; index < string.length; ++index) {
        var ch = string[index];

        switch (state) {
            case 'start':
                if (whitespace.test(ch) || (fat && ch != '('))
                    continue;

                if (ch == '(') {
                    state = 'arg';
                    tmp = index + 1;
                }
                else {
                    state = 'singleArg';
                    tmp = index;
                }
                break;

            case 'arg':
            case 'singleArg':
                var escaped = depth.length > 0 && depth[depth.length - 1] == '\\';
                if (escaped) {
                    depth.pop();
                    continue;
                }
                if (whitespace.test(ch))
                    continue;

                switch (ch) {
                    case '\\':
                        depth.push(ch);
                        break;

                    case ']':
                    case '}':
                    case ')':
                        if (depth.length > 0) {
                            if (pair.test(depth[depth.length - 1] + ch))
                                depth.pop();
                            continue;
                        }
                        if (state == 'singleArg')
                            throw '';
                        args.push(string.substring(tmp, index).trim());
                        state = (fat) ? 'body' : 'arrow';
                        break;

                    case ',':
                        if (depth.length > 0)
                            continue;
                        if (state == 'singleArg')
                            throw '';
                        args.push(string.substring(tmp, index).trim());
                        tmp = index + 1;
                        break;

                    case '>':
                        if (depth.length > 0)
                            continue;
                        if (string[index - 1] != '=')
                            continue;
                        if (state == 'arg')
                            throw '';
                        args.push(string.substring(tmp, index - 1).trim());
                        state = 'body';
                        break;

                    case '{':
                    case '[':
                    case '(':
                        if (
                            depth.length < 1 ||
                            !(depth[depth.length - 1] == '"' || depth[depth.length - 1] == '\'')
                        )
                            depth.push(ch);
                        break;

                    case '"':
                        if (depth.length < 1)
                            depth.push(ch);
                        else if (depth[depth.length - 1] == '"')
                            depth.pop();
                        break;
                    case '\'':
                        if (depth.length < 1)
                            depth.push(ch);
                        else if (depth[depth.length - 1] == '\'')
                            depth.pop();
                        break;
                }
                break;

            case 'arrow':
                if (whitespace.test(ch))
                    continue;
                if (ch != '=')
                    throw '';
                if (string[++index] != '>')
                    throw '';
                state = 'body';
                break;

            case 'body':
                if (whitespace.test(ch))
                    continue;
                string = string.substring(index);

                if (ch == '{')
                    string = string.replace(/^{\s*(.*)\s*}\s*$/, '$1');
                else
                    string = 'return ' + string.trim();

                index = string.length;
                break;

            default:
                throw '';
        }
    }

    return ['window.Function', args, string];
};

const useStyles = makeStyles((theme) => ({
    root: {
       display: 'flex',
       paddingRight: '12px'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.default,
        paddingRight: 20,
        marginTop: 50
    },
    fab: {
        '& > *': {
            margin: theme.spacing(1),

        },
        position: 'fixed',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        zIndex: 9
    },
}));
const borderlines = ["lightcoral", "blue", "green", "indigo","gold", "fuchsia", "lightblue", "lightgreen","lightcoral", "blue", "green", "indigo","gold", "fuchsia", "lightblue", "lightgreen","lightcoral", "blue", "green", "indigo","gold", "fuchsia", "lightblue", "lightgreen"]
const ObjectFieldTemplate = ({ TitleField, properties, title, description }, props) => {
 
    return (
      <div>
        <TitleField title={title} />
        <div className="row">
          {properties.map(prop => (
            <div
              className="col-lg-2 col-md-4 col-sm-6 col-xs-12"
              key={prop.content.key}>
              {prop.content}
            </div>
          ))}
        </div>
        {description}
      </div>
    );
  }
const ArrayFieldTemplate = (props)=> {
  
   function getTitle(){
   // console.log('formcontext:', props.formContext)
   // console.log('prps:', props)
   const body = props.formContext.filter(body => body.name === 'body')[0]
   const arrays = body && body.schema.properties ? body.schema.properties.filter(body => body.schema.items) : []
  // console.log('body === ', body.schema.properties)
  // console.log('arrays ==== ', arrays)
   // const title_obj = arrays.filter(data =>  data.schema.items.properties.includes(props.formData))
   // console.log('title obj === ', title_obj)
   let arrayFilter = props.formData.map(itemY => { return itemY; });
   // console.log('arrayFilter::: ', arrayFilter)
    let compareTwoArrayOfObjects = (
        first_array_of_objects,
        second_array_of_objects
    ) => {
        return (
            first_array_of_objects.length === second_array_of_objects.length &&
            first_array_of_objects.every((element_1) =>
                second_array_of_objects.some(
                    (element_2) =>
                        element_1.key === element_2.key 
                )
            )
        );
    };
   // Use filter and "not" includes to filter the full dataset by the filter dataset's val.
   let filteredX = arrays.filter(array => array.schema.items.properties && array.schema.items.properties.length === arrayFilter.length && compareTwoArrayOfObjects(arrayFilter,array.schema.items.properties) === true);
  
   // Print the result.
  // console.log('title OBJ:: ',filteredX);
    const new_title = filteredX[0]?.title ? filteredX[0].title : "Body"
    return new_title
   }

    return (
        <div style={{border: '1px solid black', borderRadius: '5px', padding: '8px', margin: '8px'}}>
        <Typography variant="h5">{ props.title ?? props.schema.title}</Typography>
        <Typography>{props.schema.description}</Typography>
        {props.items &&
          props.items.map((element, index) => (
            <div key={element.key} className={element.className} style={{border: '2px solid', borderRadius: '5px', borderColor: borderlines[index], padding: '8px', margin: "8px 0px 8px 0px"}}>
              <div>{element.children}</div>
              <div style={{float: "right"}}>
              {element.hasMoveDown && (
                  <IconButton  style={{cursor: "pointer", backgroundColor: 'white', color: 'black', padding: '8px 12px 8px 12px'}}
                  onClick={element.onReorderClick(
                    element.index,
                    element.index + 1
                  )}>
                  <ArrowDownwardRoundedIcon />
                </IconButton>
              )}
              {element.hasMoveUp && (
                  <IconButton  style={{cursor: "pointer", backgroundColor: 'white', color: 'black', padding: '8px 12px 8px 12px'}}
                  onClick={element.onReorderClick(
                    element.index,
                    element.index - 1
                  )}>
                  <ArrowUpwardRoundedIcon />
                </IconButton>
              )}
              <Tooltip title={"Delete"}>
             <IconButton style={{cursor: "pointer", backgroundColor: 'white', color: borderlines[index], padding: '8px 12px 8px 12px', border: '1px solid',borderColor: borderlines[index], borderRadius: '5px', marginBottom: '8px'}}
                onClick={element.onDropIndexClick(element.index)}>
                <ClearRoundedIcon />
              </IconButton></Tooltip>
            </div>
            <hr style={{marginTop: '8px', marginBottom: '8px', width: "100%"}} />
            </div>
          ))}
  
        {props.canAdd  && (
          <div className="row">
            <p className="col-xs-3 col-xs-offset-9 array-item-add text-right">
              <Button onClick={props.onAddClick} type="button" variant="contained" style={{textTransform: "none", color: "white", backgroundColor: "black", float: "right", padding: '4px 8px 4px 8px', margin: '12px 0px 12px 0px'}}>
                + Add to {props.title === 'properties' ? getTitle() : props.title ?? props.schema.title} 
              </Button>
            </p>
          </div>
        )}
      </div>
    );
  }

const regexErrors = (es) => {
    return es.map(e => {
        if (e.stack.includes('should match pattern \"^[a-zA-Z\\d-_]*$\"')) {
            e.message = 'only letters, numbers, hyphens, and underscores'
        }
        return e
    })
}

const sortObj = o => Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {})

const ActionForm = ({ action, onChange, actionDelete, appInfo }) => {

    const dynamicFormSchema = {
        "type": "object",
        "properties": {
            "configs": {
                "type": "array",
                "items": {
                    "type": "object",
                    "description": "Should contain asyncConfig and responseTransform unless it's the last step, then it should only contain finalProcess",
                    "properties": {
                        "asyncConfig": {
                            "type": "string",
                            "description": "takes in a 'values' and 'cred' argument; needs to return an array of objects with 'label' and 'value' key",
                            "default": "\{ \}"
                        },
                        "responseTransform": {
                            "type": "string",
                            "description": "takes in a 'resp' argument; needs to return an array of objects with 'label' and 'value' key, except for second-to-last step",
                            "default": "\{ \}"
                        },
                        "finalProcess": {
                            "type": "string",
                            "default": "\{ \}"
                        }
                    }
                }
            }
        }
    }

    const actionSchema = {
        "type": "object",
        "properties": {
            "key": {
                "type": "string",
                "pattern": "^[a-zA-Z\\d-_]*$"
            },
            "title": {
                "type": "string"
            },
            "description": {
                "type": "string"
            },
            "url": {
                "type": "string",
                "description": "Use ${formFields.XXX} when you need to reference data from the Auth section. Otherwise, use {XXX} to reference a path parameter."
            },
            "method": {
                "type": "string",
                "enum": [
                    "get",
                    "post",
                    "put",
                    "patch",
                    "delete"
                ]
            },
            "hidden": {
                "type": "boolean",
                "title": "Is Action Hidden?"
            },
            "fields": {
                "type": "array",
                "title": "List of fields for this action",
                "items": {
                    "type": "object",
                    "properties": {
                        "name": {
                            "type": "string"
                        },
                        "title": {
                            "type": "string"
                        },
                        "description": {
                            "type": "string"
                        },
                        "hidden": {
                            "type": "boolean",
                            "title": "Is Field Hidden?"
                        },
                        "in": {
                            "type": "string",
                            "enum": [
                                "query",
                                "path",
                                "header",
                                "formData"
                            ]
                        },
                        
                        "required": {
                            "type": "boolean",
                            "title": "Is Field Required?"
                        },
                        "schema": {
                            "$ref": "#/$defs/jsonSchema"
                        },
                        "value": {
                            "type": "string"
                        }
                    },
                    "required": ["name", "in", "schema"]
                }
            }
        },
        "$defs": {
            "jsonSchema": {
                "title": "Field Type",
                "type": "object",
                "properties": {
                    "type": {
                        "type": "string",
                        "enum": [
                            "string",
                            "number",
                            "object",
                            "array",
                            "boolean"
                        ]
                    },
                    "dynamic": {
                        "type": "string",
                        "description": "ex: appSlug.Label.getListActionKey.listHead.listValue.listLabel\n\nappSlug = "+appInfo.appSlug+"\n\nLabel = What user sees i.e. \"Choose your Label\"\n\ngetListActionKey = The key of another action in this app to call in order to get a list of items for user to choose from\n\nlistHead = The name of the array which is at the head of the array (before the [ bracket) like this  (e.g. \"SPACES\": [ \"id\": 1234, \"name\": \"Joe's Workspace\")\n\nlistValue = Within the array, what is the label for the id or value to be used by this action you're setting up (e.g.  \"spaces\": [ \"ID\": 1234, \"name\": \"Joe's Workspace\")\n\nlistLabel = What the user sees to help them know what value they're selecting  (e.g.  \"spaces\": [ \"id\": 1234, \"NAME\": \"Joe's Workspace\")"
                    },
                    "params": {
                        "type": "array",
                        "title": "Params",
                        "description": "Insert field names from the dynamic action that need to populated/referenced from other fields in this lookup step. i.e. spreadsheet.",
                        "items": {
                            "type": "object",
                            "properties": {
                                "name": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "perform": {
                        "type": "string",
                        "description": "ex: "+appInfo.appSlug+".Spreadsheets.getFiles.results.id.name (appSlug.DisplayedAs.action.data_head.value.human_readable)"
                    },
                },
                "required": ["type"],
                "dependencies": {
                    "type": {
                        "oneOf": [
                            {
                                "properties": {
                                    "type": {
                                        "enum": ["string"]
                                    },
                                    "Is static list?": {
                                        "type": 
                                            "string",
                                            "enum": ["Yes", "No"]
                                      }
                                }
                            },
                            {
                                "properties": {
                                    "type": {
                                        "enum": ["number"]
                                    }
                                }
                            },
                            {
                                "properties": {
                                    "type": {
                                        "enum": ["boolean"]
                                    }
                                }
                            },
                            {
                                "properties": {
                                    "type": {
                                        "enum": ["array"]
                                    },
                                    "items": {
                                        "$ref": "#/$defs/jsonSchema"
                                    },
                                    "hidden": {
                                        "type": "boolean",
                                        "title": "Is Hidden?"
                                    }
                                }
                            },
                            {
                                "properties": {
                                    "type": {
                                        "enum": ["object"]
                                    },
                                    "properties": {
                                        "type": "array",
                                        "items": {
                                            "type": "object",
                                            "properties": {
                                                "key": {
                                                    "type": "string"
                                                },
                                                "title": {
                                                    "type": "string"
                                                },
                                                "description": {
                                                    "type": "string"
                                                },
                                                "hidden": {
                                                    "type": "boolean",
                                                    "title": "Is Object Property Hidden?"
                                                },
                                                "schema": {
                                                    "$ref": "#/$defs/jsonSchema"
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        ]
                    },
                    "Is static list?" : {
                        "oneOf": [ {
                            "properties": {
                              "Is static list?": {
                                "enum": [
                                  "No"
                                ]
                              }
                            }
                          },
                          {
                            "properties": {
                                "Is static list?": {
                                    "enum": ["Yes"]
                                },
                                "enum": {
                                    "type": "array",
                                    "items": {
                                        "type": "string"
                                    }
                                },
                                "enumNames": {
                                    "type": "array",
                                    "items": {
                                        "type": "string"
                                    }
                                }
                            }
                        }]
                    }
                }
            }
        }
    }

    //converts the action schema to a form to fill out
    const actionToForm = (actSch, title) => {
        if (actSch.$ref) {
           
            return actSch
        }
        if (actSch.type == 'object') {
          
            var propsArr = []
            Object.entries(actSch.properties).forEach(([k, v]) => {
              
                propsArr.push({
                    key: k,
                    title: v.title,
                    description: v.description,
                    hidden: v.hidden,
                    schema: actionToForm(v)
                })
            })
            return {
                "type": "object",
                "properties": propsArr,
                "title": title
            }
        } else if (actSch.type == 'array') {
          
            return {
                "type": "array",
                "items": actionToForm(actSch.items, actSch.title),
                "title": actSch.title
            }
        } else {
            
            return actSch
        }
    }

    
    

    //converts the field form value to its corresponding action value
    const formToAction = (formSch) => {
        console.log('forSch ==== ', formSch)
        if (formSch.$ref) {
            return formSch
        }

        if (formSch.type == 'object') {
            var propsObj = {}
            formSch.properties?.forEach((prop) => {
                
                propsObj[prop.key] = { ...formToAction(prop.schema), title: prop.title, description: prop.description, hidden: prop.hidden }
                console.log('propsbe to: ', propsObj)
            })
            return {
                "type": "object",
                "properties": propsObj
            }
        } else if (formSch.type == 'array') {
            return {
                "type": "array",
                "items": formToAction(formSch.items)
            }
        } else {
            return formSch
        }
    }

    //decode dynamic form string to a json schema form
    const dynamicActionToForm = (dynamicDataString) => {
        if (!dynamicDataString) {
            return null
        }

        return JSON.parse(dynamicDataString, Function.deserialise)
    }

    const dynamicFormToAction = (dynamicForm) => {
        if (!dynamicForm) {
            return null
        }

        return JSON.stringify(dynamicForm)
    }

    //convert the true action object value into the form version value
    const actionObj = {
        ...action,
        dynamicForm:  action ? dynamicActionToForm(action.dynamicForm) : {} ,
        fields:  action ? action.fields.map(field => {
            return {
                ...field,
                schema: actionToForm(field.schema)
            }
        }) : {}
    }
console.log('actionobj.....: ', actionObj)
    const [actionbutton, setActionButton] = useState(false);

    const codeMirrorOptions = {
        mode: "javascript",
        lineNumbers: true,
        cursorHeight: 1,
        fullScreen: false,
        scrollbarStyle: null,
        lineWrapping: false,
        theme: 'paraiso-dark',
        readOnly: false,
    }

    return (
        <div style={{whiteSpace: 'pre-line'}} >
            {actionbutton === false ? <Button variant='outlined' color='secondary' onClick={() => { setActionButton(true) }}>Delete Action</Button> : <Button variant='outlined' color='secondary' onClick={() => { actionDelete(action.key) }}><Typography color="secondary" style={{ fontWeight: 'bold' }}>Really Delete?</Typography></Button>}
            <Typography><h2>Edit { action ? action.key : ""}</h2></Typography>
            <Form style={{ outerWidth: '80%vw'  }} liveValidate={true} showErrorList={false} transformErrors={regexErrors} schema={actionSchema} ObjectFieldTemplate={ObjectFieldTemplate} ArrayFieldTemplate={ArrayFieldTemplate} formData={actionObj} formContext={actionObj.fields} onChange={e => {
                console.log(e.formData)
                var newActionObj = {
                    ...e.formData,
                    dynamicForm: dynamicFormToAction(e.formData.dynamicForm),
                    fields: e.formData.fields.map(field => {
                        return {
                            ...field,
                            schema: formToAction(field.schema)
                        }
                    })
                }
                onChange(newActionObj)
            }} ><Fragment /></Form>
            <CodeMirror2 options={codeMirrorOptions} />
            
        </div>
    )
}




const AuthInfoForm = ( {actions, authInfo, updateAuthInfo, appInfo }) => {
//console.log('authinfoform actions: ', actions)
    const classes = useStyles();

    const [activeStep, setActiveStep] = useState(0)

    const [apiType, setApiType] = useState(authInfo.apiType)

    console.log(authInfo)

    const apiTypeOptions = [
        {
            "key": "basic",
            "label": "Basic"
        },
        {
            "key": "oauth2",
            "label": "OAuth2"
        },
        {
            "key": "apikey",
            "label": "API Key/Custom"
        },
        {
            "key": "customCode",
            "label": "Custom Code"
        },
        {
            "key": "",
            "label": ""
        }
    ]

    const basicSetupConfig = {
        "steps": [
            {
                "title": "Username/password form",
                "key": "basicFormFields",
                "description": "The form presented to the user is a simple username and password, all that's needed for Basic Auth",
                "schema": {
                    "type": "array",
                    "items": {
                        "type": "object",
                        "properties": {
                            "key": {
                                "type": "string"
                            },
                            "title": {
                                "type": "string"
                            },
                            "description": {
                                "type": "string"
                            }
                        }
                    }
                },
                "value": authInfo.basicFormFields ?? [
                    {
                        "key": "username"
                    },
                    {
                        "key": "password"
                    }
                ]
            },
            {
                "key": "testAuth",
                "title": "Auth Test Action",
                "value": authInfo.testAuth ?? "",
                "description": "Define an action that can validate the user auth input (a GET request with no params works best)",
                "schema": {
                    "type": "string",
                    "enum": Object.keys(actions),
                    "title": "Auth Test Action",
                    "description": "The action that will generate a test with the user input to make sure they are connected and authenticated."
                }
               
            }
        ]
    }

    const apikeySetupConfig = {
        "steps": [
            {
                "key": "apikeyFormFields",
                "title": "Form Fields",
                "description": "Define a list of fields for the user to fill out",
                "schema": {
                    "type": "array",
                    "items": {
                        "type": "object",
                        "properties": {
                            "key": {
                                "type": "string"
                            },
                            "title": {
                                "type": "string"
                            },
                            "description": {
                                "type": "string"
                            }
                        }
                    }
                },
                "value": authInfo.apikeyFormFields ?? []
            },
            {
                "key": "requestConfig",
                "title": "Define Request",
                "description": "Define where in the request the previously defined fields will go",
                "schema": {
                    "type": "object",
                    "properties": {
                        "params": {
                            "title": "Query Parameters",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        },
                        "headers": {
                            "title": "Headers",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    }
                },
                "value": authInfo.requestConfig ?? {
                    url: "",
                    params: [],
                    headers: []
                }
            },
            {
                "key": "testAuth",
                "title": "Auth Test Action",
                "value": authInfo.testAuth ?? "",
                "description": "Define an action that can validate the user auth input (a GET request with no params works best)",
                "schema": {
                    "type": "string",
                    "enum": Object.keys(actions),
                    "title": "Auth Test Action",
                    "description": "The action that will generate a test with the user input to make sure they are connected and authenticated."
                }
               
            }
        ]
    }


    const oauthSetupConfig = {
        "steps": [
            {
                "key": "oauthFormFields",
                "title": "Form Fields",
                "description": "Define a list of fields for the user to fill out",
                "schema": {
                    "type": "array",
                    "items": {
                        "type": "object",
                        "properties": {
                            "key": {
                                "type": "string"
                            },
                            "title": {
                                "type": "string"
                            },
                            "description": {
                                "type": "string"
                            }
                        }
                    }
                },
                "value": authInfo.oauthFormFields ?? []
            },
            {
                "key": "clientInfo",
                "title": "Application Credentials",
                "description": "Input the client_id and client_secret here",
                "schema": {
                    "type": "object",
                    "properties": {
                        "client_id": {
                            "type": "string"
                        },
                        "client_secret": {
                            "type": "string"
                        }
                    }
                },
                "value": authInfo.clientInfo ?? {
                    client_id: "",
                    client_secret: ""
                }
            },
            {
                "key": "authRequestConfig",
                "title": "Define Authorization Request",
                "description": "Define where in the request the previously defined fields will go",
                "schema": {
                    "type": "object",
                    "properties": {
                        "url": {
                            "title": "Request URL",
                            "type": "string"
                        },
                        "params": {
                            "title": "Query Parameters",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        },
                        "headers": {
                            "title": "Headers",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        },
                        "body": {
                            "title": "Form Body",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    }
                },
                "value": authInfo.authRequestConfig ?? {
                    url: "",
                    params: [
                        {
                            "key": "client_id",
                            "value": "${clientInfo.client_id}"
                        }, {
                            "key": "redirect_uri",
                            "value": "https://editor.workload.co:5000/auth/"+appInfo.appSlug+"/redirect"
                        }, {
                            "key": "response_type",
                            "value": "code"
                        }, {
                            "key": "state",
                            "value": "state"
                        }
                    ],
                    headers: [],
                    body: []
                }
            },
            {
                "key": "accessRequestConfig",
                "title": "Define Access Token Request",
                "description": "Define where in the request the previously defined fields will go",
                "schema": {
                    "type": "object",
                    "properties": {
                        "url": {
                            "title": "Request URL",
                            "type": "string"
                        },
                        "params": {
                            "title": "Query Parameters",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        },
                        "headers": {
                            "title": "Headers",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        },
                        "body": {
                            "title": "Form Body",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    }
                },
                "value": authInfo.accessRequestConfig ?? {
                    url: "",
                    params: [],
                    headers: [],
                    body: [
                        {
                            "key": "client_id",
                            "value": "${clientInfo.client_id}"
                        }, {
                            "key": "client_secret",
                            "value": "${clientInfo.client_secret}"
                        }, {
                            "key": "grant_type",
                            "value": "authorization_code"
                        }, {
                            "key": "redirect_uri",
                            "value": "https://editor.workload.co:5000/auth/"+appInfo.appSlug+"/redirect"
                        }, {
                            "key": "code",
                            "value": "code"
                        }
                    ]
                }
            },
            {
                "key": "refreshRequestConfig",
                "title": "Define Refresh Token Request",
                "description": "Define where in the request the previously defined fields will go",
                "schema": {
                    "type": "object",
                    "properties": {
                        "url": {
                            "title": "Request URL",
                            "type": "string"
                        },
                        "params": {
                            "title": "Query Parameters",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        },
                        "headers": {
                            "title": "Headers",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        },
                        "body": {
                            "title": "Form Body",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    }
                },
                "value": authInfo.refreshRequestConfig ?? {
                    "url": "",
                    "params": [],
                    "headers": [],
                    "body": [
                        {
                            "key": "client_id",
                            "value": "${clientInfo.client_id}"
                        },
                        {
                            "key": "client_secret",
                            "value": "${clientInfo.client_secret}"
                        },
                        {
                            "key": "refresh_token",
                            "value": "${formFields.refresh_token}"
                        },
                        {
                            "key": "grant_type",
                            "value": "refresh_token"
                        }
                    ]
                }
            },
            {
                "key": "requestConfig",
                "title": "Define Request",
                "description": "Define where in the request the previously defined fields will go",
                "schema": {
                    "type": "object",
                    "properties": {
                        "params": {
                            "title": "Query Parameters",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        },
                        "headers": {
                            "title": "Headers",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    }
                },
                "value": authInfo.requestConfig ?? {
                    url: "",
                    params: [],
                    headers: []
                }
            }
        ]
    }

    var defaultCustomCode = `
    domain = r['domain']
    access_token = r['access_token']
    url = url.format(domain=domain)
    headers['APIKEY'] = access_token
    `

    var starterCustomCode = `
    import sys, os,json, re, base64, datetime, hashlib, hmac 
    import requests

    def authenticatedRequest(creds, method, url, headers={}, params={}, body={}):
        credURL = "https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/credentials?id="+creds
        credInfo = requests.get(credURL).json()[0]
    `

    var enderCustomCode = `
        r = requests.request(method, url, headers=headers, params=params, json=body)
    `

    const customCodeConfig = {
        'steps': [
            {
                "key": "customCodeFormFields",
                "title": "Form Fields",
                "description": "Define a list of fields for the user to fill out",
                "schema": {
                    "type": "array",
                    "items": {
                        "type": "object",
                        "properties": {
                            "key": {
                                "type": "string"
                            },
                            "title": {
                                "type": "string"
                            },
                            "description": {
                                "type": "string"
                            }
                        }
                    }
                },
                "value": authInfo.customCodeFormFields ?? []
            },
            {
                "title": "Define Auth Request",
                "key": "customCodeInfo",
                "description": "",
                "schema": {
                    "type": "object",
                    "properties": {
                        "customCode": {
                            "type": "string",
                            "code": true
                        }
                    }
                },
                "value": authInfo.customCodeInfo ?? { customCode: defaultCustomCode }
            },
            {
                "key": "testAuth",
                "title": "Auth Test Action",
                "value": authInfo.testAuth ?? "",
                "description": "Define an action that can validate the user auth input (a GET request with no params works best)",
                "schema": {
                    "type": "string",
                    "enum": Object.keys(actions),
                    "title": "Auth Test Action",
                    "description": "The action that will generate a test with the user input to make sure they are connected and authenticated."
                }
               
            }
        ]
    }




    const [authValue, setAuthValue] = React.useState();
    const history = useHistory()
    const handleAuthValueChange = (event) => {
      setAuthValue(event.target.value);
      setApiType(event.target.value)
      updateAuthInfo('apiType', event.target.value)
      if (event.target.value == 'basic') {
          basicSetupConfig.steps.forEach(step => { updateAuthInfo(step.key, step.value) })
      } else if (event.target.value == 'oauth2') {
          console.log(oauthSetupConfig)
          oauthSetupConfig.steps.forEach(step => { updateAuthInfo(step.key, step.value) })
      } else if (event.target.value == 'apikey') {
          apikeySetupConfig.steps.forEach(step => { updateAuthInfo(step.key, step.value) })
      } else if (event.target.value == 'customCode') {
          customCodeConfig.steps.forEach(step => { updateAuthInfo(step.key, step.value) })
      }
      //history.push("/develop/"+appInfo.appSlug+"/authentication/edit")
    };
    const [alert, setAlert] = useState();
    const [saving, setSaving] = React.useState()
    const saveToDB = () => {
        console.log(appInfo)
        setSaving(true)
        axios({
            headers: {
                'content-type': 'application/json'
            },
            method: 'post',
            url: 'https://editor.workload.co:5000/api/apps/' + appInfo.appSlug,
            data: JSON.stringify(appInfo)
        }).then(resp => { console.log(resp); setAlert({ "severity": "success", "message": appInfo.appName + " integration saved!" }); setSaving(false); history.push("/develop/"+appInfo.appSlug+"/authentication/edit") })
    }


    return (
        <>
            
            <Paper className={classes.paper} elevation={0} >
                {/* <Autocomplete
                    options={apiTypeOptions}
                    getOptionLabel={(option) => option.label ?? option.key}
                    onChange={(e, v) => {
                        setApiType(v.key)
                        updateAuthInfo('apiType', v.key)
                        if (v.key == 'basic') {
                            basicSetupConfig.steps.forEach(step => { updateAuthInfo(step.key, step.value) })
                        } else if (v.key == 'oauth2') {
                            console.log(oauthSetupConfig)
                            oauthSetupConfig.steps.forEach(step => { updateAuthInfo(step.key, step.value) })
                        } else if (v.key == 'apikey') {
                            apikeySetupConfig.steps.forEach(step => { updateAuthInfo(step.key, step.value) })
                        } else if (v.key == 'customCode') {
                            customCodeConfig.steps.forEach(step => { updateAuthInfo(step.key, step.value) })
                        }
                    }}
                    value={apiTypeOptions.find(item => item.key == apiType) ?? { key: "", label: "" }}
                    renderInput={(params) => <TextField style={{ padding: '4px' }} {...params} label={"Auth Type"} />}
                /> */}
                 <Card style={{padding: '14px', margin: '12px 0px 12px 0px', width: '100%'}}>
                    
                    <Typography variant="h5">Add Authentication</Typography>
                    <p>Authentication lets users prove their identity to your app and authorize Workload to access their data, using your API authentication scheme.</p>
                    </Card>
                 <FormControl style={{width: '100%'}} component="fieldset">
                
                <RadioGroup aria-label="gender" name="gender1" value={authValue} onChange={handleAuthValueChange}>
                   <Card style={{padding: '12px', width: '100%', margin: '12px 0px 12px 0px'}}>
                   <FormControlLabel value="apikey" control={<Radio />} label="API Key" />
                   <p>Use API Key authentication type if you simply need to collect some information from your users and then include that information, as it was entered by the user, when you make an API request.</p>
                   </Card>
                    
                   <Card style={{padding: '12px', width: '100%', margin: '12px 0px 12px 0px'}}>
                   <FormControlLabel value="oauth2" control={<Radio />} label="OAuth 2.0" />
                   <p>Use the OAuth 2 authentication type if your API supports OAuth 2 "Authorization Code" grant. When setting up a workflow, your user's browser will be redirected to your site where you can authenticate them. Your OAuth implementation will then return an access token that your Workload integration will use to authorize requests to your API. If your API uses one of the other OAuth 2 grant types, Session auth or API Key authentication will be a better fit.</p>
                   </Card>
                   <Card style={{padding: '12px', width: '100%', margin: '12px 0px 12px 0px'}}>
                   <FormControlLabel value="basic" control={<Radio />} label="Basic Auth" />
                   <p>Use the basic authentication type if your API relies on the HTTP “Basic” Authentication standard. When your user sets up a new workflow, Workload will prompt them for a username and password, then automatically add the appropriate encoded authorization headers to your API requests for you.</p>
                   </Card>
                   <Card style={{padding: '12px', width: '100%', margin: '12px 0px 12px 0px'}}>
                   <FormControlLabel value="customCode" control={<Radio />} label="Custom Auth" />
                   <p>Use the Custom authentication type if you need to collect some information from your users, for example a user name and password, and then make a request to your API to exchange that information for a token or session key, which you will use to authorize subsequent API requests.</p>
                   </Card>
                </RadioGroup>
                
                </FormControl>
                <Button onClick={saveToDB} size='large' color="primary" startIcon={saving === true ? <CircularProgress color="secondary" size={30} /> : <SaveRoundedIcon />} variant="contained" style={{float: 'right', textDecoration: 'none'}}>Save</Button>
            </Paper><br />
           
        </>
    )
}



export default () => {
    const classes = useStyles();

    const [allApps, setAllApps] = useState([])
    const [loadingAllApps, setLoadingAllApps] = useState(false)
    const [alert, setAlert] = useState();
    const [appInfo, setAppInfo] = useState({ actions: {}, triggers: {}, authInfo: {} })
    const [loadingApp, setLoadingApp] = useState(false)

    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(true)
    const [saving, setSaving] = useState(false)
    const [formType, setFormType] = useState(null)
    const [selectedObject, setSelectedObject] = useState(null)

    const statusOptions = [
        "Complete",
        "In Progress"
    ]

    const allAppsFilterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 50
    })

   // console.log(appInfo)

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const loadApps = () => {
        setLoadingAllApps(true)
        axios({
            method: "get",
            url: "https://editor.workload.co:5000/api/apps"
        }).then(resp => { console.log(resp); setAllApps(resp.data); setLoadingAllApps(false) })
    }
    const pathname = window.location.pathname.split("/")[2]
    useEffect(() => {
       // console.log('pathname is: ', pathname)
       if (pathname) {
        loadApp(pathname)
       }
    }, [pathname])
//console.log('this is the authentication page talking!')
    const loadApp = (appSlug) => {
        setLoadingApp(true)
        setSelectedObject(null)
        setFormType(null)
        setAppInfo({ actions: {}, triggers: {}, authInfo: {} })
        axios({
            method: 'get',
            url: `https://editor.workload.co:5000/api/apps/`+pathname
        }).then(resp => { setAppInfo(resp.data); setLoadingApp(false); setDisabled(false) })
    }

    useEffect(() => {
        loadApps()
    }, [])


    const saveToDB = (newAppInfo) => {
       // console.log(appInfo)
        setSaving(true)
        axios({
            headers: {
                'content-type': 'application/json'
            },
            method: 'post',
            url: 'https://editor.workload.co:5000/api/apps/' + appInfo.appSlug,
            data:  JSON.stringify(newAppInfo ?? appInfo)
        }).then(resp => { console.log(resp); setAlert({ "severity": "success", "message": appInfo.appName + " integration saved!" }); handleClick(); setSaving(false) })
    }

  

    const makeNewKey = (dictOfKeys, testString, num = 0) => {
        if (Object.keys(dictOfKeys).includes(testString)) {
            return makeNewKey(dictOfKeys, testString.slice(0, testString.length - 1) + num, num + 1)
        } else {
            return testString
        }
    }

  
 
const [reallyDelete, setReallyDelete] = useState(false)
    const deleteAction = (actionKey) => {
        if (reallyDelete === true) {
            var newAppInfo = JSON.parse(JSON.stringify(appInfo))
            setFormType(null)
            setSelectedObject(null)
            delete newAppInfo.actions[actionKey]
            setAppInfo(newAppInfo)
           // console.log('new app info: ', newAppInfo)
            saveToDB(newAppInfo)
            handleCloseAuthMenu();
            setReallyDelete(false)
        } else {
            setReallyDelete(true)
        }
       
    }

  
   
    const history = useHistory()
   // console.log(appInfo)
    const [anchorElAuthMenu, setAnchorElAuthMenu] = React.useState(null);
    const [rowKey, setRowKey] = useState()

    const handleAuthClick = (event, rowKey) => {
      setAnchorElAuthMenu(event.currentTarget);
      setRowKey(rowKey)
     // console.log('rowkey: ', rowKey)
    };
  
    const handleCloseAuthMenu = () => {
      setAnchorElAuthMenu(null);
      setReallyDelete(false)
    };


    const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (event, newPage) => {
   // console.log('new page: ', newPage)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
   // console.log('rows per page: ', event.target.value)
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const formattedActionArray = Object.values(appInfo.actions).map(item => ({
    key: item.key,
    name: item.title,
    description: item.description,
    hidden: item.hidden
  }));
  const [searchQuery, setSearchQuery] = useState('');
  const [orderDirection, setOrderDirection] = useState('asc'); // ascending or descending
  const [orderBy, setOrderBy] = useState('name'); // default order by name

 // Function to filter rows based on search input
 const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
 // Handle sorting logic
 const handleSortRequest = (property) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Function to sort rows based on column
  const stableSort = (array, comparator) => {
    const stabilizedArray = array.map((el, index) => [el, index]);
    stabilizedArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedArray.map((el) => el[0]);
  };

  // Comparator for sorting
  const getComparator = (orderDirection, orderBy) => {
    return orderDirection === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  // Comparator for sorting in descending order
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };
   // Filter the rows based on the search query
   const filteredRows = formattedActionArray.filter((row) => {
    return (
      row.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.key?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.type?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (row.hidden === true ? 'hidden' : 'shown').includes(searchQuery.toLowerCase())
    );
  });

   // Sort the filtered rows
   const sortedRows = stableSort(filteredRows, getComparator(orderDirection, orderBy));

   const date_options = [
    {
        "id": "%Y-%m-%dT%H:%M:%S%z",
        "format": "YYYY-MM-DDTHH:MM:SSZ (2013-09-30T00:00:00Z)"
    },
    {
        "id": "%Y-%m-%dT%H:%M:%S%",
        "format": "YYYY-MM-DDTHH:MM:SS (2013-09-30T00:00:00)"
    },
    {
        "id": "%m/%d/%y",
        "format": "MM/DD/YY (09/30/13)"
    },
    {
        "id": "%m/%d/%Y",
        "format": "MM/DD/YYY (09/30/2013)"
    },
    {
        "id": "%m-%d-%y",
        "format": "MM-DD-YY (09-30-13)"
    },
    {
        "id": "%m-%d-%Y",
        "format": "MM-DD-YYYY (09-30-2013)"
    },
    {
        "id": "%Y-%m-%d",
        "format": "YYYY-MM-DD (2013-09-30)"
    },
    {
        "id": "%d/%m/%y",
        "format": "DD/MM/YY (30/09/13)"
    },
    {
        "id": "%d/%m/%Y",
        "format": "DD/MM/YYYY (30/09/2013)"
    },
    {
        "id": "%d-%m-%y",
        "format": "DD-MM-YY (30-09-13)"
    },
    {
        "id": "%d-%m-%Y",
        "format": "DD-MM-YYY (30-09-2013)"
    },
    {
        "id": "%Y-%m-%d %H:%M:%S %z",
        "format": "YYY-MM-DD HH:mm:ss Z (2013-09-30 23:04:05 +0000)"
    },
    {
        "id": "%Y-%m-%d %H:%M:%S%z",
        "format": "YYYY-MM-DD HH:mm:ssZ (2013-09-30 23:04:05-0000)"
    },
    {
        "id": "%b %d %Y",
        "format": "MMM DD YYYY (Sep 30 2013)"
    },
    {
        "id": "%B %d %Y",
        "format": "MMMM DD YYYY (September 30 2013)"
    },
    {
        "id": "%B %d %Y %H:%M:%S",
        "format": "MMMM DD YYYY HH:mm:ss (September 30 2013 23:04:05)"
    },
    {
        "id": "%a %b %d %H:%M:%S %z %Y",
        "format": "ddd MMM DD HH:mm:ss Z YYYY (Sun Sep 30 23:04:05 -0000 2013)"
    },
    {
        "id": "%m/%d",
        "format": "MM/DD (09/30)"
    }, 
    {
        "id": "%m-%d",
        "format": "MM-DD (09-30)"
    }   
]
    return (
        <div style={{ width: '100vw', height: '100vh' }}>
            <AppBarHeaderDummy />
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid style={{overflow: 'auto'}} item xs={4} >
                        <Paper className={classes.paper} style={{ marginLeft: 15}}> <Typography ><h1>Apps</h1></Typography>
                            <Button variant='outlined' style={{marginBottom: '10px'}} color='primary' onClick={() => { history.push("/develop/new") }}>Add App</Button>
                            <Autocomplete
                                loading={loadingAllApps}
                                loadingText={"Loading Apps..."}
                                noOptionsText={"No Apps? Something has gone wrong..."}
                                options={allApps}
                                getOptionLabel={(option) => option.appName ?? option.appSlug}
                                onChange={(e, v) => {
                                    setAppInfo({ ...v, actions: {}, triggers: {}, authInfo: {} })
                                    if (v) {
                                        loadApp(v.appSlug);
                                        history.push("/develop/"+v.appSlug)

                                    } else {
                                        setDisabled(true)
                                    }
                                }}
                                value={appInfo ?? { appSlug: '' }}
                                renderInput={(params) => <TextField style={{ width: '100%', padding: '4px', margin: '4px' }} {...params} variant="outlined" placeholder={"Type to search apps"} label={"App"} />}
                                filterOptions={allAppsFilterOptions}
                            />
                            {
                                appInfo.appSlug ?
                                    <>
                                        <Autocomplete
                                            options={statusOptions}
                                            onChange={(e, v) => {
                                                setAppInfo({ ...appInfo, completionStatus: v })
                                            }}
                                            value={appInfo.completionStatus}
                                            renderInput={(params) => <TextField variant="outlined" style={{margin: '4px', width: '100%', padding: '4px' }} {...params} label={"Status"} />}
                                        />
                                            <TextField style={{margin: '4px', width: '100%', padding: '4px' }} label={"API Docs"} value={appInfo.apiDocs} onChange={(e) => { setAppInfo({ ...appInfo, apiDocs: e.target.value }) }} onBlur={saveToDB} />
                                        <TextField variant="outlined" select style={{margin: '4px', width: '100%', padding: '4px' }} label={"API DateTime Format"} value={appInfo.apiDateTimeFormat} onChange={(e) => { setAppInfo({ ...appInfo, apiDateTimeFormat: e.target.value }); saveToDB() }} >
                                           {date_options.map(option => (
                                                    <MenuItem key={option.id} value={option.id}>{option.format}</MenuItem>
                                           ))}
                                           
                                            </TextField>                                        <Box style={{width: '100%', height: '700px', overflow: 'scroll' }}>
                                        <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug)} style={{margin: '8px 0px 0px 0px'}}><SettingsRounded style={{marginRight: '10px', padding: '4px'}}/><Typography variant="h6">App Overview</Typography></MenuItem>
                                        <Accordion elevation={0} defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreRounded />}><BuildRounded style={{marginRight: '10px', padding: '4px'}} /><Typography variant="h6">Develop</Typography></AccordionSummary>
                                            <AccordionDetails>
                                                <List>
                                                    <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug+"/authentication")} style={{margin: '0px 0px 4px 0px'}}>Authentication</MenuItem>
                                                    {appInfo.authInfo.apiType && (
                                                       <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug+"/authentication/edit")}>
                                                        <VpnKeyRounded style={{marginRight: '8px', color: appInfo.authInfo.testResponseStatus === true ? 'green' : appInfo.authInfo.testResponseStatus === false ? 'red' : null}} />
                                                        {appInfo.authInfo.apiType === 'basic' &&
                                                       <Typography variant="body1">Basic Auth</Typography>}
                                                       {appInfo.authInfo.apiType === 'apikey' &&
                                                       <Typography variant="body1">API Key</Typography>}
                                                       {appInfo.authInfo.apiType === 'oauth2' &&
                                                       <Typography variant="body1">OAuth 2.0</Typography>}
                                                       {appInfo.authInfo.apiType === 'customCode' &&
                                                       <Typography variant="body1">Custom</Typography>}</MenuItem>  
                                                    )}
                                                    {appInfo.disableAuth === true && (
                                                       <MenuItem disabled><VpnKeyRounded style={{marginRight: '8px'}} /><Typography variant="body1">{"Passport App"}</Typography></MenuItem>  
                                                    )}
                                                    <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug+"/triggers")} style={{margin: '12px 0px 4px 0px'}}>Triggers</MenuItem>
                                                    {Object.values(appInfo.triggers).map(trigger => (
                                                       <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug+"/triggers/"+trigger.key+"/settings")}><OfflineBoltRounded style={{marginRight: '8px'}} /><Typography variant="body1">{trigger.label}</Typography></MenuItem> 
                                                    ))}
                                                    <MenuItem style={{margin: '0px 0px 4px 0px', color: '#0069ea', backgroundColor: '#f0f1fa'}}>Actions</MenuItem>
                                                    {Object.values(appInfo.actions).map(action => (
                                                       <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug+"/actions/"+action.key+"/settings")}><SpellcheckRounded style={{marginRight: '8px'}} /><Typography variant="body1">{action.title}</Typography></MenuItem> 
                                                    ))}
                                                </List>
                                            </AccordionDetails>
                                        </Accordion>
                                        </Box>
                                    </> :
                                    null
                            }
                        </Paper>
                    </Grid>
                    
                    <Grid item xs={8} >
                    <Paper className={classes.paper}> 
                    <Box component="div" style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                      <Box component="div" style={{float: 'left'}}>
                      <Typography ><h1>Actions</h1></Typography>
                        </Box>  
                        <Box component="div" style={{float: 'left', margin: '20px'}}>
                      <Button onClick={()=> history.push("/develop/"+appInfo.appSlug+"/actions/__new__/settings")} variant="contained" color="primary" style={{textDecoration: 'none'}} ><Typography>+Add Action</Typography></Button>
                        </Box>  
                    </Box>
                    {/* Search Input */}
      <TextField
        label="Search Actions"
        variant="outlined"
        value={searchQuery}
        onChange={handleSearch}
        style={{ marginBottom: '16px', width: '40%' }}
        InputProps={{
            startAdornment: <InputAdornment position="start"><SearchRounded style={{color: 'gray'}}/></InputAdornment>
        }}
      />
                 {/* Table */}
                 <TableContainer>
                    <Table stickyHeader>
                        <TableHead style={{width: '100%'}}>
                            <TableRow>
                                <TableCell key="name">
                                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? orderDirection : 'asc'}
                  onClick={() => handleSortRequest('name')}
                >
                    Name
                    </TableSortLabel>
                    </TableCell>
                                <TableCell key="key">
                                <TableSortLabel
                  active={orderBy === 'key'}
                  direction={orderBy === 'key' ? orderDirection : 'asc'}
                  onClick={() => handleSortRequest('key')}
                >
                    Key
                    </TableSortLabel>
                    </TableCell>
                            <TableCell key="hidden">
                                <TableSortLabel
                  active={orderBy === 'hidden'}
                  direction={orderBy === 'hidden' ? orderDirection : 'asc'}
                  onClick={() => handleSortRequest('hidden')}
                >
                    Visibility in Editor
                    </TableSortLabel>
                    </TableCell>
                                <TableCell key="edit"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {sortedRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           // console.log('row is: ', row)
                            return (
                                <TableRow key={row.key}>
                                    <TableCell  align="left">
                                       <Typography onClick={()=> { history.push("/develop/"+appInfo.appSlug+"/actions/"+row.key+"/settings")}} style={{cursor: 'pointer', textDecoration: 'underline', color: 'blue'}}>{row.name ?? row.key}</Typography> 
                                        <p>{row.description ?? "No description"}</p>
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.key}
                                    </TableCell>
                                    <TableCell  align="left">
                                        {row.hidden === true ? "Hidden" : "Shown"}
                                    </TableCell>
                                    <TableCell  align="left">
                                       <IconButton onClick={(e) => handleAuthClick(e, row.key)}>
                        <SettingsRounded />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorElAuthMenu}
                        open={Boolean(anchorElAuthMenu)}
                        onClose={handleCloseAuthMenu}
                        
                        >
                        <MenuItem style={{width: '150px'}} onClick={()=>   { history.push("/develop/"+appInfo.appSlug+"/actions/"+rowKey+"/settings")}}>{"Edit"}</MenuItem>
                        <MenuItem style={{width: '150px'}} onClick={()=> deleteAction(rowKey)}>{reallyDelete === true ? <Typography color="secondary">Really Delete?</Typography> : "Delete"}</MenuItem>
                        </Menu>
                                    </TableCell>
                                </TableRow>
                            );
                            })}
                        </TableBody>
                    </Table>
                 </TableContainer>
                 <TablePagination
                        rowsPerPageOptions={[10, 25, 100,250]}
                        component="div"
                        count={formattedActionArray?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                    </Paper>
                    </Grid>
                     

                    
                    
                    
                    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={alert ? alert.severity : null}>
                            {alert ? alert.message : null}
                        </Alert>
                    </Snackbar>
                    
                </Grid>
            </div></div>

    )
}
